import { useEffect, useState } from "react";
import searchIcon from "assets/search.png";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OnlineOffline } from "Store/Slices/ChatSlice";
import { Avatar } from "antd";
import moment from "moment";
import { FirstCharacterIcon } from "Components/GeneralComponents";
import { getLandlordConversations } from "network/landlord/chat";
import { getTenantConversations } from "network/tenant/chat";

const ChatUserList = ({ archive }) => {
  const socket = useSelector((state) => state.Chat.socket);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [conversations, setConversations] = useState([]);
  const [openedChat, setOpenedChat] = useState(null);
  const [search, setSearch] = useState("");
  const role = localStorage.getItem("role");

  useEffect(() => {
    if (role === "landlord" || role === "user") {
      getLandlordConversations(1, search).then((res) => {
        if (res.apiCallStatus === "success") {
          // console.log("res.data >>>>>>>>>>>", res);
          setConversations(res.message);
        }
      });
    } else {
      getTenantConversations(1, search).then((res) => {
        if (res.apiCallStatus === "success") {
          // console.log("res.data >>>>>>>>>>>", res);
          setConversations(res.message);
        }
      });
    }
  }, [search]);

  // Listen for new messages via socket
  useEffect(() => {
    if (socket) {
      socket.on("chatMessage", () => {
        if (role === "landlord" || role === "user") {
          getLandlordConversations(1, search).then((res) => {
            if (res.apiCallStatus === "success") {
              // console.log("res.data >>>>>>>>>>>", res);
              setConversations(res.message);
            }
          });
        } else {
          getTenantConversations(1, search).then((res) => {
            if (res.apiCallStatus === "success") {
              // console.log("res.data >>>>>>>>>>>", res);
              setConversations(res.message);
            }
          });
        }
      });
    }
  }, [socket, role]);

  const handleNavigateArchive = () => {
    navigate("/archive-user");
  };

  return (
    <div className="row m-0 h-100">
      <div className="col-md-5 col-lg-4 border-end p-0 h-100">
        <div className="chat-user-list-container p-2 h-100">
          <div className="chat-search-bar border-bottom position-relative pb-3 d-none d-lg-block">
            <input
              type="text"
              placeholder="Search"
              className="form-control search-form-control-task"
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="search-icon-task">
              <img src={searchIcon} alt="search" />
            </div>
          </div>

          <div className="chat-archive-bar mt-3 text-center">
            <button
              onClick={handleNavigateArchive}
              className={`archive-chat-button w-100 mb-2 ${
                archive && "d-none"
              }`}
            >
              <span>
                <svg width={21} height={21} fill="#98A2B3" viewBox="0 0 24 24">
                  <path d="M21 6a3 3 0 0 0-3-3H6a3 3 0 0 0-2 5.22V18a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8.22A3 3 0 0 0 21 6ZM6 5h12a1 1 0 1 1 0 2H6a1 1 0 0 1 0-2Zm12 13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V9h12v9Z" />
                  <path d="M14.13 12H9.87a.87.87 0 0 0-.87.87v.26c0 .48.39.87.87.87h4.26c.48 0 .87-.39.87-.87v-.26a.87.87 0 0 0-.87-.87Z" />
                </svg>
              </span>{" "}
              Archived Chats
            </button>
          </div>

          <div
            className="chat-user-lists-container"
            style={{ height: "calc(100vh - 115px)", overflowY: "auto" }}
          >
            {conversations.map((item) => {
              const user =
                role === "landlord" || role === "user"
                  ? item.tenant
                  : item.landlord;
              return (
                <Link
                  key={item.id}
                  className="text-grey"
                  to={
                    window.innerWidth <= 850
                      ? "/mobile-chat"
                      : `chat?id=${item.id}`
                  }
                  onClick={() => {
                    setOpenedChat(item);
                    dispatch(OnlineOffline(user.status || item.isTenant));
                  }}
                >
                  <div className="chat-user-lists gap-3 d-flex align-items-center border-bottom p-3">
                    {/* <Avatar
                      size="large"
                      style={{ backgroundColor: "#EF6B3E" }}
                      src={user.profileImage}
                    >
                      {!user.profileImage && localStorage.getItem("name")[0]}
                    </Avatar> */}
                    {user?.profileImage ? (
                      <Avatar
                        src={user.profileImage}
                        size="large"
                        style={{ backgroundColor: "" }}
                      />
                    ) : (
                      <FirstCharacterIcon name={user?.firstName[0]} />
                    )}
                    <div
                      className="chat-user-info"
                      style={{ width: "calc(100% - 50px)" }}
                    >
                      <p className="mb-0">{`${user?.firstName} ${user?.lastName}`}</p>
                      <p
                        className="mb-0 normal-grey-text"
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {item.text}
                      </p>
                    </div>
                    <div className="chat-user-time">
                      <p className="mb-0 normal-grey-text">
                        {moment(item.timestamp).startOf("minutes").fromNow()}
                      </p>
                    </div>
                    {item.unreadCount > 0 && (
                      <div className="chat-user-count">
                        <p className="mb-0 text-white">{item.unreadCount}</p>
                      </div>
                    )}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      <div className="col-md-7 d-md-none d-lg-block col-lg-8 ps-0 position-relative">
        <Outlet context={{data: openedChat}}/>
      </div>
    </div>
  );
};

export default ChatUserList;
