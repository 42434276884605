import { useState } from "react";
import propertyImg from "assets/fi_3110045.png";
import propertyImgWhite from "assets/fi_3110045 (1).png";
import AddNewTaskModal from "Modals/AddNewTaskModal/AddNewTaskModal";
import AddNewWorkOrderModal from "Modals/AddNewWorkOrderModal/AddNewWorkOrderModal";
import PaymentSuccessModal from "Modals/PaymentSuccessModal/PaymentSuccessModal";
import { Link } from "react-router-dom";
import * as Component from "Components/GeneralComponents";
import CreateTaskForm from "Components/AllTasks/CreateTaskForm.jsx";



const CreateNew = () => {
  // States start
  const [hoveredState, setHoveredState] = useState(false);
  const [openModalTask, setOpenModalTask] = useState(false);
  const [openModalWorkOrder, setopenModalWorkOrder] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // States end

  const cardData = [
    { id: 1, text: "Create new Property", link: "add-property-details" },
    { id: 3, text: "Create new Task", click: true },
    { id: 4, text: "Create new Work Order", click: true },
    { id: 5, text: "Create new Prospect", link: "add-prospect-details" },
    { id: 6, text: "Create new Tenant", link: "add-tenant-details" },
    { id: 7, text: "Create new Vendor", link: "add-vendor-details" },
    // {id:2, text: 'Create new Units', link: 'add-unit-details' },
    // Add more card data as needed
  ];
  const cardData2 = [
    { id: 8, text: "Create new Report" },
    { id: 9, text: "Create new File", link: "new-files" },
    { id: 10, text: "Create new Lease", link: "new-lease" },
    { id: 11, text: "Create new Account", link: "add-account" },
    { id: 12, text: "Create new Mileage", link: "add-mileage" },
    { id: 13, text: "Create new Payment", link: "create-payable" },
    // Add more card data as needed
  ];
  const handleMouseEnterView = (id) => {
    setHoveredState(id);
  };

  const handleMouseLeaveView = (index) => {
    setHoveredState(null);
  };

  // Modal Function
  const onOpenModalTask = () => {
    setOpenModalTask(true);
  };
  const onCloseModalTask = () => {
    setOpenModalTask(false);
  };
  const onOpenModalWorkOrder = () => {
    setopenModalWorkOrder(true);
  };
  const onCloseModalWorkOrder = () => {
    setopenModalWorkOrder(false);
  };
  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      {openModalTask === true ? (
        <Component.FormModalLayout
        title={"Add New Tasks"}
        isOpen={onOpenModal}
        onClose={onCloseModalTask}
      >
        <CreateTaskForm onClose={onCloseModalTask} />
      </Component.FormModalLayout>
        // <AddNewTaskModal onOpen={onOpenModal} onClose={onCloseModalTask} />
      ) : (
        ""
      )}
      {openModalWorkOrder === true ? (
        <AddNewWorkOrderModal
          onOpen={onOpenModal}
          onClose={onCloseModalWorkOrder}
        />
      ) : (
        ""
      )}
      {openModal === true ? (
        <PaymentSuccessModal
          onClose={onCloseModal}
          message="Task details inserted successfully"
          success="All Tasks"
        />
      ) : (
        ""
      )}
      <div className="container-fluid p-4">
        <div className="row">
          <div className="col-md-12 text-center">
            <p className="create-new-page-title my-4">Select Any One:</p>
          </div>
        </div>
        <div className="row justify-content-center create-new-main-section">
          {cardData.map((card, index) => (
            <>
              <div className="col-lg-5 col-md-12 mt-2">
                <Link
                  to={card.link ? `/${card.link}` : "#!"}
                  className="text-decoration-none"
                >
                  <div
                    onClick={() => {
                      if (
                        card.click === true &&
                        card.text === "Create new Task"
                      ) {
                        onOpenModalTask();
                      } else if (
                        card.click === true &&
                        card.text === "Create new Work Order"
                      ) {
                        onOpenModalWorkOrder();
                      }
                    }}
                    key={index}
                    className="card create-new-page-card-main mx-auto "
                    onMouseEnter={() => handleMouseEnterView(card.id)}
                    onMouseLeave={() => handleMouseLeaveView(card.id)}
                  >
                    <div className="card-body p-4 d-flex align-items-center gap-4">
                      <img
                        src={
                          hoveredState === card.id
                            ? propertyImgWhite
                            : propertyImg
                        }
                        alt=""
                        className="d-block contact-page-card-img"
                      />
                      <span className="create-new-para-text">{card.text}</span>
                    </div>
                  </div>
                </Link>
              </div>
            </>
          ))}
          {cardData2.map((card, index) => (
            <>
              <div className="col-lg-5 col-md-12 mt-2">
                <Link
                  to={card.link ? `/${card.link}` : "#!"}
                  className="text-decoration-none"
                >
                  <div
                    key={index}
                    className="card create-new-page-card-main mx-auto "
                    onMouseEnter={() => handleMouseEnterView(card.id)}
                    onMouseLeave={() => handleMouseLeaveView(card.id)}
                  >
                    <div className="card-body p-4 d-flex align-items-center gap-4">
                      <img
                        src={
                          hoveredState === card.id
                            ? propertyImgWhite
                            : propertyImg
                        }
                        alt=""
                        className="d-block contact-page-card-img"
                      />
                      <span className="create-new-para-text">{card.text}</span>
                    </div>
                  </div>
                </Link>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default CreateNew;
