import React, { useState, useEffect } from "react";
import { ConfigProvider, Tabs } from "antd";
import settingIconOrange from "assets/dots-vertical.png";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import * as InnerComponent from "./index";
import * as API from "network/landlord/lease";

const LeaseDetails = () => {
  const { id } = UseUrlParamsHook();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [commercial, setCommercial] = useState(false);
  const { TabPane } = Tabs;
  const [data, setData] = useState([]);

  const handleIconClickCustom = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  useEffect(() => {
    API.getLeaseById(id).then((res) => {
      if (res.apiCallStatus === "success") {
        res.message.data.property_type === "commercial"
          ? setCommercial(true)
          : setCommercial(false);
        setData(res.message.data);
      }
    });
  }, [id]);

  return (
    <>
      <div className="container-fluid bg-white p-3">
        <div className="row">
          <div className="col-md-12 text-end">
            <img
              onClick={handleIconClickCustom}
              src={settingIconOrange}
              alt="Action Buttons"
              className="cursor"
            />
            {isDropdownOpen && <></>}
          </div>
        </div>
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                inkBarColor: "#EF6B3E",
                itemColor: "#667085",
                itemSelectedColor: "#EF6B3E",
                itemHoverColor: "#EF6B3E",
                titleFontSize: 15,
                horizontalItemGutter: window.innerWidth <= 768 ? 10 : 60,
                fontFamily: "Montserrat",
              },
            },
          }}
        >
          <Tabs
            centered={window.innerWidth <= 450 ? false : true}
            defaultActiveKey="1"
            style={{ fontWeight: 500 }}
          >
            {/* {
              <> */}
            <TabPane tab="Overview" key="1">
              <InnerComponent.OverviewTab id={id} />
            </TabPane>
            <TabPane tab="Tenants" key="2">
              <InnerComponent.TenantsTab id={id} />
            </TabPane>
            {!commercial && (
              <TabPane tab="Pet Info" key="3">
                <InnerComponent.PetsInfoTab id={id} />
              </TabPane>
            )}
            <TabPane tab="Vehicle Info" key="4">
              <InnerComponent.VehicleInfoTab id={id} />
            </TabPane>
            {!commercial && (
              <TabPane tab="Dependent Info" key="5">
                <InnerComponent.DependentInfoTab id={id} />
              </TabPane>
            )}
            <TabPane tab="Documents" key="6">
              <InnerComponent.DocumentsTab data={data} />
            </TabPane>
            <TabPane tab="Files" key="7">
              <InnerComponent.FilesTab id={id} category={"lease"} />
            </TabPane>
            <TabPane tab="Notes" key="8">
              <InnerComponent.NotesTab id={id} category={"lease"} />
            </TabPane>
            {/* </>
            } */}
          </Tabs>
        </ConfigProvider>
      </div>
    </>
  );
};

export default LeaseDetails;
