import axios from "axios";
import config from "Helpers/config.js";

const fetchClientSecret = async (data) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/stripe/createNewSubscriber`,
      data,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchLandlordPlans = async () => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/landlord/plans`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// const UpdateSubscription = (plan, update) => {
//     fetch(`${config.baseUrl}/api/stripe/updateSubscription`, {
//       method: "PUT",
//       headers: {
//         "content-type": "application/json",
//         Authorization: `Bearer ${localStorage.getItem("token")}`,
//       },
//       body: JSON.stringify({
//         email: localStorage.getItem("email"),
//         interval: !interval ? "Monthly" : "Annually",
//         productName: plan,
//         planUpdate: update ? "upgrade" : "downgrade",
//       }),
//     })
//       .then((res) => {
//         return res.json();
//       })
//       .then((res) => {
//         if (res.apiCallStatus === "success") {
//           message.success(res.message);
//           setUpdate(true);
//         } else {
//           message.error(res.message);
//         }
//       })
//       .catch((err) => {
//         console.log(err, "error");
//       });
//   };

const UpdateSubscription = async (data) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/stripe/updateSubscription`,
      data,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export { fetchClientSecret, fetchLandlordPlans, UpdateSubscription };
