import { useCallback, useEffect, useState } from "react";
import CheckBoxActive from "assets/_Checkbox active.png";
import ProIcon from "assets/user-check-02.png";
import PaymentSuccessModal from "Modals/PaymentSuccessModal/PaymentSuccessModal";
import UseGetHook from "Hooks/UseGetHook";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import config from "Helpers/config.js";
import { loadStripe } from "@stripe/stripe-js";
import { AuthLayout, SuccessModal } from "Components/GeneralComponents";
const PaymentMethodSignUp = () => {
  // States start
  const [openModal, setOpenModal] = useState(false);
  // States end
  const { plan, FetchLandlordPlans } = UseGetHook("landlord");
  const { interval, email, planName, price } = UseUrlParamsHook();
  useEffect(() => {
    FetchLandlordPlans();
  }, []);
  // Modal Function
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };
  // Stripe
  const stripeKey = loadStripe(config.stripe_publish_key);
  const fetchClientSecret = useCallback(async () => {
    // Create a Checkout Session
    const res = await fetch(`${config.baseUrl}/api/stripe/createNewSubscriber`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        email: email,
        interval: interval,
        productName: planName,
        returnUrl: "https://digitalapp.technoholicasprojects.com",
      }),
    });
    const data = await res.json();
    return data.clientSecret;
  }, []);
  const options = { fetchClientSecret };
  // Stripe End
  return (
    <>
      {openModal === true && (
        // <PaymentSuccessModal
        //   paymentSuccess="Successful Payment"
        //   success="Go To Dashboard"
        //   onClose={onCloseModal}
        // />
        <SuccessModal
          heading={"Successful Payment"}
          title={""}
          route={"/land-lord-dashboard"}
          success={"Go To Dashboard"}
          onClose={onCloseModal}
        />
      )}

      <AuthLayout>
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-lg-12 col-xl-4 col-md-12">
              <SignUpLeftBanner />
            </div> */}
            <div className="">
              <div className="container">
                <div className="land-lord-right-heading text-start mt-5 d-flex flex-column">
                  <span style={{ fontSize: "33px", fontWeight: "600" }}>
                    Add a Payment Method
                  </span>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#2D3748",
                    }}
                  >
                    Create your account if not registered yet
                  </span>
                </div>
                <div className="payment-select-boxes mt-4">
                  <div className="payment-select-box active-box">
                    <div className="payment-select-box-left d-flex gap-2 align-items-center">
                      <img
                        src={ProIcon}
                        className="img-fluid object-fit-contain"
                        alt=""
                      />
                      <div className="onboarding-box-text ">
                        <div>
                          <span
                            style={{
                              fontSize: "18px",
                              fontWeight: "500",
                              color: "#344054",
                            }}
                          >
                            Service {planName}
                          </span>{" "}
                          <span
                            className="ms-2"
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#475467",
                            }}
                          >{`$${price}/${interval}`}</span>
                        </div>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#667085",
                          }}
                        >
                          Includes up to 5 users, 10GB indiviual data and access
                          to all features.
                        </span>
                      </div>
                    </div>
                    <div className="onboarding-select-box-check-box">
                      <img src={CheckBoxActive} alt="" />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <EmbeddedCheckoutProvider
                    stripe={stripeKey}
                    options={options}
                  >
                    <EmbeddedCheckout />
                  </EmbeddedCheckoutProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default PaymentMethodSignUp;
