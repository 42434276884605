import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TempModal from "Modals/TempModal/TempModal";
import moment from "moment";
import { PersonalDetailsForm, EmergencyInfoForm, Stepper } from "./index";
import { getTenantById, updateTenant } from "network/landlord/tenant";
import { message } from "antd";
import Loader from "Helpers/Loader";
import { validateTenantPersonalDetails } from "utils/validations";
import { tenantFormData } from "utils/FormDataStructure";

const AddTenantDetails = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [DeletedImages, setDeletedImages] = useState([]);
  const [ProfileImages, setProfileImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [tenant, setTenant] = useState([]);
  const [form, setForm] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    dob: "",
    addressLine_1: "",
    addressLine_2: "",
    country: "USA",
    city: "",
    state: "",
    zipCode: "",
    phoneType: "",
    company: "",
    jobTitle: "",
    notes: "",
  });
  const [emergencyContacts, setEmergencyContacts] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      relationship: "",
      description: "",
    },
  ]);

  const { id } = useParams();
  const navigate = useNavigate();

  // Get Tenant By Id
  useEffect(() => {
    setIsLoading(true);
    getTenantById(id).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setTenant(res.message);
      } else {
        message.error(res.error.message);
      }
    });
  }, []);

  // Set Form Data
  useEffect(() => {
    setForm({
      firstName: tenant?.firstName || "",
      middleName: tenant?.middleName || "",
      lastName: tenant?.lastName || "",
      email: tenant?.email || "",
      phone: tenant?.phone || "",
      dob: moment(tenant?.dob).format("L") || "",
      addressLine_1: tenant?.addressLine_1 || "",
      addressLine_2: tenant?.addressLine_2 || "",
      city: tenant?.city?.name || "",
      state: tenant?.state?.name || "",
      zipCode: tenant?.zipcode?.number ? "" + tenant?.zipcode?.number : "",
      phoneType: tenant?.phoneType || "",
      company: tenant?.company || "",
      jobTitle: tenant?.jobTitle || "",
      notes:
        tenant?.notes &&
        tenant?.notes.length > 0 &&
        tenant?.notes[0]?.description,
    });
  }, [tenant]);

  // handle form data change
  const handleChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  // set emergency contact data
  useEffect(() => {
    if (tenant?.emergencyDetails) {
      setEmergencyContacts(
        tenant?.emergencyDetails.map((emergencyData) => ({
          firstName: emergencyData?.firstName || "",
          middleName: emergencyData?.middleName || "",
          lastName: emergencyData?.lastName || "",
          email: emergencyData?.email || "",
          phone: emergencyData?.phone || "",
          relationship: emergencyData?.relationship || "",
          description: emergencyData?.description || "",
        }))
      );
    }
  }, [tenant?.emergencyDetails]);

  // Handle Emergency Contact Info change
  const handleEmergencyChange = (field, index, value) => {
    const newEmergencyContacts = [...emergencyContacts];
    newEmergencyContacts[index][field] = value;
    setEmergencyContacts(newEmergencyContacts);
  };

  const handleAddContact = () => {
    setEmergencyContacts([
      ...emergencyContacts,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phoneNo: "",
        relationship: "",
        description: "",
      },
    ]);
  };

  const handleRemoveContact = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedEmergencyContacts = emergencyContacts.filter(
        (contact, index) => index !== indexToRemove
      );
      setEmergencyContacts(updatedEmergencyContacts);
    }
  };

  const handleAdditionInfoSubmit = () => {
    const newErrors = validateTenantPersonalDetails(form);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleSubmit = () => {
    const formData = tenantFormData(form, emergencyContacts, ProfileImages);
    setIsLoading(true);
    updateTenant(id, formData).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setOpenModal(true);
      } else {
        message.error(res.error.message);
      }
    });
  };

  const handleNext = () => {
    switch (currentStep) {
      case 1:
        handleAdditionInfoSubmit();
        break;
      case 2:
        handleSubmit();
        break;
    }
  };
  
  const handleBackButton = () => {
    if (currentStep === 1) {
      navigate("/all-tenants");
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <>
      {openModal === true && (
        <TempModal
          onClose={() => setOpenModal(false)}
          title="Tenant updated successfully"
          success="Go To Tenants"
          route="all-tenants"
        />
      )}
      <div className="container-fluid bg-white p-3">
        <div className="container">
          <Stepper currentStep={currentStep} />
          <div className="stepper-content-container mt-4">
            {currentStep === 1 && (
              <>
                {isLoading ? (
                  <Loader />
                ) : (
                  <PersonalDetailsForm
                    form={form}
                    handleChange={handleChange}
                    errors={errors}
                    setProfileImages={setProfileImages}
                    ProfileImages={ProfileImages}
                    setDeletedImages={setDeletedImages}
                    DeletedImages={DeletedImages}
                    handleNext={handleNext}
                  />
                )}
              </>
            )}
            {currentStep === 2 && (
              <EmergencyInfoForm
                emergencyContacts={emergencyContacts}
                handleAddContact={handleAddContact}
                handleRemoveContact={handleRemoveContact}
                handleChange={handleEmergencyChange}
                handleNext={handleNext}
                handleBackButton={handleBackButton}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTenantDetails;
