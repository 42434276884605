import React from "react";

const Stepper = ({currentStep, isCommercial}) => {
  return (
    <div className="stepper-container step-container-responsive tenant-stepper">
      <div
        className={
          currentStep === 1 ||
          currentStep === 2 ||
          currentStep === 3 ||
          currentStep === 4 ||
          currentStep === 5 ||
          currentStep === 6
            ? "step-1 personal-details stepper-active"
            : "step-1 personal-details stepper-inactive"
        }
      >
        1<div className="form-step-text">Email</div>
      </div>
      <div
        className={
          currentStep === 2 ||
          currentStep === 3 ||
          currentStep === 4 ||
          currentStep === 5 ||
          currentStep === 6
            ? "step-1 personal-details stepper-active"
            : "step-1 personal-details stepper-inactive"
        }
      >
        2<div className="form-step-text">Personal Details</div>
      </div>
      <div
        className={
          currentStep === 3 ||
          currentStep === 4 ||
          currentStep === 5 ||
          currentStep === 6
            ? "step-2 emergency-info stepper-active"
            : "step-2 emergency-info stepper-inactive"
        }
      >
        3<div className="form-step-text">Emergency Info</div>
      </div>
      {!isCommercial && (
        <>
          <div
            className={
              currentStep === 4 ||
              currentStep === 5 ||
              currentStep === 6 
                ? "step-3 emergency-info stepper-active"
                : "step-3 emergency-info stepper-inactive"
            }
          >
            4<div className="form-step-text">Dependent Info</div>
          </div>

          <div
            className={
              currentStep === 5 || currentStep === 6
                ? "step-4 pet-info stepper-active"
                : "step-4 pet-info stepper-inactive"
            }
          >
            5<div className="form-step-text">Pet Info</div>
          </div>
        </>
      )}
      <div
        className={
          (isCommercial && currentStep === 4) || currentStep === 6
          ? "step-5 dependent-info stepper-active"
          : "step-5 dependent-info stepper-inactive"
        }
        >
        {isCommercial ? 4 : 6}
        <div className="form-step-text">Vehicle Info</div>
      </div>




      
      <div className="lines-tenant vender-tab-line">
        <div
          className={`line ${
            currentStep === 2 ||
            currentStep === 3 ||
            currentStep === 4 ||
            currentStep === 5 ||
            currentStep === 6
              ? "active"
              : ""
          }`}
        ></div>
        <div
          className={`line ${
            currentStep === 3 ||
            currentStep === 4 ||
            currentStep === 5 ||
            currentStep === 6
              ? "active"
              : ""
          }`}
        ></div>
        {
          isCommercial && (

            <>
            <div
            className={`line ${
              currentStep === 4 || currentStep === 5 || currentStep === 6
              ? "active"
              : ""
            }`}
            ></div>
            <div
            className={`line ${
              currentStep === 5 || currentStep === 6 ? "active" : ""
            }`}
            ></div>
            <div className={`line ${currentStep === 6 ? "active" : ""}`}></div>
            </>
          )
        }
      </div>
    </div>
  );
};

export default Stepper;
