import { useState, useEffect } from "react";
import searchIcon from "assets/New icons (7).png";
import filterIcon from "assets/filter.png";
import plusIcon from "assets/plus.png";
import trashIconWhite from "assets/trash-icon-white.png";
import chevronIconDown from "assets/chevron-down.png";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import UseGetHook from "Hooks/UseGetHook";
import { propertyIcon } from "utils/index";
import OldDeleteModal from "Modals/DeleteModal/DeleteModal";
import AddPropertyEditModal from "Modals/AddPropertyEditModal/AddPropertyEditModal";
import BulkDelete from "Hooks/BulkDelete";
import PropertyFilter from "Helpers/PropertyFilter";
import ConditionalFilter from "Hooks/ConditionalFilter";
import { useSelector } from "react-redux";
import UserPermission from "libs/UserPermission";
import { VerticalElipsisIcon } from "assets/icons";
import {
  CustomTable,
  DeleteModal,
  FormModalLayout,
  RecordMenuPopup,
} from "Components/GeneralComponents";
import { archiveProperty } from "network/landlord/property";

const PropertiesDashboard = () => {
  // select box icon
  const { ROLE } = UserPermission();
  // States start
  const navigate = useNavigate();
  const [hideFilter, setHideFilter] = useState(false);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [openModal, setOpenModal] = useState(false);
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [component, setcomponent] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [key, setKey] = useState([]);
  const [search, setSearch] = useState("");
  const [openEditModalTask, setOpenEditModalTask] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [update, setUpdate] = useState(false);
  const [isDeletePropertyRender, setIsDeletePropertyRender] = useState(false);
  const [isDeletePropertiesRender, setIsDeletePropertiesRender] =
    useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeletePropertiesModal, setOpenDeletePropertiesModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // States end
  const property_sub_type = useSelector((state) => {
    return state.FilterValue.property_sub_type;
  });
  const status = useSelector((state) => {
    return state.FilterValue.status;
  });
  const minRent = useSelector((state) => {
    return state.FilterValue.minRent;
  });
  const maxRent = useSelector((state) => {
    return state.FilterValue.maxRent;
  });
  const bedroom = useSelector((state) => {
    return state.FilterValue.bedroom;
  });
  const bathroom = useSelector((state) => {
    return state.FilterValue.bathroom;
  });
  const state = useSelector((state) => {
    return state.FilterValue.state;
  });
  const { filters, FilterObjects } = ConditionalFilter({
    property_sub_type,
    status,
    minRent,
    maxRent,
    state,
    ...(bedroom && bedroom.length ? { "bedroom[]": bedroom } : {}),
    ...(bathroom && bathroom.length ? { "bathroom[]": bathroom } : {}),
  });
  const { fetchProperty, PropertyData, loader } = UseGetHook(
    filters(FilterObjects)
      ? `property/filter?${filters(FilterObjects)}`.replace(
          "state=",
          "address.state="
        )
      : "property"
  );
  useEffect(() => {
    fetchProperty();
  }, [property_sub_type, status, minRent, maxRent, bedroom, bathroom, state]);

  useEffect(() => {
    if (update) {
      fetchProperty();
      setUpdate(false);
    }
  }, [update]);
  // Modal Function
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };
  // Data Table Functions
  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };
  const onOpenEditModalTask = () => {
    setOpenEditModalTask(true);
  };

  const columns = [
    {
      title: "Property Name",
      dataIndex: "propertyName",
      render: (text, propertyName) => (
        <>
          <Link
            to={`/property-details-view?id=${propertyName.key}`}
            className="d-flex align-items-center gap-2"
          >
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                width: "50px",
                borderRadius: "10%",
                overflow: "hidden",
                position: "relative",
              }}
            >
              {propertyName.img.length !== 0 ? (
                <img
                  src={propertyName.img[0]}
                  alt="task"
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src={propertyIcon(propertyName.type)}
                  alt="task"
                  style={{
                    width: "2rem",
                    height: "2rem",
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
            <div className="">{text}</div>
          </Link>
        </>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text, type) => (
        <>
          <span className="type-text-multi-family rounded-4">
            {text.split("_").join(" ")}
          </span>
        </>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      render: (text, add) => (
        <>
          <div className="properties_dashbaord_address_text">{text}</div>
          <span className="properties_dashbaord_address_text">
            {add.city} {add.state}, {add.zipcode}
          </span>
        </>
      ),
    },
    {
      title: "Rent Amount",
      dataIndex: "rentAmount",
      render: (text) => (
        <>
          <span className="properties_dashboard_table_amount_text">{text}</span>
        </>
      ),
    },

    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <VerticalElipsisIcon width={23} height={23} />
            <RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDeleteRecord: () => {
                  // onOpenModal();
                  // setDeleteId(setting.key);
                  // setcomponent("property");
                  setOpenDeleteModal(true);
                  setDeleteId(setting.key);
                },
                handleView: () => {
                  navigate(`/property-details-view?id=${setting.key}`);
                },
                handleEdit: () => {
                  onOpenEditModalTask();
                  setTaskId(setting.key);
                },
              }}
              fields={{
                view: true,
                edit: true,
                delete: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];
  // Get Properties

  const data = PropertyData.filter(
    (e) =>
      e?.title?.toLowerCase().includes(search.toLowerCase()) ||
      e?.address?.address_line_1
        ?.toLowerCase()
        .includes(search.toLowerCase()) ||
      e?.property_sub_type?.toLowerCase().includes(search.toLowerCase()) ||
      e?.rent_amount?.toLocaleString()?.includes(search) ||
      (e?.address?.city + " " + e?.address?.state + " " + e?.address?.zipcode)
        .toLowerCase()
        .includes(search.toLowerCase())
  ).map((e) => ({
    key: e.id,
    propertyName: e.title,
    img: e.images,
    city: e.address.city,
    state: e.address.state,
    zipcode: e.address.zipcode,
    type: `${e.property_sub_type}`,
    address: e.address.address_line_1,
    rentAmount: `$${e.rent_amount.toLocaleString()}`,
  }));

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableItem([...selectedRowKeys]);
    },

    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  //Status Dropdown
  const onCloseEditModalTask = () => {
    setOpenEditModalTask(false);
  };

  const { bulkDelete } = BulkDelete(
    "property",
    selectedTableItem,
    fetchProperty
  );
  const DeleteSelectedProperty = () => {
    bulkDelete();
  };
  const fetchFun = () => {
    fetchProperty();
  };
  const isArchived = PropertyData?.filter((e) => e.id === deleteId);

  // handle delete property
  useEffect(() => {
    if (deleteId) {
      setIsLoading(true);
      archiveProperty(deleteId).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          setOpenDeleteModal(false);
          message.info("Property has moved to archive");
          setUpdate(!update);
          setDeleteId("");
        }
      });
      // setOpenDeleteModal(true);
    }
  }, [isDeletePropertyRender]);

  // handle multiple delete properties
  useEffect(() => {
    if (selectedTableItem.length > 0) {
      setOpenDeletePropertiesModal(false);
    }
  }, [isDeletePropertiesRender]);

  return (
    <>
      {console.log("======> ", data)}

      <DeleteModal
        isOpen={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
          setDeleteId("");
        }}
        message={
          "Once the property is deleted, it will move to the archive, and after 60 days, it will be deleted from the archive."
        }
        deleteCall={() => setIsDeletePropertyRender(!isDeletePropertyRender)}
        isLoading={isLoading}
      />

      <DeleteModal
        isOpen={openDeletePropertiesModal}
        onClose={() => setOpenDeletePropertiesModal(false)}
        message={
          "Once the properties are deleted, these will move to the archive, and after 60 days, it will be deleted from the archive."
        }
        deleteCall={() =>
          setIsDeletePropertiesRender(!isDeletePropertiesRender)
        }
        isLoading={isLoading}
      />

      {/* {openEditModalTask && (
        <AddPropertyEditModal
          setUpdate={setUpdate}
          id={taskId}
          onClose={onCloseEditModalTask}
        />
      )} */}

      <FormModalLayout
        title={"Edit Property"}
        isOpen={openEditModalTask}
        onClose={onCloseEditModalTask}
      >
        <AddPropertyEditModal
          id={taskId}
          onClose={() => {
            setOpenEditModalTask(false);
            setUpdate(!update);
          }}
        />
      </FormModalLayout>

      <div className="container-fluid d-lg-block p-3">
        <div className="row">
          <div className="search-bar mt-4">
            <form>
              <div className="input-icon">
                <span className="input-icon-addon">
                  <img src={searchIcon} alt="" className="icon" />
                </span>
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Search"
                />
              </div>
            </form>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-3">
            <div
              className={hideFilter === true ? "sub-headings mt-4" : "d-none"}
            >
              <h2 className="property-sub-title ">Filters</h2>
            </div>
          </div>
          <div
            className={
              hideFilter === true
                ? "col-md-9 d-flex justify-content-between"
                : "col-md-12 d-flex justify-content-between"
            }
          >
            <div className="sub-headings mt-4 d-flex gap-2 align-items-center">
              <h2 className="property-sub-title mb-0">Property</h2>
              <span className="badge badge-gray">
                <p className="badge-base-text">{PropertyData.length}</p>
              </span>
            </div>
            <div className="properties-dashbboard-btn-main btn-list mt-4">
              <button
                onClick={() => {
                  setHideFilter(!hideFilter);
                }}
                className="filter-btn"
              >
                <img
                  src={filterIcon}
                  alt=""
                  className="properties-filter-icon"
                />{" "}
                Filter
              </button>
              <button
                onClick={() => {
                  navigate("/add-property-details");
                }}
                className="add-property-btn"
              >
                <img src={plusIcon} alt="" className="add-property-icon" /> Add
                Property
              </button>
            </div>
          </div>
        </div>
        <div className={hideFilter === true ? "d-flex gap-4" : "d-block"}>
          <PropertyFilter hideFilter={hideFilter} />
          {selectedTableItem.length >= 1 && (
            <div className="table-delete-icon mt-3">
              <button
                onClick={() => {
                  setOpenDeletePropertiesModal(true);
                }}
                className="table-delete-btn next-btn-main"
              >
                <img src={trashIconWhite} alt="not found data" />
                Delete
              </button>
            </div>
          )}
          <div
            className={
              hideFilter === true
                ? "col-md-9 property-main-section mt-3"
                : "property-main-section mt-3"
            }
          >
            <div
              className={
                hideFilter === true
                  ? "scrollable-table ${isScrollActive ? 'active"
                  : ""
              }
            >
              <CustomTable
                pagination={true}
                columns={columns}
                dataSource={data}
                rowSelection={{
                  type: selectionType,
                  ...rowSelection,
                }}
                loading={loader}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertiesDashboard;
