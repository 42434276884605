import React, { useEffect } from "react";
import ImageSwiper from "Components/GeneralComponents/ImageSwiper";
import { Tabs, Skeleton } from "antd";
import DescriptionTab from "./DescriptionTab";
import { AmenitiesTab } from "Components/TenantPropertyUnitsView";
import Loader from "Helpers/Loader";

const OverviewTab = ({ data, isLoading }) => {
  const { TabPane } = Tabs;
  console.log("My Data", data);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ImageSwiper data={data} />
          <div></div>
          <Tabs defaultActiveKey="2">
            <TabPane tab="Description" key="2">
              <DescriptionTab data={data} />
            </TabPane>
            <TabPane tab="Amenities" key="3">
              <AmenitiesTab amenities={data?.amenities?.map((e) => e.name)} />
            </TabPane>
          </Tabs>
        </>
      )}
    </>
  );
};

export default OverviewTab;
