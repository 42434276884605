import React, { useEffect, useState } from "react";
import {
  message,
} from "antd";
import TenantPassportPaymentMethodModal from "Modals/TenantPassportPaymentMethodModal/TenantPassportPaymentMethodModal";
import TenantPassportInvitationSentModal from "Modals/TenantPassportInvitationSentModal/TenantPassportInvitationSentModal";
import UseGetHook from "Hooks/UseGetHook";
import config from "Helpers/config.js";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import { useNavigate } from "react-router-dom";
import * as InnerComponents from "./index";
import { fetchUserTenant } from "network/tenant/passport";

const TenantPassportProfile = () => {
  // States start.
  const [openModal, setOpenModal] = useState(false);
  const [openModalInvitation, setOpenModalInvitation] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [DeletedImages, setDeletedImages] = useState([]);
  const [Images, setImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [disabledFields, setDisabledFields] = useState([]);

  const navigate = useNavigate();

  const [form, setForm] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    dob: "",
    ssn: "",
    gender: "",
    maritalStatus: "",
    moveInDate: "",
    itin: "",
    address: "",
    country: "USA",
    city: "",
    state: "",
    city: "",
    state: "",
    zipCode: "",
    driverLicense: "",
    licenseNumber: "",
  });

  const [employerVerificationForm, setEmployerVerificationForm] = useState([
    {
      jobTitle: "",
      companyName: "",
      annualIncome: "",
      employmentStatus: "",
      startDate: "",
      endDate: "",
      supervisorName: "",
      supervisorEmail: "",
      supervisorPhone: "",
    },
  ]);

  const addWorkHistory = () => {
    setEmployerVerificationForm([
      ...employerVerificationForm,
      {
        jobTitle: "",
        companyName: "",
        annualIncome: "",
        employmentStatus: "",
        startDate: "",
        endDate: "",
        supervisorName: "",
        supervisorEmail: "",
        supervisorPhone: "",
      },
    ]);
  };

  const removeWorkHistory = (index) => {
    setEmployerVerificationForm(
      employerVerificationForm.filter((_, i) => i !== index)
    );
  };

  const [rentalHistoryForm, setRentalHistoryForm] = useState([
    {
      residenceType: "",
      streetAddress: "",
      aptOrUnit: "",
      state: "",
      zipCode: "",
      country: "USA",
      moveInDate: "",
      moveOutDate: "",
      referenceType: "",
      fullName: "",
      monthlyRentAmount: "",
      email: "",
      phone: "",
      images: [],
    },
  ]);

  const handleRentalHistoryChange = (field, index, value) => {
    const newRentalHistory = [...rentalHistoryForm];
    newRentalHistory[index][field] = value;
    setRentalHistoryForm(newRentalHistory);
  };

  const addRentalHistory = () => {
    setRentalHistoryForm([
      ...rentalHistoryForm,
      {
        residenceType: "",
        streetAddress: "",
        aptOrUnit: "",
        state: "",
        zipCode: "",
        country: "USA",
        moveInDate: "",
        moveOutDate: "",
        referenceType: "",
        fullName: "",
        monthlyRentAmount: "",
        email: "",
        phone: "",
      },
    ]);
  };

  const removeRentalHistory = (index) => {
    setRentalHistoryForm(rentalHistoryForm.filter((_, i) => i !== index));
  };

  const handleEmployerChange = (field, index, value) => {
    const newEmployerInfo = [...employerVerificationForm];
    newEmployerInfo[index][field] = value;
    setEmployerVerificationForm(newEmployerInfo);
  };

  const [incomeForm, setIncomeForm] = useState({
    yearlyIncome: "",
    details: "",
  });

  const handleIncomeChange = (fieldName, value) => {
    setIncomeForm({
      ...incomeForm,
      [fieldName]: value,
    });
  };

  const [screeningQuestions, setScreeningQuestions] = useState({
    pets: "",
    people: "",
    individuals: "",
    fullName: "",
    phone: "",
  });

  const handleScreeningQuestionsChange = (fieldName, value) => {
    setScreeningQuestions({
      ...screeningQuestions,
      [fieldName]: value,
    });
  };

  const { email, id } = UseUrlParamsHook();

  const handleProfileChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const { user, FetchUserTenant } = UseGetHook("userinfo");
  // States end
  useEffect(() => {
    FetchUserTenant();
  }, []);

  useEffect(() => {
    setLoader(true);
    fetchUserTenant().then((res) => {
      setLoader(false);
      if (res.apiCallStatus === "success") {
        console.log("User >>> ", res);
        setForm({
          ...form,
          firstName: res.message.firstName || "",
          middleName: res.message.middleName || "",
          lastName: res.message.lastName || "",
          email: res.message.email || "",
          phone: res.message.phone || "",
          dob: res.message.dob || "",
          address: res.message.addressLine_1 || "",
          city: res.message.city || "",
          state: res.message.state || "",
          zipCode: res.message.zipcode || "",
        });
        // set those fields to disabled which are already filled
        const fieldsToDisable = [];

        if (res.message.firstName) {
          fieldsToDisable.push("firstName");
        }
        if (res.message.middleName) {
          fieldsToDisable.push("middleName");
        }
        if (res.message.lastName) {
          fieldsToDisable.push("lastName");
        }
        if (res.message.email) {
          fieldsToDisable.push("email");
        }
        if (res.message.phone) {
          fieldsToDisable.push("phone");
        }
        if (res.message.dob) {
          fieldsToDisable.push("dob");
        }
        if (res.message.addressLine_1) {
          fieldsToDisable.push("address");
        }
        if (res.message.city) {
          fieldsToDisable.push("city");
        }
        if (res.message.state) {
          fieldsToDisable.push("state");
        }
        if (res.message.zipcode) {
          fieldsToDisable.push("zipCode");
        }

        setDisabledFields(fieldsToDisable);

        setProfileImage(res.message.profileImage);
      }
    });
  }, []);
  // States end

  const url = user[0]?.isTenant
    ? "/api/system/transunion/verify"
    : "/api/renter/transunion/createRenter";

  // Modal Function
  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  // Modal Function
  const onOpenModalInvitation = () => {
    setOpenModalInvitation(true);
  };
  const onCloseModalInvitation = () => {
    setOpenModalInvitation(false);
  };

  // States

  // Stepper Function
  const handleNext = () => {
    setCurrentStep(currentStep + 1);
    if (currentStep === 5) {
      setCurrentStep(1);
    }
  };
  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const CreateScreeningRequest = () => {
    setLoader(true);
    const newErrors = {};

    for (const key in incomeForm) {
      if (incomeForm[key] === "") {
        newErrors[key] = `${key} is required`;
        setLoader(false);
      }
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      fetch(`${config.baseUrl}${url}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          income: incomeForm.income,
          incomeFrequency: incomeForm.incomeFrequency,
          otherIncome: incomeForm.otherIncome,
          otherIncomeFrequency: incomeForm.incomeFrequency,
          assets: incomeForm.assets,
          employmentStatus: incomeForm.employmentStatus,
          dateOfBirth: incomeForm.dob,
          nationalId: incomeForm.ssn,
          landlordEmail: email,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.apiCallStatus === "success") {
            if (
              res.message.message ===
              "You already have one Screening Request, Can not create an other"
            ) {
              message.error(res.message.message);
              setLoader(false);
              setTimeout(() => {
                navigate(`/tenant-passport`);
              }, 2000);
            } else {
              setLoader(false);
              navigate(
                `/tenant-questions?id=${
                  id || res.message.screeningRequests_._id
                }&ssn=${incomeForm.ssn}`
              );
            }
          } else {
            message.error(res.error.message);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err, "errorr");
          setLoader(false);
        });
    }
  };

  return (
    <>
      {openModal === true ? (
        <TenantPassportPaymentMethodModal
          onOpen={onOpenModalInvitation}
          onClose={onCloseModal}
        />
      ) : (
        ""
      )}
      {openModalInvitation === true ? (
        <TenantPassportInvitationSentModal
          success="Share Your Profile"
          route="tenant-passport"
          onClose={onCloseModalInvitation}
        />
      ) : (
        ""
      )}
      <div className="container-fluid bg-white p-3">
        <div className="container">
          <InnerComponents.Stepper currentStep={currentStep} />
          <div className="stepper-content-container mt-4">
            {currentStep === 1 && (
              <InnerComponents.EditProfileForm
                form={form}
                handleChange={handleProfileChange}
                errors={errors}
                setProfileImages={setProfileImage}
                ProfileImages={profileImage}
                setDeletedImages={setDeletedImages}
                DeletedImages={DeletedImages}
                handleNext={handleNext}
                disabledFields={disabledFields}
              />
            )}
            {currentStep === 2 && (
              <InnerComponents.EmploymentVerificationForm
                form={employerVerificationForm}
                errors={errors}
                handleChange={handleEmployerChange}
                addWorkHistory={addWorkHistory}
                removeWorkHistory={removeWorkHistory}
                handleNext={handleNext}
                handlePrev={handlePrev}
              />
            )}
            {currentStep === 3 && (
              <InnerComponents.RentalHistoryForm
                form={rentalHistoryForm}
                errors={errors}
                handleChange={handleRentalHistoryChange}
                addRentalHistory={addRentalHistory}
                removeRentalHistory={removeRentalHistory}
                handleNext={handleNext}
                handlePrev={handlePrev}
              />
            )}
            {currentStep === 4 && (
              <InnerComponents.CreditAndBackgroundCheckForm
                form={incomeForm}
                errors={errors}
                Images={Images}
                setImages={setImages}
                handleChange={handleIncomeChange}
                handleNext={handleNext}
                handlePrev={handlePrev}
              />
            )}
            {currentStep === 5 && (
              <InnerComponents.ScreeningQuestionsForm
                form={screeningQuestions}
                errors={errors}
                handleChange={handleScreeningQuestionsChange}
                handleNext={CreateScreeningRequest}
                handlePrev={handlePrev}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TenantPassportProfile;
