import React, { useEffect } from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import SideDrawer from "Components/SideDrawer/SideDrawer";
import ResponsiveSideBar from "Components/ResponsiveSideBar/ResponsiveSideBar";
import { useSelector } from "react-redux";
import AccountingMain from "Components/AccountingMain/AccountingMain";
import UseGetHook from "Hooks/UseGetHook";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";

const AccountingMainPage = () => {
  // const { FetchUser, user, FetchUserTenant, FetchUserLandlordUser } =
  //   UseGetHook("userinfo");
  // useEffect(() => {
  //   FetchUser();
  //   FetchUserTenant();
  //   FetchUserLandlordUser();
  // }, []);
  // const myUserRoles = user[0]?.roles?.map((e) => e.role);
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  console.log("retrievedArray ------======>", myUserRoles);

  const drawer = useSelector((state) => {
    return state.sideDrawer.drawerState;
  });
  return (
    <>
      <div className="container-fluid">
        <div className="row hv-100">
          <div className="col-xl-2 p-sm-0 d-md-none d-lg-none d-xl-block d-none position-relative drawer-bg-primary">
            <SideDrawer />
          </div>
          <div
            className={
              drawer === true ? "res-drawer-bg-open" : "res-drawer-bg-close"
            }
          >
            <div
              className={
                drawer === true
                  ? "drawer-bg-primary responsive-drawer-container-open"
                  : "drawer-bg-primary responsive-drawer-container "
              }
            >
              <ResponsiveSideBar />
            </div>
          </div>
          <div className="col-xl-10 col-lg-12 col-md-12 mt-3">
            <div className="right-component bg-white h-100">
              <div className="container-fluid bg-default">
                <TitleTopBar topBarTitle="Accounting " />
              </div>
              {![
                "Owner/Executive",
                "Property Manager",
                "Accounting/Finance",
              ].some((role) => myUserRoles?.includes(role)) &&
              myUserRoles?.length !== 0 ? (
                <NoAccessPage />
              ) : (
                <AccountingMain />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountingMainPage;
