import { useEffect, useState } from "react";
import blackBoxStar from "assets/star.png";
import whiteBoxWallet from "assets/wallet-03.png";
import LandLordPackages from "Components/LandLordPackages/LandLordPackages";
import UseGetHook from "Hooks/UseGetHook";
import plusIconOrange from "assets/plus-orange.png";
import { Link } from "react-router-dom";
import UserPermission from "libs/UserPermission";
import { ConfigProvider, Table, message } from "antd";
import settingIcon from "assets/three-dots.png";
import * as Component from "Components/GeneralComponents";
import viewIcon from "assets/Icon.png";
import editIcon from "assets/edit-05.png";
import deleteIcon from "assets/trash-01.png";
import * as Icon from "assets/icons";

import config from "Helpers/config.js";
import FormAddPayment from "Modals/AddPaymentMethod/FormAddPayment";
import { ModalLayout1 } from "Components/GeneralComponents";
import { Divider, Form, Radio, Skeleton, Space, Switch } from "antd";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import masterCard from "assets/master-card.png";
import * as API from "network/landlord/Account";
import Countdown from "Components/GeneralComponents/Countdown";
import SettingSubscriptionCard from "Components/LandLordPackages/SettingSubscriptionCard";
const SettingSubscription = () => {
  // States start
  const [editCard, setEditCard] = useState(false);
  const [changePlan, SetChangePlan] = useState(false);
  const [managePayment, setManagePayment] = useState(false);
  const [payment, setPayment] = useState([]);
  const [upgradePlan, setupgradePlan] = useState(false);
  const [key, setKey] = useState([]);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [interval, setInterval] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isPayNowModelOpen, setIsPayNowModelOpen] = useState(false);
  const [options, setOptions] = useState({});
  const [user, setUser] = useState([]);
  // const { } =
  //   UseGetHook("userinfo");
  const [targetDate, setTargetDate] = useState(null);
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const { FetchUserRole } = UserPermission();
  // useEffect(() => {
  //   setTargetDate(user?.newPlanSubscriptionDate);
  // }, [user?.newPlanSubscriptionDate]);

  useEffect(() => {
    if (localStorage.getItem("role") === "tenant") {
      FetchUserTenant();
    } else if (localStorage.getItem("role") === "landlord") {
      FetchUser();
      FetchUserRole();
    } else {
      FetchUserLandlordUser();
      FetchUserRole();
    }
  }, []);

  const FetchUser = async () => {
    const response = await API.FetchUser();
    setUser(response.message);
  };
  const FetchUserTenant = async () => {
    const response = await API.FetchUserTenant();
    setUser(response.message);
  };

  const FetchUserLandlordUser = async () => {
    const response = await API.FetchUserLandlordUser();
    setUser(response.message);
  };
  // API.FetchPaymentMethod();

  const FetchPaymentMethod = async () => {
    const response = await API.FetchPaymentMethod();
    setIsLoading(false);
    const { message } = response;
    setPayment(message.data);
  };

  useEffect(() => {
    FetchPaymentMethod();
  }, []);

  // States end
  const { plan, FetchLandlordPlans } = UseGetHook("landlord");
  console.log("plan>><<<<>>>>><<<<", plan);
  // const { payment, FetchPaymentMethod } = UseGetHook(
  //   "getCustomerPaymentMethod",
  //   localStorage.getItem("email")
  // );
  useEffect(() => {
    FetchLandlordPlans();
    FetchPaymentMethod();
  }, [update]);
  useEffect(() => {
    if (update) {
      FetchLandlordPlans();
      FetchUserRole();
      FetchPaymentMethod();
    }
  }, [update]);

  const changePaymentMethod = (id) => {
    fetch(`${config.baseUrl}/api/stripe/makeDefaultPaymentMethod`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        paymentMethodId: id,
        role: localStorage.getItem("role"),
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.apiCallStatus === "success") {
          message.success("Payment method edit successful");
          setUpdate(true);
        }
      })
      .catch((err) => {
        // console.log(err, "error")
      });
  };
  const deletePaymentMethod = (id) => {
    if (data.length === 1) {
      message.error("You must have at least one payment method");
    } else {
      fetch(`${config.baseUrl}/api/stripe/payment-method/${id}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.apiCallStatus === "success") {
            message.success(res.message.message);
            setUpdate(!update);
            setIsLoading(!isLoading);
          } else {
          }
        });
    }
  };
  const onOpenAccountModal = () => {
    setOpenAccountModal(true);
  };
  const onCloseAddPaymentModal = () => {
    setOpenAccountModal(false);
  };
  // rowSelection object indicates the need for row selection
  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     // console.log(selectedTableItem.length);
  //     setSelectedTableItem([...selectedRowKeys]);
  //   },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.name === "Disabled User",
  //     name: record.name,
  //   }),
  // };
  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, prospectName) => (
        <>
          <Link to={`/prospect-details?id=${prospectName.key}`}>
            <span className="property-table-name-text ">
              <img
                src={`${prospectName.img}`}
                alt=""
                className="me-2 property-table-image mw_40 mh_40 me-2 rounded-5"
              />
              {text}
            </span>
          </Link>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text, phone) => (
        <>
          <span className="phone">{text}</span>
        </>
      ),
    },
    {
      title: "Card",
      dataIndex: "card_number",
      render: (text) => (
        <>
          <span>*******{text}</span>
        </>
      ),
    },
    {
      title: "Card type",
      dataIndex: "card",
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Expiry",
      dataIndex: "exp",
      render: (text, exp) => (
        <>
          <span>{`${exp.exp_month}/${exp.exp_year}`}</span>
        </>
      ),
    },
    {
      title: "Payment method type",
      dataIndex: "type",
      render: (text, exp) => (
        <>
          <span>{text ? "Primary" : "Secondary"}</span>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDeleteRecord: () => {
                  deletePaymentMethod(setting.key);
                },
              }}
              fields={{
                view: false,
                edit: true,
                postAnUpdate: false,
                complete: false,
                delete: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  //          <div
  //           className="position-relative cursor text-center"
  //           onClick={() => handleIconClick(setting.key)}
  //         >
  //           {setting.key === key && (
  //             <>
  //               <Icon.VerticalElipsisIcon width={23} height={23} />
  //               <Component.RecordMenuPopup
  //                 isOpen={setting.key === key}
  //                 onClose={() => setKey(null)}
  //                 handler={{
  //                   handleDeleteRecord: () => {
  //                     deletePaymentMethod(setting.key);
  //                   },
  //                   handleRenewLease: () => {},
  //                 }}
  //                 fields={{
  //                   view: true,
  //                   delete: true,
  //                   renewLease: setting.renew,
  //                 }}
  //               />
  //             </>
  //           )}
  //         </div>
  //          <div className="task-table-setting-container position-relative cursor">
  //           <img
  //             onClick={() => {
  //               handleIconClick(setting.key);
  //             }}
  //             src={settingIcon}
  //             alt=""
  //           />
  //           {setting.key === key && (
  //             <div className="task-table-setting-dropdown-prospect bg-white box-shadow">
  //               <ul className="p-0 d-flex flex-column gap-3">
  //                 {!setting.type && (
  //                   <li
  //                     onClick={() => changePaymentMethod(setting.key)}
  //                     className="list-style-none table-setting-dropdown-menu"
  //                   >
  //                     <img src={editIcon} alt="" /> Set as primary
  //                   </li>
  //                 )}
  //                 <li
  //                   onClick={() => deletePaymentMethod(setting.key)}
  //                   className="list-style-none table-setting-dropdown-menu"
  //                 >
  //                   {" "}
  //                   <img src={deleteIcon} alt="" /> Delete
  //                 </li>
  //               </ul>
  //             </div>
  //           )}
  //         </div>
  //         </div>
  //       </>

  //     ),
  //   },
  // ];

  useEffect(() => {
    if (user?.plan?.interval === "Annually") {
      setInterval(false);
    }
    if (user?.plan?.interval === "Monthly") {
      setInterval(true);
    } else {
      setInterval(false);
    }
  }, [user?.plan?.interval]);
  const data = payment?.map((e) => ({
    key: e.id,
    name: localStorage.getItem("name"),
    email: localStorage.getItem("email"),
    card_number: e.card.last4,
    card: e.card.brand,
    exp_month: e.card.exp_month,
    exp_year: e.card.exp_year,
    type: e.default_payment_method,
  }));

  // console.log(data, "hhhhhhhhhhhhhhhhh");
  const stripeKey = loadStripe(config.stripe_publish_key);
  const handlePayNow = (planName) => {
    const fetchClientSecret = () => {
      return fetch(`${config.baseUrl}/api/stripe/selectPlan`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          email: localStorage.getItem("email"),
          interval: !interval ? "Monthly" : "Annually",
          productName: planName,
        }),
      })
        .then((res) => res.json())
        .then((data) => data?.clientSecret);
    };
    setOptions({
      fetchClientSecret: fetchClientSecret,
      onComplete: () => {
        setIsPayNowModelOpen(false);
        FetchLandlordPlans();
        FetchUserLandlordUser();
        setTimeout(() => {
          message.success("Subscription updated successfully");
        }, 2000);
      },
    });

    setIsPayNowModelOpen(true);
  };
  const updateing = () => {
    setUpdate(true);
    setIsLoading(true);
  };
  return (
    <>
      {openDeleteModal && <></>}
      {openAccountModal && (
        <Component.FormModalLayout
          title={"ADD PAYMENT METHOD DETAILS"}
          isOpen={openAccountModal}
          onClose={onCloseAddPaymentModal}
        >
          <FormAddPayment
            onClose={onCloseAddPaymentModal}
            setUpdate={updateing}
          />
        </Component.FormModalLayout>
      )}

      <Countdown targetDate={targetDate} />
      {/* <p className="heading">SUBSCRIPTION</p> */}

      <div className=" d-flex justify-content-md-between flex-md-row flex-column gap-4 ps-4 pe-4  ">
        {user?.plan ? (
          <>
            <div
              className=""
              style={{ height: "190px", backgroundColor: "black" }}
            >
              <div className="p-3">
                <div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column pe-4">
                      <span
                        className=""
                        style={{ color: "white", textWrap: "nowrap" }}
                      >
                        Current Subscription Plan
                      </span>
                      <span className="primary-orange-text fs-5">
                        {user?.plan?.planName || "Free"}
                      </span>{" "}
                    </div>
                    <div>
                      <img src={blackBoxStar} alt="" />
                    </div>
                  </div>
                </div>
                <div className="sub-black-box-text">
                  <p className="m-0 pb-2" style={{ color: "white" }}>
                    ${user?.plan?.price?.toLocaleString() || "Free"}
                  </p>
                </div>
                <div>
                  <button
                    onClick={() => {
                      setupgradePlan(true);
                      SetChangePlan(!changePlan);
                      setManagePayment(false);
                    }}
                    className="sub-black-box-btn mt-2"
                  >
                    Change Plan
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Skeleton.Button
              active
              shape={"square"}
              block={false}
              style={{ width: "330px", height: "190px" }}
            />
          </>
        )}

        {data[0]?.card_number ? (
          <>
            <div
              className=""
              style={{
                height: "190px",
                border: "1px solid #f4f2f9",
              }}
            >
              <div className="p-3">
                <div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <div
                        className="drawer-text-grey pe-4"
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          textWrap: "nowrap",
                        }}
                      >
                        Payment Method
                      </div>
                    </div>
                    <div>
                      <img src={whiteBoxWallet} alt="" />
                    </div>
                  </div>
                </div>
                <div className="card-info-box d-flex flex-direction-row  gap-3 mb-3 ">
                  <div className="card-info-img">
                    <img src={masterCard} className="img-fluid" alt="" />
                  </div>
                  <div className="card-text" style={{ width: "200px" }}>
                    <p className="drawer-text-grey mb-0">
                      {" "}
                      <span style={{ fontSize: "12px" }}>
                        {data[0]?.card}
                      </span>{" "}
                      *******
                      <span
                        className="text-dark ms-3 f-italic"
                        style={{ fontSize: "12px" }}
                      >
                        {data[0]?.card_number}
                      </span>
                    </p>
                    <p
                      className="drawer-text-grey mb-0"
                      style={{ fontSize: "12px" }}
                    >
                      Exp. Date{" "}
                      <span className="text-dark ms-3 f-italic">{`${data[0]?.exp_month}/${data[0]?.exp_year}`}</span>
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => {
                    setManagePayment(!managePayment);
                    SetChangePlan(false);
                    setEditCard(false);
                  }}
                  className="sub-white-box-btn"
                >
                  Manage Payment{" "}
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <Skeleton.Button
              active
              shape={"square"}
              block={false}
              style={{ width: "330px", height: "190px" }}
            />
          </>
        )}
      </div>
      {/* 
      <div className="setting-sub-box-container d-flex align-items-center gap-5 mt-4 justify-content-between">
        <div className="setting-sub-black-box">
          <div className="sub-black-box-upper d-flex justify-content-between align-items-start p-3">
            <div className="sub-black-box-text">
              <span className="pb-2">Current Subscription Plan</span>
              
              <p className="m-0 pb-2">
                <span className="primary-orange-text fs-5">
                  {user?.plan?.planName || "Free"}
                </span>{" "}
              </p>
              <p className="m-0 pb-2">
                ${user?.plan?.price?.toLocaleString() || "Free"}
              </p>
              <button
                onClick={() => {
                  setupgradePlan(true);
                  SetChangePlan(!changePlan);
                  setManagePayment(false);
                }}
                className="sub-black-box-btn mt-2"
              >
                Change Plan
              </button>
            </div>

            <div className="sub-black-box-img">
              <img src={blackBoxStar} alt="" />
            </div>
          </div>
        </div>
        <div className="setting-sub-white-box">
          <div className="sub-white-box-upper d-flex justify-content-between align-items-start p-3">
            <div className="sub-black-box-text">
              <div
                className="drawer-text-grey mb-3"
                style={{ fontSize: "20px", fontWeight: "600" }}
              >
                Payment Method
              </div>
              <div className="card-info-box d-flex flex-direction-row  gap-3 mb-3">
                <div className="card-info-img">
                  <img src={masterCard} className="img-fluid" alt="" />
                </div>
                <div className="card-text">
                  <p className="drawer-text-grey mb-0">
                    {" "}
                    <span style={{ fontSize: "12px" }}>
                      {data[0]?.card}
                    </span>{" "}
                    *******
                    <span
                      className="text-dark ms-3 f-italic"
                      style={{ fontSize: "12px" }}
                    >
                      {data[0]?.card_number}
                    </span>
                  </p>
                  <p
                    className="drawer-text-grey mb-0"
                    style={{ fontSize: "12px" }}
                  >
                    Exp. Date{" "}
                    <span className="text-dark ms-3 f-italic">{`${data[0]?.exp_month}/${data[0]?.exp_year}`}</span>
                  </p>
                </div>
              </div>
              <button
                onClick={() => {
                  setManagePayment(!managePayment);
                  SetChangePlan(false);
                  setEditCard(false);
                }}
                className="sub-white-box-btn"
              >
                Manage Payment{" "}
              </button>
            </div>
            <div className="sub-black-box-img">
              <img src={whiteBoxWallet} alt="" />
            </div>
          </div>
        </div>
        </div> */}
      {/* cart components recreated */}

      <div className="sub-payment-box mt-3">
        <div className="sub-payment-heading row">
          {changePlan && (
            <>
              <div className="pb-2 d-flex justify-content-center">
                Subscription Plans
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center">
                {/* <div className="border-1 subscription-box w-auto mx-auto">
                <div className="px-1">
                  <button
                    className={!interval ? "active-sub monthly" : "monthly"}
                    onClick={() => setInterval(false)}
                  >
                    Monthly
                  </button>
                </div>
                <div className="">
                  <button
                    className={interval ? "active-sub annually" : " annually"}
                    onClick={() => setInterval(true)}
                  >
                    Annually
                  </button>
                </div>
              </div> */}
                <span
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#344054",
                    marginRight: "15px",
                  }}
                >
                  Monthly
                </span>
                <div>
                  <ConfigProvider
                    theme={{
                      components: {
                        Switch: {
                          colorPrimary: "#EF6B3E",
                          colorPrimaryHover: "#EF6B3E",
                          fontSize: 18,
                        },
                      },
                    }}
                  >
                    <Switch
                      size="large"
                      // defaultChecked={false}
                      onChange={(e) => {
                        setInterval(e);
                      }}
                    />
                  </ConfigProvider>
                </div>
                <span
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#344054",
                    marginLeft: "15px",
                  }}
                >
                  Annually
                </span>
              </div>
              {/* <div className="d-flex flex-column align-items-center">
                <p className="heading">SUBSCRIPTION</p>

                <div className="row me-1">
                  <div className="border-1 subscription-box ">
                    <div className="">
                      <button
                        className={!interval ? "active-sub monthly" : "monthly"}
                        onClick={() => setInterval(false)}
                      >
                        Monthly
                      </button>
                    </div>
                    <div className="">
                      <button
                        className={
                          interval ? "active-sub annually" : " annually"
                        }
                        onClick={() => setInterval(true)}
                      >
                        Annually
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
              <SettingSubscriptionCard
                data={plan}
                handlePayNow={handlePayNow}
                setUpdate={setUpdate}
                selectedPlan={user}
                interval={interval}
              />
            </>
          )}
        </div>
        {managePayment && (
          <>
            <div className="container px-4">
              <div className="d-flex justify-content-between mt-2">
                <p className="heading mb-5">PAYMENT</p>
                <button
                  className="add-subscription-btn-white"
                  onClick={onOpenAccountModal}
                >
                  <img
                    src={plusIconOrange}
                    className="add-property-icon-white"
                    alt=""
                  />{" "}
                  Add Payment Method
                </button>
              </div>
            </div>
            <div className="  mt-3">
              <Component.CustomTable
                loading={isLoading}
                pagination={true}
                columns={columns}
                dataSource={data}
                rowSelection={false}
              />
              {/* <ConfigProvider
                theme={{
                  components: {
                    Table: {
                      colorTextHeading: "#667085",

                      colorText: "#667085",
                      fontSize: 14,
                      fontWeightStrong: 500,
                      fontFamily: "Montserrat",
                    },
                    Checkbox: {
                      colorPrimary: "#EF6B3E",
                      colorPrimaryHover: "#EF6B3E",
                    },
                  },
                }}
              >
                <Table
                  pagination={false}
                  className="all-prospects-table-list scroll-remove scroll-responsive-tablet"
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                  }}
                  columns={columns}
                  dataSource={data}
                />
              </ConfigProvider> */}
            </div>
          </>
        )}
      </div>
      {/* Create Subscriber now Model */}
      <ModalLayout1
        isOpen={isPayNowModelOpen}
        onClose={() => setIsPayNowModelOpen(false)}
      >
        <EmbeddedCheckoutProvider stripe={stripeKey} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </ModalLayout1>
    </>
  );
};

export default SettingSubscription;
