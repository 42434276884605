import React, { useEffect, useState } from "react";
import { getVendor } from "network/landlord/vendor";
import Loader from "Helpers/Loader";

export const OverviewTab = ({ id }) => {
  const [vendorData, setVendorData] = useState({});
  const [isLoader, setIsLoader] = useState(false);

  // Get Prospect By Id
  useEffect(() => {
    setIsLoader(true);
    getVendor(id).then((res) => {
      setIsLoader(false);
      setVendorData(res.message.data);
      console.log("My VendorData", JSON.stringify(vendorData));
    });
  }, []);

  return (
    <>
      {isLoader ? (
        <Loader />
      ) : (
        <>
          <div className="row mt-5">
            <div className="col-md-10">
              <div className="task-info-heading">
                <h4>Personal Info</h4>
              </div>
              <div className="task-info-lists mt-5">
                <div className="row">
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        First Name:
                      </span>{" "}
                      {vendorData?.firstName || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Middle Name:
                      </span>{" "}
                      {vendorData?.middleName || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Last Name:
                      </span>{" "}
                      {vendorData?.lastName || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">Email:</span>{" "}
                      {vendorData?.email || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Phone No:
                      </span>{" "}
                      {vendorData?.phone || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">Company:</span>{" "}
                      {vendorData?.company || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Job Title:
                      </span>{" "}
                      {vendorData?.job_title || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Current Address:
                      </span>{" "}
                      {vendorData?.address?.address_line_1 || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Current Address 2:
                      </span>{" "}
                      {vendorData?.address?.address_line_2 || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">City:</span>{" "}
                      {vendorData?.address?.city || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">Country:</span>{" "}
                      {vendorData?.address?.country || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">State:</span>{" "}
                      {vendorData?.address?.state || "N/A"}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">ZipCode:</span>{" "}
                      {vendorData?.address?.zipcode || "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 text-end">
              <img
                className="img-fluid"
                src={vendorData?.profileImage}
                alt=""
              />
            </div>
          </div>
          <hr />
          <div className="row my-5">
            <div className="col-md-12">
              <div className="task-info-heading">
                <h4>SERVICES</h4>
              </div>
              <div className="task-info-lists mt-5">
                <div className="row">
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3"></span>{" "}
                      {vendorData?.services}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-5">
            <div className="col-md-12">
              <div className="task-info-heading">
                <h4>INSURANCE</h4>
              </div>
              <div className="task-info-lists mt-5">
                <div className="row">
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3 ">
                        Provider:
                      </span>{" "}
                      {vendorData?.insurance?.provider}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Effective Date:
                      </span>{" "}
                      {new Date(
                        vendorData?.insurance?.effective_date
                      ).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Expiration Date:
                      </span>{" "}
                      {new Date(
                        vendorData?.insurance?.expiration_date
                      ).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Policy Number:
                      </span>{" "}
                      {vendorData?.insurance?.policy_number}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Coverage:
                      </span>{" "}
                      {vendorData?.insurance?.coverage}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row my-5">
            <div className="col-md-12">
              <div className="task-info-heading">
                <h4>PROPERTIES</h4>
              </div>
              <div className="task-info-lists ps-3">
                <ol className="order-list-property mt-4">
                  {vendorData?.properties?.map((property) => (
                    <li className="task-info-list-span me-3">
                      <p>{property.title}</p>
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
