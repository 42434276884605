import React, { useState, useEffect } from "react";
import { ConfigProvider, Tabs } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import TaskOverviewTab from "./TaskOverview";
import * as InnerComponent from "Components/TenantDetailsView";
import {
  DeleteModal,
  RecordMenuPopup,
  FormModalLayout,
} from "Components/GeneralComponents";
import * as Icon from "assets/icons";
import { message } from "antd";
import { EditTaskForm } from "Components/AllTasks";
import { deleteTask } from "network/landlord/task";
import { OverviewTab } from "Components/TenantTaskDetails";
import { getTaskById } from "network/landlord/task";

const { TabPane } = Tabs;
const TaskDetails = () => {
  const search = useLocation().search;
  const navigate = useNavigate();
  const id = new URLSearchParams(search).get("id");
  console.log("TaskDetails -> id", id);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [openEditModalTask, setOpenEditModalTask] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleIconClickCustom = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const [task, setTask] = useState("");

  useEffect(() => {
    setIsLoading(true);
    getTaskById(id).then((res) => {
      if (res.apiCallStatus === "success") {
        setTask(res.message.data);
        setIsLoading(false);
      }
    });
  }, [id]);

  const deleteTaskCall = (id) => {
    setIsLoading(true);
    deleteTask(id).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setOpenDeleteModal(false);
        message.success("Task Deleted Successfully");
        navigate("/all-task");
      }
    });
  };

  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        message={"Are you sure you want to delete this task?"}
        deleteCall={() => deleteTaskCall(id)}
        isLoading={isLoading}
      />

      <FormModalLayout
        title={"Edit Task"}
        isOpen={openEditModalTask}
        onClose={() => setOpenEditModalTask(false)}
      >
        <EditTaskForm id={id} onClose={() => setOpenEditModalTask(false)} />
      </FormModalLayout>
      <div className="container-fluid bg-white p-3 ">
        <div className="row">
          <div className="col-md-12 text-end">
            <span className="position-relative">
              <div className="cursor" onClick={handleIconClickCustom}>
                <Icon.VerticalElipsisIcon width={23} height={23} />
                {
                  <RecordMenuPopup
                    isOpen={isDropdownOpen}
                    onClose={() => {}}
                    handler={{
                      handleEdit: () => {
                        setOpenEditModalTask(true);
                      },
                      handleDeleteRecord: () => {
                        setOpenDeleteModal(true);
                      },
                    }}
                    fields={{
                      edit: task.updates?.length > 1 ? false : true,
                      delete: true,
                    }}
                  />
                  // )
                }
              </div>
            </span>
          </div>
        </div>
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                inkBarColor: "#EF6B3E",
                itemColor: "#667085",
                itemSelectedColor: "#EF6B3E",
                itemHoverColor: "#EF6B3E",
                titleFontSize: 15,
                horizontalItemGutter: window.innerWidth <= 425 ? 10 : 30,
                fontFamily: "Montserrat",
              },
            },
          }}
        >
          <Tabs
            centered
            defaultActiveKey="1"
            style={{ fontWeight: 500 }}
            className="property_details_view_tabs"
          >
            <TabPane tab="Overview" key="1">
              <OverviewTab task={task} isLoading={isLoading} />
            </TabPane>
            <TabPane tab="Notes" key="2">
              <InnerComponent.NotesTab id={id} category={"task"} />
            </TabPane>
            <TabPane tab="Files" key="3">
              <InnerComponent.FilesTab id={id} category={"task"} />
            </TabPane>
          </Tabs>
        </ConfigProvider>
      </div>
    </>
  );
};

export default TaskDetails;
