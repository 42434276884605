import React, { useState, useEffect } from "react";
import settingIcon from "assets/three-dots.png";
import { useNavigate, Link } from "react-router-dom";
import { ConfigProvider } from "antd";
import { usePDF } from "react-to-pdf";
import viewIcon from "assets/Icon.png";
import editIcon from "assets/edit-05.png";
import completeIcon from "assets/calendar-check-01.png";
import deleteIcon from "assets/trash-01.png";
import SearchBar from "Helpers/SearchBar";
import { Table } from "antd";
import { useSelector } from "react-redux";
import MileageDeleteModal from "Modals/MileageDeleteModal/MileageDeleteModal";
import MileageEditModal from "Modals/MileageEditModal/MileageEditModal";
import UseGetHook from "Hooks/UseGetHook";
import trashIconWhite from "assets/trash-icon-white.png";
import BulkDelete from "Hooks/BulkDelete";
import UserPermission from "libs/UserPermission";
import SearchBarWithOutBtn from "Helpers/SearchBarWithOutBtn";
import { ButtonVariant1 } from "Components/GeneralComponents";
import { FilterValue } from "Store/Slices/FilterSlice";
import { useDispatch } from "react-redux";
const Mileage = () => {
  const { ROLE } = UserPermission();
  // States start
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
  const [selectionType, setSelectionType] = useState("checkbox");
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [key, setKey] = useState([]);
  const [openEditModalMileage, setOpenEditModalMileage] = useState(false);
  const [mileageId, setMileageId] = useState("");
  const [update, setUpdate] = useState(false);
  // States end

  const dispatch = useDispatch();
  const Search = useSelector((state) => {
    return state.Search.value;
  });
  // Get Mileages
  const { fetchMileage, MileageData } = UseGetHook("mileages");
  useEffect(() => {
    if (update) {
      fetchMileage();
      setUpdate(false);
    }
  }, [update]);
  useEffect(() => {
    fetchMileage();
  }, []);
  const navigate = useNavigate();

  // Data Table functions
  const data = MileageData.filter((data) =>
    data.businessName.toLowerCase().includes(Search.toLowerCase())
  ).map((e) => ({
    key: e.id,
    businessName: e.businessName,
    fromLocation: e.fromLocation,
    toLocation: e.toLocation,
    //tripDate: new Date(e.tripDate).toISOString().split('T')[0],
    tripDate: new Date(e.tripDate).toLocaleDateString(),
    miles: e.miles,
    amount: e.amount,
  }));

  // Modal Function
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onOpenEditModalMileage = () => {
    setOpenEditModalMileage(true);
  };

  const onCloseEditModalMileage = () => {
    setOpenEditModalMileage(false);
  };

  const resetFilter = () => {
    dispatch(FilterValue({ reset: "reset" }));
  };

  const range = useSelector((state) => {
    return state.FilterValue.value;
  });
  const property = useSelector((state) => {
    return state.FilterValue.property;
  });
  const fromDate = useSelector((state) => {
    return state.FilterValue.fromDate;
  });
  const toDate = useSelector((state) => {
    return state.FilterValue.toDate;
  });

  const columns = [
    {
      title: "Business Name",
      dataIndex: "businessName",
      render: (text, businessName) => (
        <Link to={`/mileage-view?id=${businessName.key}`}>
          <span className="mileage-main-table-bold-text">{text}</span>
        </Link>
      ),
    },
    {
      title: "From",
      dataIndex: "fromLocation",
      render: (text, fromLocation) => (
        <>
          <span className="mileage-main-table-bold-text">{text}</span>
        </>
      ),
    },
    {
      title: "To",
      dataIndex: "toLocation",
      render: (text, toLocation) => (
        <>
          <span className="mileage-main-table-bold-text">{text}</span>
        </>
      ),
    },
    {
      title: "Trip Date",
      dataIndex: "tripDate",
    },
    {
      title: "Miles",
      dataIndex: "miles",
      render: (text, miles) => <span>{text}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text, amount) => <span>${text}</span>,
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div className="task-table-setting-container position-relative cursor">
            <img
              src={settingIcon}
              alt=""
              onClick={() => handleIconClick(setting.key)}
            />
            {setting.key === key && (
              <div className="task-table-setting-dropdown-prospect bg-white box-shadow">
                <ul className="p-0 d-flex flex-column gap-3">
                  <Link to={`/mileage-view?id=${setting.key}`}>
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={viewIcon} alt="" /> View
                    </li>
                  </Link>
                  <Link>
                    <li
                      onClick={() => {
                        onOpenEditModalMileage();
                        setMileageId(setting.key);
                      }}
                      className="list-style-none table-setting-dropdown-menu"
                    >
                      {" "}
                      <img src={editIcon} alt="" /> Edit
                    </li>
                  </Link>

                  <Link>
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={completeIcon} alt="" /> Complete
                    </li>
                  </Link>
                  <Link>
                    <li
                      onClick={() => {
                        onOpenModal();
                        setDeleteId(setting.key);
                      }}
                      className="list-style-none table-setting-dropdown-menu"
                    >
                      {" "}
                      <img src={deleteIcon} alt="" /> Delete
                    </li>
                  </Link>
                </ul>
              </div>
            )}
          </div>
        </>
      ),
    },
  ];
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableItem([...selectedRowKeys]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const { bulkDelete } = BulkDelete("mileage", selectedTableItem, fetchMileage);
  const DeleteSelected = () => {
    bulkDelete();
  };
  // Data Table Functions
  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };
  return (
    <>
      {openModal && (
        <MileageDeleteModal
          deleteBtnText="Delete Mileage"
          route="mileage-view"
          onClose={onCloseModal}
          delId={deleteId}
        />
      )}
      {openEditModalMileage && (
        <MileageEditModal
          id={mileageId}
          onClose={onCloseEditModalMileage}
          setUpdate={setUpdate}
        />
      )}
      <div className="container-fluid bg-white p-3">
        <div className="row">
          <div className="col-md-12">
            <SearchBar
              onClick={() => {
                navigate("/add-mileage");
              }}
              btnTitle="Add New Mileage"
              accountingFilter={true}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            {selectedTableItem.length >= 1 && (
              <div className="table-delete-icon mt-3">
                <button
                  onClick={DeleteSelected}
                  className="table-delete-btn next-btn-main"
                >
                  <img src={trashIconWhite} />
                  Delete
                </button>
              </div>
            )}
            <div className="pdf-data-container" ref={targetRef}>
              {range || property || fromDate || toDate ? (
                <div className="mt-2" style={{ width: "fit-content" }}>
                  <ButtonVariant1 handler={resetFilter} children={"Reset"} />
                </div>
              ) : (
                ""
              )}
              <ConfigProvider
                theme={{
                  components: {
                    Table: {
                      colorTextHeading: "#667085",
                      colorText: "#667085",
                      fontSize: 14,
                      fontWeightStrong: 500,
                      fontFamily: "Montserrat",
                    },
                    Checkbox: {
                      colorPrimary: "#EF6B3E",
                      colorPrimaryHover: "#EF6B3E",
                    },
                  },
                }}
              >
                <Table
                  className="scroll-remove scroll-responsive-tablet"
                  pagination={false}
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                  }}
                  columns={columns}
                  dataSource={data}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mileage;
