import React, { useState, useEffect } from "react";
import * as Component from "Components/GeneralComponents";
import {
  propertyStatus,
  selectMultiColumns,
  usaStates,
} from "utils/dropdownData";

const PropertyDetails = ({
  form,
  handleChange,
  errors,
  Images,
  setImages,
  choosePropertyTypeCommercial,
  handleNext,
  handlePrev,
}) => {
  return (
    <div className="container-fluid mt-5 mb-4">
      <div className="row mt-5">
        <div className="col-md-12">
          <Component.InputField
            name={"title"}
            required={true}
            label={"Property Title"}
            placeholder={"Property Title"}
            value={form.title}
            handler={(e) => handleChange("title", e.target.value)}
            errorMessage={errors.title}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <Component.SelectField
            name={"status"}
            required={true}
            label={"Property Status"}
            placeholder={"Property Status"}
            value={form.status}
            options={propertyStatus}
            handler={(e) => handleChange("status", e)}
            errorMessage={errors.status}
          />
        </div>
      </div>
      <Component.SelectMultipleColumns
        label={"Select Amenities"}
        placeholder={"Select Amenities"}
        values={form.amenities}
        options={selectMultiColumns}
        handleChange={(e) => {
          console.log("e", e);
          handleChange("amenities", e);
        }}
      />
      {choosePropertyTypeCommercial ? (
        ""
      ) : (
        <>
          <div className="row mt-3">
            <div className="col-md-6">
              <Component.InputField
                name={"bedroom"}
                required={true}
                type="number"
                label={"Bedrooms"}
                placeholder={"Bedrooms"}
                value={form.bedroom}
                handler={(e) => handleChange("bedroom", e.target.value)}
                errorMessage={errors.bedroom}
              />
            </div>
            <div className="col-md-6">
              <Component.InputField
                name={"bathroom"}
                required={true}
                type="number"
                label={"Bathrooms"}
                placeholder={"Bathrooms"}
                value={form.bathroom}
                handler={(e) => handleChange("bathroom", e.target.value)}
                errorMessage={errors.bathroom}
              />
            </div>
          </div>
        </>
      )}

      <div className="row mt-3">
        <div className="col-md-6">
          <Component.InputField
            name={"area"}
            required={true}
            type="number"
            label={"SqFt"}
            placeholder={"SqFt"}
            value={form.area}
            handler={(e) => handleChange("area", e.target.value)}
            errorMessage={errors.area}
          />
        </div>
        <div className="col-md-6">
          <Component.AmountField
            name={"rent"}
            required={true}
            label={"Rent Amount"}
            placeholder={"Enter Rent Amount"}
            value={form.rent}
            handler={(value) => handleChange("rent", value)}
            errorMessage={errors.rent}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <Component.InputField
            name={"address"}
            required={true}
            label={"Address Line 1"}
            placeholder={"Address Line 1"}
            value={form.address}
            handler={(e) => handleChange("address", e.target.value)}
            errorMessage={errors.address}
          />
        </div>
        <div className="col-md-6">
          <Component.InputField
            name={"address2"}
            label={"Address Line 2"}
            placeholder={"Address Line 2"}
            value={form.address2}
            handler={(e) => handleChange("address2", e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-3">
          <Component.SelectField
            name={"country"}
            required={true}
            label={"Country"}
            placeholder={"Country"}
            value={"USA"}
            options={[{ value: "USA" }, { label: "Canada" }]}
            handler={(e) => handleChange("country", e)}
            errorMessage={errors.country}
            disabled={true}
          />
        </div>
        <div className="col-md-3">
          <Component.InputField
            name={"city"}
            required={true}
            label={"City"}
            placeholder={"City"}
            value={form.city}
            handler={(e) => handleChange("city", e.target.value)}
            errorMessage={errors.city}
          />
        </div>
        <div className="col-md-3">
          <Component.SelectField
            name={"state"}
            required={true}
            label={"State"}
            placeholder={"Select State"}
            value={form.state}
            options={usaStates}
            handler={(e) => handleChange("state", e)}
            errorMessage={errors.state}
          />
        </div>
        <div className="col-md-3">
          <Component.InputField
            name={"Postal code"}
            required={true}
            label={"Zip Code"}
            placeholder={"Zip Code"}
            value={form.zipCode}
            handler={(e) => {
              if (/^\d{0,5}$/.test(e.target.value))
                handleChange("zipCode", e.target.value);
            }}
            errorMessage={errors.zipCode}
          />
        </div>
      </div>
      <div className="row mt-3">
        <Component.TextAreaField
          name={"description"}
          label={"Description"}
          placeholder={"Add your property description here"}
          value={form.description}
          handler={(e) => handleChange("description", e.target.value)}
          rows={"10"}
          cols={"30"}
        />
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <Component.MediaField
            required={true}
            label={"Properties Images"}
            setImages={setImages}
            Images={Images}
            errorMessage={errors.images}
          />
        </div>
      </div>
      <div className="stepper-btn d-flex justify-content-between mt-5 gap-1">
        <div style={{ width: "50%" }}>
          <Component.SubmitButton type="contained" handler={handlePrev}>
            Back
          </Component.SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <Component.SubmitButton handler={handleNext}>
            Next
          </Component.SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;
