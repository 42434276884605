import React from "react";
import CostumerQueries from "Components/CostumerQueries/CostumerQueries";
import ResponsiveSideBar from "Components/ResponsiveSideBar/ResponsiveSideBar";
import TitleTopBar from "Helpers/TitleTopBar";
import SideDrawer from "Components/SideDrawer/SideDrawer";
import { useSelector } from "react-redux";
import CostumerQueriesInner from "Components/CostumerQueriesInner/CostumerQueriesInner";

const CostumerQueriesInnerPage = () => {
  const drawer = useSelector((state) => {
    return state.sideDrawer.drawerState;
  });
  return (
    <>
      <div className="container-fluid">
        <div className="row hv-100">
          <div className="col-xl-2 p-sm-0 d-md-none d-lg-none d-xl-block d-none position-relative drawer-bg-primary">
            <SideDrawer />
          </div>
          <div
            className={
              drawer === true ? "res-drawer-bg-open" : "res-drawer-bg-close"
            }
          >
            <div
              className={
                drawer === true
                  ? "drawer-bg-primary responsive-drawer-container-open"
                  : "drawer-bg-primary responsive-drawer-container "
              }
            >
              <ResponsiveSideBar />
            </div>
          </div>
          <div className="col-xl-10 col-lg-12 col-md-12 mt-3">
            <div className="right-component bg-white h-100">
              <div className="container-fluid bg-default">
                <TitleTopBar
                  topBarTitleDetail={"Customer Queries"}
                  back={"costumer-queries"}
                />
              </div>
              <CostumerQueriesInner />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CostumerQueriesInnerPage;
