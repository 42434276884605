import React from "react";
import ProfileUploader from "../ProfileUploader/ProfileUploader";
import { usaStates } from "utils/dropdownData";
import * as Component from "Components/GeneralComponents";

const VendorDetails = ({
  form,
  handleChange,
  errors,
  handleNext,
  setProfileImages,
  ProfileImages,
  setDeletedImages,
  DeletedImages,
  disableEmail = false,
  isLoading,
}) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <div className="stepper-content d-flex align-items-center">
            <div className="dragger">
              <ProfileUploader
                setProfileImages={setProfileImages}
                ProfileImages={ProfileImages}
                setDeletedImages={setDeletedImages}
                DeletedImages={DeletedImages}
              />
            </div>
            <p
              className="
                        ps-3"
            >
              Your Photo
            </p>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <Component.InputField
            name={"first_name"}
            required={true}
            label={"First Name"}
            placeholder={"Enter First Name"}
            value={form.first_name}
            handler={(e) => handleChange("first_name", e.target.value)}
            errorMessage={errors.first_name}
          />
        </div>
        <div className="col-md-4">
          <Component.InputField
            name={"middle_name"}
            label={"Middle Name"}
            placeholder={"Enter Middle Name"}
            value={form.middle_name}
            handler={(e) => handleChange("middle_name", e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <Component.InputField
            name={"last_name"}
            required={true}
            label={"Last Name"}
            placeholder={"Enter Last Name"}
            value={form.last_name}
            handler={(e) => handleChange("last_name", e.target.value)}
            errorMessage={errors.last_name}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <Component.InputField
            name={"email"}
            required={true}
            label={"Email"}
            placeholder={"Enter Email"}
            value={form.email}
            handler={(e) => handleChange("email", e.target.value)}
            errorMessage={errors.email}
            disabled={disableEmail}
          />
        </div>
        <div className="col-md-6">
          <Component.PhoneNumberField
            name={"phone_no"}
            required={true}
            label={"Phone No"}
            placeholder={"Phone No"}
            value={form.phone_no}
            handler={(value) => handleChange("phone_no", value)}
            errorMessage={errors.phone_no}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <Component.InputField
            name={"company"}
            required={true}
            label={"Company"}
            placeholder={"Enter Company"}
            value={form.company}
            handler={(e) => handleChange("company", e.target.value)}
            errorMessage={errors.company}
          />
        </div>
        <div className="col-md-6">
          <Component.InputField
            name={"job"}
            required={true}
            label={"Job Title"}
            placeholder={"Enter Job Title"}
            value={form.job}
            handler={(e) => handleChange("job", e.target.value)}
            errorMessage={errors.job}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <Component.InputField
            name={"address1"}
            required={true}
            label={"Address Line 1"}
            placeholder={"Enter Address Line 1"}
            value={form.address1}
            handler={(e) => handleChange("address1", e.target.value)}
            errorMessage={errors.address1}
          />
        </div>
        <div className="col-md-6">
          <Component.InputField
            name={"address2"}
            label={"Address Line 2"}
            placeholder={"Enter Address Line 2"}
            value={form.address2}
            handler={(e) => handleChange("address2", e.target.value)}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-3">
          <Component.SelectField
            name={"Country"}
            required={true}
            label={"Country"}
            placeholder={"Select Country"}
            value={"USA"}
            options={[{ name: "USA" }, { name: "Canada" }]}
            handler={(e) => handleChange("country", e)}
            errorMessage={errors.country}
            disabled={true}
          />
        </div>
        <div className="col-md-3">
          <Component.InputField
            name={"city"}
            required={true}
            label={"City"}
            placeholder={"Enter City"}
            value={form.city}
            handler={(e) => handleChange("city", e.target.value)}
            errorMessage={errors.city}
          />
        </div>
        <div className="col-md-3">
          <Component.SelectField
            name={"State"}
            required={true}
            label={"State"}
            placeholder={"Select State"}
            value={form.state}
            options={usaStates}
            handler={(e) => handleChange("state", e)}
            errorMessage={errors.state}
          />
        </div>
        <div className="col-md-3">
          <Component.InputField
            name={"Postal code"}
            required={true}
            label={"Zip Code"}
            placeholder={"Zip Code"}
            value={form.zipCode}
            handler={(e) => {
              if (/^\d{0,5}$/.test(e.target.value))
                handleChange("zipCode", e.target.value);
            }}
            errorMessage={errors.zipCode}
          />
        </div>
      </div>
      <div className="stepper-first-btn d-flex justify-content-between mt-5">
        <Component.SubmitButton handler={handleNext} isLoading={isLoading}>
          Next
        </Component.SubmitButton>
      </div>
    </div>
  );
};

export default VendorDetails;
