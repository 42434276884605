import React, { useState } from "react";
import * as Icon from "assets/icons";
import { Tooltip } from "antd";
import { SelectField } from "Components/GeneralComponents";
import { SubmitButton } from "Components/GeneralComponents";
import { selectactiveinactivearchive, usaStates } from "utils/dropdownData";
import * as Componennt from "Components/GeneralComponents";
const PropertyFilter = ({ hideFilter }) => {
  // States
  const [sliderValue, setSliderValue] = useState([1, 6000]);
  const [filter, setFilter] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedIcon, setSelectedIcon] = useState("");
  const beds = [
    "Select All",
    "2 Bedroom",
    "3 Bedroom",
    "4 Bedroom",
    "5 Bedroom",
  ];
  const bath = [
    "Select All",
    "2 Bathroom",
    "3 Bathroom",
    "4 Bathroom",
    "5 Bathroom",
  ];
  const rangeHandleChange = (value) => {
    setSliderValue(value);
  };

  const handlefilter = () => {
    setIsDisabled(false);
    handleChange("max", sliderValue[1]);
    handleChange("min", sliderValue[0]);
  }
  const handlereset = () => {
    setIsDisabled(true);
    setFilter([]);
    setSliderValue([1, 6000]);
  }

  const handleInputChange = (index, event) => {
    const newValue = [...sliderValue];
    newValue[index] = Number(event.target.value);
    setSliderValue(newValue);
  };

  const handleIconClickImages = (type) => {
    setSelectedIcon(type);
    handleChange("property_sub_type", type);
  };
  const handleChange = (fieldName, value) => {
    setFilter((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handlebedchange = (e, label) => {
    const bedvalue = {
      ...filter.bedrooms,
      [label]: e.target.checked,
    };
    setFilter((prev) => ({ ...prev, bedrooms: bedvalue }));
  };
  const handlebathChange = (e, label) => {
    const bedvalue = {
      ...filter.bathrooms,
      [label]: e.target.checked,
    };
    setFilter((prev) => ({ ...prev, bathrooms: bedvalue }));
  };

 

  return (
    <>
      {console.log("filter data -------->", filter)}
      <div
        className={
          hideFilter === true ? "col-md-3 filter-main-section" : "d-none"
        }
      >
        <div className="filter-dropdown-section">
          <span className="filter-sub-title">Filter By</span>
        </div>
        <div className="row mt-3">
          <SelectField
            name={"status"}
            required={false}
            label={"Property Status"}
            placeholder={"Property Status"}
            value={filter.status}
            options={selectactiveinactivearchive}
            handler={(e) => handleChange("status", e)}
            errorMessage={""}
          />
        </div>
        <div className="row mt-3">
          <SelectField
            name={"state"}
            required={false}
            label={"State"}
            placeholder={"Select State"}
            value={filter.state}
            options={usaStates}
            handler={(e) => handleChange("state", e)}
            errorMessage={""}
          />
        </div>
        <div className="location-dropdown-section mt-4  ">
          <span className="filter-sub-title">Property Type</span>
          <br />
          <div className="icon-main-apan">
            <Tooltip title="Residential">
              <span
                className={
                  selectedIcon === "single"
                    ? "active-property property-type-icons p-2 cursor"
                    : "property-type-icons p-2 cursor"
                }
              >
                <Icon.ResdentialIcon
                  handleIconClickImages={handleIconClickImages}
                  selectedIcon={selectedIcon}
                />
              </span>
            </Tooltip>
            <Tooltip title="Commercial">
              <span
                className={
                  selectedIcon === "multi_famly"
                    ? "active-property property-type-icons p-2 cursor"
                    : "property-type-icons p-2 cursor"
                }
              >
                <Icon.ComerecialIcon
                  handleIconClickImages={handleIconClickImages}
                  selectedIcon={selectedIcon}
                />
              </span>
            </Tooltip>
          </div>
        </div>
        <span className="filter-sub-title mt-4">Bedroom</span>
        <div className="location-dropdown-section row">
          {beds.map((bed, index) => {
            return (
              <div className="d-flex align-items-center col-md-6">
                <Componennt.Checkbox
                  label={bed}
                  onChange={(e) => handlebedchange(e, bed)}
                  checked={filter.bedrooms?.[bed]}
                />
              </div>
            );
          })}
        </div>
        <div className="location-dropdown-section mt-4">
          <span className="filter-sub-title">Rent Amount Range</span>
          <Componennt.NumberRangeSelector
            rangeHandleChange={rangeHandleChange}
            sliderValue={sliderValue}
            handleInputChange={handleInputChange}
          />
        </div>
        <span className="filter-sub-title mt-4">Bathroom</span>
        <div className="location-dropdown-section row">
          {bath.map((bath, index) => {
            return (
              <div className="d-flex align-items-center col-md-6">
                <Componennt.Checkbox
                  label={bath}
                  onChange={(e) => handlebathChange(e, bath)}
                  checked={filter.bathrooms?.[bath]}
                />
              </div>
            );
          })}
        </div>
        <div className="apply-now-filter-btn mt-4">
        <SubmitButton handler={ handlefilter }>  Apply Now  </SubmitButton>
        </div>
        <div className="apply-now-filter-btn mt-4">
        <button onClick={ handlereset } className={`${isDisabled ? 'disabledbutton' : 'add-new-task-btn'}`} >
            Reset
          </button>
        </div>
      </div>
    </>
  );
};

export default PropertyFilter;
