import { useState, useEffect } from "react";
import NotFound from "assets/not-found-img.png";
import { Link, useNavigate } from "react-router-dom";
import settingIcon from "assets/three-dots.png";
import trashIconWhite from "assets/trash-icon-white.png";
import activeDot from "assets/_Dot.png";
import SearchBar from "Helpers/SearchBar";
import ConvertTenantModal from "Modals/ConvertTenantModal/ConvertTenantModal";
import {
  DeleteModal,
  InfoModal,
  RecordMenuPopup,
} from "Components/GeneralComponents";
import { useSelector } from "react-redux";
import AddProspectDetailsEditModal from "Modals/AddProspectDetailsEditModal/AddProspectDetailsEditModal";
import config from "Helpers/config.js";
import { message } from "antd";
import ReportViewModal from "Modals/ReportViewModal/ReportViewModal";
import SearchBarWithOutBtn from "Helpers/SearchBarWithOutBtn";
import UserPermission from "libs/UserPermission";
import { formatPhoneNumber } from "utils";
import * as API from "network/landlord/prospect";
import * as Component from "Components/GeneralComponents";

const AllProspect = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [DeleteId, setDeleteId] = useState("");
  const [key, setKey] = useState([]);
  const [openEditModalTask, setOpenEditModalTask] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [prospectId, setProspectId] = useState("");
  const [update, setUpdate] = useState(false);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [email, setEmail] = useState("");
  const [prospects, setProspects] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isDeleteProspectRender, setIsDeleteProspectRender] = useState(false);
  const [isDeleteProspectsRender, setIsDeleteProspectsRender] = useState(false);
  const [isRerender, setIsRerender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteProspectModal, setIsDeleteProspectModal] = useState(false);
  const [filteredProspects, setFilteredProspects] = useState([]);
  const [isInfoModal, setIsInfoModal] = useState(false);
  const [newLeaseId, setNewLeaseId] = useState("");
  const [filter, setFilter] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    range: "",
    property: "",
  });

  const navigate = useNavigate();

  // get prospects with filter
  useEffect(() => {
    setIsTableLoading(true);
    API.getProspects(filter).then((res) => {
      setIsTableLoading(false);
      if (res.apiCallStatus === "success") {
        setProspects(
          res.message.map((e) => ({
            key: e._id,
            prospectName: `${e.firstName ? e.firstName + " " : ""}${
              e.lastName ? e.lastName : ""
            }`.trim(),
            img: e.profileImage,
            status: e.verification_status,
            phone: e.phone,
            email: e.email,
            created_at: new Date(e.createdAt).toLocaleDateString(),
          }))
        );
      }
    });
  }, [isRerender]);

  // handle delete prospect
  useEffect(() => {
    if (DeleteId) {
      setIsLoading(true);
      API.deleteProspect(DeleteId).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          onCloseDeleteModal();
          message.success("Prospect Deleted Successfully");
          setIsRerender(!isRerender);
          setDeleteId("");
        }
      });
    }
  }, [isDeleteProspectRender]);

  // handle multiple delete prospects
  useEffect(() => {
    if (selectedTableItem.length > 0) {
      setIsLoading(true);
      API.DeleteBulkProspect(selectedTableItem).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          setIsDeleteProspectModal(false);
          message.success("Prospects Deleted Successfully");
          setIsRerender(!isRerender);
          setSelectedTableItem([]);
        }
      });
    }
  }, [isDeleteProspectsRender]);

  // handle search
  useEffect(() => {
    if (filter.search) {
      setFilteredProspects(
        prospects.filter((item) => {
          if (
            item.prospectName
              .toLowerCase()
              .includes(filter.search.toLowerCase())
          )
            return item;
          if (item.email.toLowerCase().includes(filter.search.toLowerCase()))
            return item;
          if (item.phone.includes(filter.search)) return item;
          if (item.status.toLowerCase().includes(filter.search.toLowerCase()))
            return item;
          if (
            item.created_at.includes(filter.search)
          )
            return item;
        })
      );
    } else {
      setFilteredProspects(prospects);
    }
  }, [filter.search, prospects]);

  // Modal Function
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const onCloseEditModalTask = () => {
    setOpenEditModalTask(false);
  };
  const convertToTenant = (id) => {
    setProspectId(id);
    onOpenModal();
  };
  const onOpenAccountModal = () => {
    setOpenAccountModal(true);
  };
  const onCloseModalWorkOrder = () => {
    setOpenAccountModal(false);
  };

  const handleIconClick = (result) => {
    const filterData = prospects.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const CheckCredit = (id) => {
    fetch(`${config.baseUrl}/api/landlord/transunion/createScreeningRequest`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        prospectId: id,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.apiCallStatus === "success") {
          message.success("Invitation sent successfully");
        } else {
          message.error(res.error);
        }
      })
      .catch((err) => console.log(err, "error"));
  };

  const columns = [
    {
      title: "Prospect Name",
      dataIndex: "prospectName",
      render: (text, prospectName) => (
        <>
          <Link
            to={`/prospect-details?id=${prospectName.key}`}
            className="d-flex align-items-center gap-2"
          >
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                overflow: "hidden",
                border: `0.5px solid #c9c9c9`,
                position: "relative",
              }}
            >
              {prospectName.img ? (
                <img
                  src={prospectName.img}
                  alt="task"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <Component.FirstCharacterIcon
                  name={prospectName.prospectName}
                />
              )}
            </div>
            <div className="">{text}</div>
          </Link>
        </>
      ),
    },
    {
      title: "Phone No",
      dataIndex: "phone",
      render: (text, phone) => (
        <>
          <span className="phone">{formatPhoneNumber(text)}</span>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: "Date Created",
      dataIndex: "created_at",
      render: (text, created_at) => (
        <>
          <span className="all-prospect-date">{text}</span>
        </>
      ),
    },
    {
      title: <>Status</>,
      dataIndex: "status",
      render: (text) => (
        <>
          <div className="prospect-active-bar">
            <img src={activeDot} alt="" /> <span>{text}</span>
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <img src={settingIcon} alt="" />
            <RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDeleteRecord: () => {
                  onOpenDeleteModal();
                  setDeleteId(setting.key);
                },
                handleViewReport: () => {},
                handleSendCreditCheck: () => {},
                handleConvertToTenant: () => {
                  setNewLeaseId(`/convert-to-tenant/${setting.key}`);
                  setIsInfoModal(true);
                },
                handleView: () => {
                  navigate(`/prospect-details?id=${setting.key}`);
                },
                handleEdit: () => {
                  navigate(`/edit-prospect-details/${setting.key}`);
                },
              }}
              fields={{
                view: true,
                edit: true,
                delete: true,
                viewReport: true,
                sendCreditCheck: true,
                convertToTenant: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedTableItem.length);
      setSelectedTableItem([...selectedRowKeys]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  return (
    <>
      {openAccountModal === true ? (
        <ReportViewModal
          onOpen={onOpenModal}
          onClose={onCloseModalWorkOrder}
          email={email}
          // id={id}
        />
      ) : (
        ""
      )}

      <DeleteModal
        isOpen={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        message={"Are you sure you want to delete this prospect?"}
        deleteCall={() => setIsDeleteProspectRender(!isDeleteProspectRender)}
        isLoading={isLoading}
      />

      <DeleteModal
        isOpen={isDeleteProspectModal}
        onClose={() => setIsDeleteProspectModal(false)}
        message={"Are you sure want to delete selected propects?"}
        deleteCall={() => setIsDeleteProspectsRender(!isDeleteProspectsRender)}
        isLoading={isLoading}
      />

      <InfoModal
        isOpen={isInfoModal}
        onClose={() => setIsInfoModal(false)}
        message={"To convert prospect to tenant you need to create lease"}
        btnName={"Create Lease"}
        infoCall={() => navigate(newLeaseId)}
        isLoading={isLoading}
      />

      {openModal && (
        <ConvertTenantModal
          id={prospectId}
          onClose={onCloseModal}
          modalTitle="Create Lease"
        />
      )}
      {openEditModalTask && (
        <AddProspectDetailsEditModal
          setUpdate={setUpdate}
          id={taskId}
          onClose={onCloseEditModalTask}
        />
      )}

      <div className="container-fluid bg-white p-3">
        <Component.FilterRow
          filter={filter}
          setFilter={setFilter}
          btnTitle={"Add New Prospect"}
          btnHandler={() => navigate("/add-prospect-details")}
          handleSubmit={() => setIsRerender(!isRerender)}
          filterList={{
            date: true,
            range: true,
            property: true,
          }}
        />
        <div className="row mt-5">
          <div className="col-md-12">
            {selectedTableItem.length >= 1 && (
              <div className="table-delete-icon mt-3">
                <button
                  onClick={() => setIsDeleteProspectModal(true)}
                  className="table-delete-btn next-btn-main"
                >
                  <img src={trashIconWhite} />
                  Delete
                </button>
              </div>
            )}
            <Component.CustomTable
              loading={isTableLoading}
              pagination={true}
              columns={columns}
              dataSource={filteredProspects}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
            />
          </div>
        </div>
        <div className="not-found-container text-center d-none">
          <img src={NotFound} alt="" />
          <p>
            <strong>No prospects found</strong>
          </p>
          <p>
            No prospects were found; the folder is empty. <br /> Please try
            again.
          </p>
          <button className="not-found-add-task-btn primary-orange-text">
            <span className="plus">
              <svg
                width={21}
                height={21}
                fill="#EF6B3E"
                stroke="#EF6B3E"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 5v14" />
                <path d="M5 12h14" />
              </svg>
            </span>
            Add Tasks
          </button>
        </div>
      </div>
    </>
  );
};

export default AllProspect;
