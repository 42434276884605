import React, { useState } from "react";
import { Link } from "react-router-dom";
import { InputField, PasswordField } from "Components/GeneralComponents";
import facebookIcon from "assets/fb login.png";
import googleIcon from "assets/google login.png";
import { LoginSocialFacebook } from "reactjs-social-login";
import Or from "assets/login-or-image.png";
import { PasswordValidate } from "Components/TenantSetPassword";
import { checkPasswordCriteria } from "utils";

const Signup = ({
  form,
  errors,
  handleChange,
  serviceProfessionalSignUp,
  googleAuth,
  setAgree,
  resolveFacebook,
  rejectFacebook,
}) => {
  const [agreeTermsChecked, setAgreeTermsChecked] = useState(false);
  const [formattedNumber, setFormattedNumber] = useState("");
  setAgree(agreeTermsChecked);

  const handleInputChange = (event) => {
    const input = event.target.value.replace(/\D/g, "");
    const formatted = formatPhoneNumber(input);
    setFormattedNumber(formatted);
    const unformatted = input.slice(0, 10);
    handleChange("phone", unformatted);
  };

  const formatPhoneNumber = (input) => {
    let formattedNumber = "";

    if (input.length > 0) {
      formattedNumber = `(${input.slice(0, 3)}`;

      if (input.length > 3) {
        formattedNumber += `) ${input.slice(3, 6)}`;
      }

      if (input.length > 6) {
        formattedNumber += `-${input.slice(6, 10)}`;
      }
    }

    return formattedNumber;
  };

  return (
    <div className="land-lord-sign-up">
      <h1 className="mt-5">Register as a Service Pro</h1>
      <div className="col-md-12 mt-3 not-a-member">
        Not a member yet?{" "}
        <Link
          className="primary-orange-text"
          to="/service-professional-sign-in"
        >
          Login Now!
        </Link>
      </div>
      <Link className="primary-orange-text" to="/">
        <svg
          width={15}
          height={15}
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M11.438 18.75 4.688 12l6.75-6.75" />
          <path d="M5.625 12h13.688" />
        </svg>
        Back
      </Link>
      <div className="tenant-sign-up">
        <div className="row">
          <div className="col-md-6 mt-3">
            <InputField
              name={"first name"}
              type={"text"}
              label="First Name"
              placeholder={"First Name"}
              isLabel={true}
              required={true}
              handler={(e) => handleChange("fname", e.target.value)}
              errorMessage={errors.fname}
            />
          </div>
          <div className="col-md-6 mt-3">
            <InputField
              name={"last name"}
              type={"text"}
              label="Last Name"
              placeholder={"Last Name"}
              isLabel={true}
              required={true}
              handler={(e) => handleChange("lname", e.target.value)}
              errorMessage={errors.lname}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <InputField
              name={"email"}
              type={"email"}
              placeholder={"Email"}
              isLabel={true}
              label="Email"
              handler={(e) => handleChange("email", e.target.value)}
              required={true}
              errorMessage={errors.email}
            />
          </div>
        </div>
        <div className="row ">
          <div className="col-md-6 mt-3">
            <PasswordField
              handler={(e) => handleChange("password", e.target.value)}
              name={"password"}
              type={"password"}
              placeholder={"Password"}
              isLabel={true}
              label="Password"
              required={true}
              errorMessage={errors.password}
            />
          </div>
          <div className="col-md-6 mt-3">
            <PasswordField
              handler={(e) => handleChange("cpassword", e.target.value)}
              name={"cpassword"}
              type={"password"}
              placeholder={"Confirm Password"}
              isLabel={true}
              label="Confirm Password"
              required={true}
              errorMessage={errors.cpassword}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <InputField
              handler={handleInputChange}
              value={formattedNumber}
              type="tel"
              name={"phone"}
              placeholder={"Company Phone number"}
              className="form-control"
              errorMessage={errors.phone}
              isLabel={true}
              required={true}
              label="Company Phone No"
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 mt-3">
            <PasswordValidate validate={checkPasswordCriteria(form.password)} />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="sign-up-terms-checkbox d-flex align-items-center">
              <input
                onChange={(e) => {
                  setAgreeTermsChecked(e.target.checked);
                }}
                type="checkbox"
                name=""
                id=""
                checked={agreeTermsChecked}
                className={agreeTermsChecked ? "checked" : ""}
              />{" "}
              <span>
                I agree to all the{" "}
                <span className="primary-orange-text">Terms</span> and{" "}
                <span className="primary-orange-text">Privacy policy </span>
              </span>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12">
            <div className="sign-up-buttons d-flex justify-content-center">
              <button
                onClick={() => serviceProfessionalSignUp()}
                className="create-accoutn-btn w-100"
              >
                Create account
              </button>
            </div>
          </div>
        </div>
        <div className="remember-me-checkBox d-flex justify-content-center mt-4">
          <p className="text-end">
            <img src={Or} alt="" style={{ width: "100%" }} />
          </p>
        </div>
        <div className="row mb-5 ">
          <div className="col-md-12">
            <div className="sign-up-buttons d-flex gap-4 justify-content-center">
              <button onClick={googleAuth} className="google-signup-btn">
                <img src={googleIcon} alt="" style={{ width: "140px" }} />
              </button>
              <LoginSocialFacebook
                appId="1129610314899200"
                autoLoad={false}
                onResolve={(response) => resolveFacebook(response)}
                onReject={(error) => rejectFacebook(error)}
              >
                <button className="facebook-signup-btn">
                  <img src={facebookIcon} alt="" style={{ width: "140px" }} />
                </button>
              </LoginSocialFacebook>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
