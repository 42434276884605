import React from "react";
import SideDrawer from "Components/SideDrawer/SideDrawer";
import TitleTopBar from "Helpers/TitleTopBar";
import TenantPassportProfile from "Components/TenantPassportProfile/TenantPassportProfile";
import ResponsiveSideBar from "Components/ResponsiveSideBar/ResponsiveSideBar";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
const TenantPassportProfilePage = () => {
  const location = useLocation();
  const drawer = useSelector((state) => {
    return state.sideDrawer.drawerState;
  });
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-2 p-sm-0 d-md-none d-lg-none d-xl-block d-none position-relative drawer-bg-primary">
            <SideDrawer />
          </div>
          {location.pathname === "/tenant-passport-edit-profile" ? (
            <div
              className={
                drawer === true
                  ? "res-drawer-bg-open h-100"
                  : "res-drawer-bg-close"
              }
            >
              <div
                className={
                  drawer === true
                    ? "drawer-bg-primary responsive-drawer-container-open"
                    : "drawer-bg-primary responsive-drawer-container "
                }
              >
                <ResponsiveSideBar />
              </div>
            </div>
          ) : (
            <div
              className={
                drawer === true ? "res-drawer-bg-open" : "res-drawer-bg-close"
              }
            >
              <div
                className={
                  drawer === true
                    ? "drawer-bg-primary responsive-drawer-container-open"
                    : "drawer-bg-primary responsive-drawer-container "
                }
              >
                <ResponsiveSideBar />
              </div>
            </div>
          )}
          <div className="col-xl-10 col-lg-12 col-md-12 mt-3">
            <div className="right-component bg-white h-100">
              <div className="container-fluid bg-default">
                <TitleTopBar
                  topBarTitleDetail={"Tenant Passport"}
                  back="tenant-passport"
                />
              </div>
              <TenantPassportProfile />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TenantPassportProfilePage;
