import React, { useEffect, useState } from "react";
import * as Components from "Components/GeneralComponents";
import { getProperties } from "network/landlord/property";
import { message } from "antd";
import TempModal from "Modals/TempModal/TempModal";
import { addnewfileform } from "utils/FormDataStructure";

const NewFile = () => {
  const [form, setForm] = useState({ file_name: "", relatedToProperty: "" });
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [properties, setProperties] = useState([]);
  const [Images, setImages] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getProperties().then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        const data = res.message.map((item) => {
          return {
            id: item.id,
            heading1: item.property_type,
            heading2: item.title,
            heading3: `${item.address.street}, ${item.address.city}, ${item.address.state}, ${item.address.zipcode}`,
          };
        });
        setProperties(data);
      } else {
        message.error(res.error.message);
      }
    });
  }, []);

  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const AddFile = () => {
    const newErrors = {};
    const fielmap = {
      file_name: "File Name",
      relatedToProperty: "Property",
    };

    for (const key in form) {
      if (form[key] === "") {
        newErrors[key] = `${fielmap[key]} is required`;
      }
    }

    if (Images.length === 0) {
      newErrors["files"] = `File is required`;
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0 && Images.length !== 0) {
      addnewfileform(form, Images);
      setIsLoading(true);
      setTimeout(() => {
        onOpenModal();
      }, 2000);
    }
  };

  const handleChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  return (
    <>
      {openModal === true && (
        <TempModal
          onClose={onCloseModal}
          title="File added successfully"
          success="Go To Files"
          route="all-files"
        />
      )}
      <div className="container bg-white p-3 ">
        <div className="row">
          <div className="col-md-12">
            <Components.InputField
              required={true}
              name={"file_name"}
              placeholder={"File Name"}
              isLabel={true}
              label={"File Name"}
              value={form.file_name}
              handler={(e) => handleChange("file_name", e.target.value)}
              errorMessage={errors.file_name}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="task-search-property-container position-relative">
              <Components.SelectPropertyField
                label={"Property"}
                placeholder={"Select Property"}
                PropertyData={properties}
                isRequired={true}
                onPropertySelect={(e) => {
                  handleChange("relatedToProperty", e?.id);
                }}
                errorMessage={errors.relatedToProperty}
              />
            </div>
          </div>
        </div>
        <div className="row mt-3 text-center">
          <div className="col-md-12">
            <Components.MediaField
              required={true}
              label={"Upload Media"}
              setImages={setImages}
              Images={Images}
              errorMessage={errors.files}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between gap-1 mt-5">
          <div style={{ width: "100%" }}>
            <Components.SubmitButton handler={AddFile} isLoading={isLoading}>
              Add File
            </Components.SubmitButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewFile;
