import React from "react";

const Stepper = ({ currentStep }) => {
  return (
    <div className="stepper-container step-container-responsive">
      <div
        className={
          currentStep === 1 ||
          currentStep === 2 ||
          currentStep === 3 ||
          currentStep === 4 ||
          currentStep === 5
            ? "step-1 personal-details stepper-active"
            : "step-1 personal-details stepper-inactive"
        }
      >
        1 <div className="form-step-text">Personal Details</div>
      </div>
      <div
        className={
          currentStep === 2 ||
          currentStep === 3 ||
          currentStep === 4 ||
          currentStep === 5
            ? "step-2 services stepper-active"
            : "step-2 services stepper-inactive"
        }
      >
        2<div className="form-step-text">Services</div>
      </div>
      <div
        className={
          currentStep === 3 || currentStep === 4 || currentStep === 5
            ? "step-3 insurance stepper-active"
            : "step-3 insurance stepper-inactive"
        }
      >
        3<div className="form-step-text">Insurance</div>
      </div>
      <div
        className={
          currentStep === 4 || currentStep === 5
            ? "step-4 properties stepper-active"
            : "step-4 Properties stepper-inactive"
        }
      >
        4<div className="form-step-text">Properties</div>
      </div>
      <div
        className={
          currentStep === 5
            ? "step-5 federal-taxes stepper-active"
            : "step-5 federal-taxes stepper-inactive"
        }
      >
        5<div className="form-step-text">Federal Taxes</div>
      </div>
      <div className="lines-vendor">
        <div
          className={`line ${
            currentStep === 2 ||
            currentStep === 3 ||
            currentStep === 4 ||
            currentStep === 5
              ? "active"
              : ""
          }`}
        ></div>
        <div
          className={`line ${
            currentStep === 3 || currentStep === 4 || currentStep === 5
              ? "active"
              : ""
          }`}
        ></div>
        <div
          className={`line ${
            currentStep === 4 || currentStep === 5 ? "active" : ""
          }`}
        ></div>
        <div className={`line ${currentStep === 5 ? "active" : ""}`}></div>
      </div>
    </div>
  );
};

export default Stepper;
