import React, { useState } from "react";
import FilterIcon from "assets/filter.png";
import * as Component from "./index";

const Filter = ({ hanldleFilter, filterList, filter, category, handleSubmit }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSubmitFilter = () => {
    setIsOpen(false);
    handleSubmit();
  };

  return (
    <div className="position-relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-100 h-100 d-flex align-items-center"
        style={{padding: "1px 10px",
          
          border: "1px solid var(--gray-300, #d0d5dd)",
          borderRadius: "5px",
          background: "var(--White, #fff)",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
         }}
      >
        {" "}
        <img src={FilterIcon} alt="" /> Filter
      </button>

      <Component.FilterPopup
        isOpen={isOpen}
        onclose={() => setIsOpen(false)}
        filterVal={filter}
        category={category}
        hanldleFilter={hanldleFilter}
        handleSubmit={handleSubmitFilter}
        filterList={filterList}
      />
    </div>
  );
};

export default Filter;
