import React, { useState } from "react";
import TempModal from "Modals/TempModal/TempModal";
import * as InnerComponent from "./index";
import * as Component from 'Components/GeneralComponents'

const TenantSetPassword = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleRedirect = () => {
    localStorage.removeItem("role");
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    window.location.replace("/tenant-sign-in");
  };

  return (
    <>
      {openModal && (
        <TempModal
          onClose={() => setOpenModal(false)}
          heading={"Password Reset"}
          title={"Password reset sucessfully"}
          success="Login"
          handler={handleRedirect}
        />
      )}
      
      <Component.AuthLayout>
        <InnerComponent.ResetPasswordForm
          openSuccessModal={() => setOpenModal(true)}
        />
      </Component.AuthLayout>
      
    </>
  );
};

export default TenantSetPassword;
