import React from "react";

const CostumerQueriesInner = () => {
  return (
    <>
      <div className="p-4">
        <div className="notes-info-title">Query info</div>
        <div className="query-info-lists mt-5">
          <div className="row">
            <div className="col-md-2">
              <p>
                <span className="query-info-list-span me-3">Landlord Name:</span>
              </p>
            </div>
            <div className="col-md-8 query-title">
              <p>Olivia Rhye</p>
            </div>

            <div className="col-md-2"></div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <p>
                <span className="query-info-list-span me-3">Phone No:</span>
              </p>
            </div>
            <div className="col-md-8 query-phone">
              <p>
                <a href="tel:+1 (555) 543-2109">+1 (555) 543-2109</a>
              </p>
            </div>

            <div className="col-md-2"></div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <p>
                <span className="query-info-list-span me-3">Email:</span>
              </p>
            </div>
            <div className="col-md-8 query-email">
              <p>
                <a href="mailto:olivia@untitledui.com">olivia@untitledui.com</a>
              </p>
            </div>

            <div className="col-md-2"></div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <p>
                <span className="query-info-list-span me-3">
                  Query Discription:
                </span>
              </p>
            </div>
            <div className="col-md-8 query-description">
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English.
              </p>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="mt-5">
            <button className="btn btn-danger m-2 ps-4 pe-4 customer-accept-btn">
              Accept
            </button>
            <button className="btn btn-danger m-2 ps-4 pe-4 customer-decline-btn">
              Decline
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CostumerQueriesInner;
