import React, { useEffect, useState } from "react";
import { ConfigProvider, Tabs } from "antd";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import * as InnerComponent from "./index";
import { getProperty } from "network/tenant/Property";

const TenantPropertyDetailsView = () => {
  const { id } = UseUrlParamsHook();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [overviewTabData, setOverviewTabData] = useState({});
  const [landlordInfoTabData, setLandlordInfoTabData] = useState({});
  const [unitsTabData, setUnitsTabData] = useState({});
  const [documentsData, setDocumentsData] = useState([]);
  const { TabPane } = Tabs;
  const [isLoading, setIsLoading] = useState(false);

  const handleIconClickCustom = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    setIsLoading(true);
    getProperty(id).then((res) => {
      if (res.apiCallStatus === "success") {
        setOverviewTabData(res.message.data.propertyOverview);
        setLandlordInfoTabData(res.message.data.LandlordInfo);
        setUnitsTabData(res.message.data.units);
        setDocumentsData(res.message.data.signnow_documents);
      }
      // after getting data set loading to false
      setIsLoading(false);
    });
  }, [id]);

  return (
    <>
      <div className="container-fluid bg-white pt-5">
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                inkBarColor: "#EF6B3E",
                itemColor: "#667085",
                itemSelectedColor: "#EF6B3E",
                itemHoverColor: "#EF6B3E",
                titleFontSize: 15,
                horizontalItemGutter: window.innerWidth <= 768 ? 10 : 60,
                fontFamily: "Montserrat",
              },
            },
          }}
        >
          <Tabs
            centered={window.innerWidth <= 450 ? false : true}
            defaultActiveKey="1"
            style={{ fontWeight: 500 }}
            className="mt-2 "
          >
            <TabPane tab="Overview" key="1">
              <InnerComponent.OverviewTab
                data={overviewTabData}
                isLoading={isLoading}
              />
            </TabPane>
            <TabPane tab="Landlord Info" key="2">
              <InnerComponent.LandlordInfoTab
                id={id}
                data={landlordInfoTabData}
                isLoading={isLoading}
              />
            </TabPane>
            <TabPane tab="Units" key="3">
              <InnerComponent.UnitsTab
                id={id}
                data={unitsTabData}
                isLoading={isLoading}
              />
            </TabPane>
            <TabPane tab="Tasks" key="4">
              <InnerComponent.TasksTab id={id} units={unitsTabData} />
            </TabPane>
            <TabPane tab="Documents" key="5">
              <InnerComponent.DocumentsTab data={documentsData} owner={landlordInfoTabData?.name} isLoading={isLoading} />
            </TabPane>
            <TabPane tab="Files" key="6">
              <InnerComponent.FilesTab id={id} category={""} />
            </TabPane>
          </Tabs>
        </ConfigProvider>
      </div>
    </>
  );
};

export default TenantPropertyDetailsView;
