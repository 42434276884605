import React, { useState } from "react";
import { Tabs } from "antd";
import homeIconTwo from "assets/home-03 (1).png";
import bedroomIcon from "assets/Vector (5).png";
import bathroomIcon from "assets/Vector (6).png";
import sizeIcon from "assets/layout-alt-04 (1).png";
import UnitDetailsViewer from "Components/GeneralComponents/UnitDetailsViewer";
import * as InnerComponent from "./index";
import DescriptionTab from "Components/TenantPropertyDetailsView/DescriptionTab";

const OverviewTab = () => {
  const { TabPane } = Tabs;
  const [showEditUnitInfo, setShowEditUnitInfo] = useState(false);
  const [UnitData, setUnitData] = useState([
    {
      id: 1,
      name: "Unit 1",
      property_title: "Property 1",
      images: [
        "https://www.w3schools.com/w3images/lights.jpg",
        "https://www.w3schools.com/w3images/nature.jpg",
        "https://www.w3schools.com/w3images/mountains.jpg",
      ],
      description: "This is a description",
      rent_amount: 2000,
      bedroom: 2,
      bathroom: 2,
      area: 1200,
    },
  ]);
  const [amenities, setAmenities] = useState([
    "Parking",
    "Gym",
    "Swimming Pool",
    "Security",
    "Elevator",
    "Balcony",
    "Fireplace",
    "Storage",
    "Pet Friendly",
    "Wheelchair Access",
    "Laundry",
    "Dishwasher",
    "Furnished",
    "Air Conditioning",
    "Heating",
    "Internet",
  ]);

  const descriptionData = {
    title: "295 Retton Cir Williamson",
    address: "1100 Welker Ln Jersey Shore, Pennsylvania(PA), 17740",
    description: "This is a description",
    rent_amount: 2000,
    bedroom: 2,
    bathroom: 2,
    area: 1200,
  };

  return (
    <div>
      <div className={"row"}>
        <UnitDetailsViewer
          images={[
            "https://plus.unsplash.com/premium_photo-1680553492268-516537c44d91?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            "https://plus.unsplash.com/premium_photo-1664474619075-644dd191935f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            "https://plus.unsplash.com/premium_photo-1664474619075-644dd191935f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            "https://plus.unsplash.com/premium_photo-1680553492268-516537c44d91?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            "https://plus.unsplash.com/premium_photo-1680553492268-516537c44d91?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            "https://plus.unsplash.com/premium_photo-1664474619075-644dd191935f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            // "https://plus.unsplash.com/premium_photo-1680553492268-516537c44d91?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            // "https://plus.unsplash.com/premium_photo-1664474619075-644dd191935f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            // "https://plus.unsplash.com/premium_photo-1680553492268-516537c44d91?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            // "https://plus.unsplash.com/premium_photo-1664474619075-644dd191935f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            // "https://plus.unsplash.com/premium_photo-1680553492268-516537c44d91?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            // "https://plus.unsplash.com/premium_photo-1664474619075-644dd191935f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            // "https://plus.unsplash.com/premium_photo-1680553492268-516537c44d91?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            // "https://plus.unsplash.com/premium_photo-1664474619075-644dd191935f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            // "https://plus.unsplash.com/premium_photo-1664474619075-644dd191935f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            // "https://plus.unsplash.com/premium_photo-1680553492268-516537c44d91?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            // "https://plus.unsplash.com/premium_photo-1680553492268-516537c44d91?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            // "https://plus.unsplash.com/premium_photo-1664474619075-644dd191935f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            // "https://plus.unsplash.com/premium_photo-1680553492268-516537c44d91?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            // "https://plus.unsplash.com/premium_photo-1664474619075-644dd191935f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            // "https://plus.unsplash.com/premium_photo-1680553492268-516537c44d91?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            // "https://plus.unsplash.com/premium_photo-1664474619075-644dd191935f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            // "https://plus.unsplash.com/premium_photo-1680553492268-516537c44d91?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            // "https://plus.unsplash.com/premium_photo-1664474619075-644dd191935f?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          ]}
          address="1100 Welker Ln Jersey Shore, Pennsylvania(PA), 17740"
          title="295 Retton Cir Williamson"
        />

        <Tabs defaultActiveKey="7" className="property_details_view_sub_tabs">
          <TabPane tab="Description" key="7">
            <DescriptionTab data={descriptionData}/>
          </TabPane>
          <TabPane tab="Amenities" key="8">
            <InnerComponent.AmenitiesTab amenities={amenities} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default OverviewTab;
