import React, { useState } from "react";
import ChevronLeft from "assets/chevron-left.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import { resetUserPassword } from "network/landlord/Auth";
import {
  AuthLayout,
  PasswordField,
  PasswordValidate,
  SubmitButton,
  SuccessModal,
} from "Components/GeneralComponents";
import { checkPasswordCriteria } from "utils";

const UserSetPassword = () => {
  // States start
  const navigate = useNavigate();
  const [form, setForm] = useState({ password: "", confirmPassword: "" });
  const [openModal, setOpenModal] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  // create a function named gotoLogin which will redirect the user to the login page but before that clear all the local storage
  const gotoLogin = () => {
    localStorage.clear();
    navigate("/land-lord-sign-in");
  }

  const ResetPassword = () => {
    const passwordCriteria = checkPasswordCriteria(form.password);
    if (form.password === "" || form.confirmPassword === "") {
      message.error("Please Fill mandatory fields to continue");
    } else if (form.confirmPassword !== form.password) {
      message.error("Password does not match");
    } else if (
      !passwordCriteria.hasMinimumLength ||
      !passwordCriteria.hasNumber ||
      !passwordCriteria.hasUpperCase ||
      !passwordCriteria.hasUpperCase ||
      !passwordCriteria.hasSpecialCharacter
    ) {
      message.error("Please choose another password");
    } else {
      const payload = {
        token: token,
        newPassword: form.password,
        confirmPassword: form.confirmPassword,
      };

      setIsLoader(true);
      resetUserPassword(payload).then((res) => {
        setIsLoader(false);
        if (res.apiCallStatus === "success") {
          setOpenModal(true);
        } else {
          message.error(res.error.message);
        }
      });
    }
  };

  return (
    <>
      {openModal && (
        <SuccessModal
          title={"Password Reset Successfully"}
          message={
            "Your password has been reset successfully. Please login with your new password"
          }
          handler={gotoLogin}
          success={"Go to Login"}
          route={"land-lord-sign-in"}
          onClose={() => setOpenModal(false)}
        />
      )}
      <AuthLayout>
        <div className="container">
          <div className="reset-password-heading my-5 pt-5">
            <h1>Set Password</h1>
            <p>Create a new password for your account</p>
          </div>
          <div className="">
            <div className="row mt-4">
              <div className="col-md-12">
                <PasswordField
                  name={"password"}
                  required={true}
                  label="Create new password"
                  placeholder={"Password"}
                  value={form.password}
                  handler={handleChange}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <PasswordField
                  name={"confirmPassword"}
                  required={true}
                  label="Confirm Password"
                  placeholder={"Confirm Password"}
                  value={form.confirmPassword}
                  handler={handleChange}
                />
              </div>
            </div>
            <div className="password-validation-list mt-4">
              <PasswordValidate
                validate={checkPasswordCriteria(form.password)}
              />
            </div>
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="reset-password-buttons d-flex gap-4 ">
                  <SubmitButton handler={ResetPassword} isLoading={isLoader}>
                    Reset Password
                  </SubmitButton>
                </div>
              </div>
            </div>
            <div className="already-account-text text-center mt-4" style={{cursor: "pointer"}} onClick={gotoLogin}>
              <span>
                <img src={ChevronLeft} alt="" />
                {/* <Link to="/land-lord-sign-in" className="custom-link-text fw-medium"> */}
                  Back to log in
                {/* </Link> */}
              </span>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default UserSetPassword;
