import axios from 'axios';
import config from "Helpers/config.js"
import { message } from "antd";

const downloadFile = async (fileUrl, desiredFileName) => {
    const splitFileUrl = fileUrl.split(
      "https://digital-tenant-bucket.s3.us-east-1.amazonaws.com/"
    )[1];
    
    fetch(`${config["baseUrl"]}/api/file/download/${splitFileUrl}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      responseType: "blob",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const fileUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = fileUrl;
        a.download = desiredFileName; // Ensure desiredFileName is used correctly
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(fileUrl);
        message.success("File download successful");
      })
      .catch((error) => {
        message.error("Error downloading file");
      });
};

const addFile = async (data) => {
    try{
        const response = await axios.post(`${config.baseUrl}/api/files`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        });
        return response.data;
    } catch (error){
        return error.response.data;
    }
}

const getFiles = async (params) => {
    try{
        const response = await axios.get(`${config.baseUrl}/api/files`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            params: params
        });
        return response.data;
    } catch (error){
        return error.response.data;
    }
};

const deleteFile = async (id) => {
    try{
        const response = await axios.delete(`${config.baseUrl}/api/files/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
        return response.data;
    } catch (error){
        return error.response.data;
    }
};

const deleteMultipleFiles = async (ids) => {
    try{
        const response = await axios.delete(`${config.baseUrl}/api/files`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            data: { ids: ids }
        });
        return response.data;
    } catch (error){
        return error.response.data;
    }
};

export { 
    downloadFile, 
    addFile,
    getFiles,
    deleteFile,
    deleteMultipleFiles
};