import React from 'react'

const ResidentialIcon = ({handleIconClickImages,selectedIcon }) => {
  return (
   <>
    <svg
              className=""
              onClick={() => handleIconClickImages("residential")}
              width={27}
              height={27}
              stroke={selectedIcon === "residential" ? "#EF6B3E" : "#D0D5DD"}
              fill={"none"}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
              <path d="M9 22V12h6v10" />
            </svg>
   </>   
  )
}

export default ResidentialIcon
