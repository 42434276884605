import { message } from 'antd';
import config from 'Helpers/config';

const BulkDelete = (url, id, handleRerender) => {
   const bulkDelete = async () => {
        let tenantIds = id?.tenantIds;
        let prospectIds = id?.prospectIds || []; // Assign an empty array as a default value if prospectIds is null or undefined
        const requestBody = JSON.stringify({ tenantIds: tenantIds });
        const prospectBody = JSON.stringify({ prospectIds: prospectIds });
        
        const body = url === "prospects" ? prospectBody : requestBody;


        await fetch(`${config['baseUrl']}/api/${url}`, {
            method: "DELETE",
            headers: {
                "content-type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
          body: body
        }).then(res => {
            return res.json()
        }).then((res) => {
            if (res.apiCallStatus === "success") {
                message.success(res?.message?.message);
                handleRerender();
                // fetchProperty();
                // fetchProspect();
            } else {
                const docs = res.relatedDocs
                const docsType = Object.entries(docs).map(e => e[1].length + " " + e[0]);
                message.error(`You cannot delete this you have ${docsType} connected to this property`)
            }
        })
    }
    return {
        bulkDelete
    }
}

export default BulkDelete;

