import { InputField, SubmitButton } from "Components/GeneralComponents";
import React from "react";

const UnitInformation = ({
  form,
  handleChange,
  isSubmitForm = false,
  errors,
  handleNext,
  handlePrev,
  isLoader,
}) => {
  return (
    <div className="container-fluid mt-5 mb-4">
      <div className="row mt-4">
        <div className="col-md-12">
          <InputField
            name={"propertyUnit"}
            label={"How many units or add unit amount"}
            placeholder={"Number of Units"}
            required={true}
            type={"number"}
            value={form.propertyUnit}
            handler={(e) => handleChange("propertyUnit", e.target.value)}
            errorMessage={errors?.propertyUnit}
          />
        </div>
      </div>
      <div className="stepper-btn d-flex justify-content-between mt-5 gap-1">
        <div style={{ width: "50%" }}>
          <SubmitButton type="contained" handler={handlePrev}>
            Back
          </SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <SubmitButton isLoading={isLoader} handler={handleNext}>
            {isSubmitForm ? "Submit" : "Next"}
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default UnitInformation;
