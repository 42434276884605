import { useEffect, useState } from "react";
import settingIconOrange from "assets/dots-vertical.png";
import { Tabs, ConfigProvider, message } from "antd";
import "leaflet/dist/leaflet.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
// import required modules
import UnitDeleteModal from "Modals/UnitDeleteModal/UnitDeleteModal";
import PropertyDetailsViewAddNewTaskModal from "Modals/PropertyDetailsViewAddNewTaskModal/PropertyDetailsViewAddNewTaskModal";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import EditTaskModal from "../EditTaskModal/EditTaskModal";
import AddPropertyEditModal from "Modals/AddPropertyEditModal/AddPropertyEditModal";
import AddPropertyConnectAccount from "Modals/AddPropertyConnectAccount/AddPropertyConnectAccount";
import { FilesTab, NotesTab } from "Components/TenantDetailsView/index.js";
import { OverviewTab } from "./OverviewTab";
import UnitTab from "./UnitTab";
import TasksTab from "./TasksTab.jsx";
import ConnectAccountTab from "./ConnectAccountTab.jsx";
import ReportsTab from "./ReportsTab.jsx";
import {
  DeleteModal,
  FormModalLayout,
  RecordMenuPopup,
} from "Components/GeneralComponents";
import { useNavigate } from "react-router-dom";
import { deleteProperty, getProperty } from "network/landlord/property";
import TenantsTab from "./TenantsTab";

const PropertyDetailsView = () => {
  // States start
  const navigate = useNavigate();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [component, setcomponent] = useState("");
  const [taskId, setTaskId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [openModalTask, setOpenModalTask] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModalTask, setOpenEditModalTask] = useState(false);
  const [EditProperty, setEditProperty] = useState(false);
  const [update, setUpdate] = useState(false);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteRender, setIsDeleteRender] = useState(false);
  const [propertyData, setPropertyData] = useState({});
  const [unitsData, setUnitsData] = useState([]);
  const { id } = UseUrlParamsHook();
  const onCloseEditModalTask = () => {
    setOpenEditModalTask(false);
  };
  const onOpenEditModalProperty = () => {
    setEditProperty(true);
  };
  const onCloseEditModalProperty = () => {
    setEditProperty(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getProperty(id).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setPropertyData(res.message);
        setUnitsData(res.message.units);
      } else {
        console.log("Error in fetching property data");
      }
    });
  }, [id, update]);

  // States end

  const onCloseModalWorkOrder = () => {
    setOpenAccountModal(false);
  };
  // Modal Function

  const onCloseModalTask = () => {
    setOpenModalTask(false);
  };

  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleIconClickCustom = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const { TabPane } = Tabs;

  useEffect(() => {
    if (deleteId) {
      setIsLoading(true);
      deleteProperty(deleteId).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          onCloseDeleteModal();
          message.success("Property Deleted Successfully");
          setDeleteId("");
        } else {
          message.error(res.error.message);
        }
      });
    }
  }, [isDeleteRender]);

  return (
    <>
      {openAccountModal === true ? (
        <AddPropertyConnectAccount
          onOpen={onOpenModal}
          onClose={onCloseModalWorkOrder}
          id={id}
          title={propertyData?.title}
        />
      ) : (
        ""
      )}

      <FormModalLayout
        title={"Edit Property"}
        isOpen={EditProperty}
        onClose={onCloseEditModalProperty}
      >
        <AddPropertyEditModal
          id={id}
          onClose={() => {
            onCloseEditModalProperty();
            setUpdate(true);
          }}
        />
      </FormModalLayout>

      {openModalTask === true ? (
        <PropertyDetailsViewAddNewTaskModal
          id={id}
          onOpen={onOpenModal}
          onClose={onCloseModalTask}
        />
      ) : (
        ""
      )}

      {openEditModalTask && (
        <EditTaskModal id={taskId} onClose={onCloseEditModalTask} />
      )}

      {openModal === true ? (
        <UnitDeleteModal delId={deleteId} onClose={onCloseModal} />
      ) : (
        ""
      )}

      <DeleteModal
        isOpen={openDeleteModal}
        onClose={onCloseDeleteModal}
        message={
          "Once the property is deleted, it will move to the archive, and after 60 days, it will be deleted from the archive."
        }
        deleteCall={() => setIsDeleteRender(!isDeleteRender)}
        isLoading={isLoading}
      />

      <div className="container-fluid bg-white p-3">
        <div className="row">
          <div className="col-md-12 text-end">
            <span className="position-relative">
              <img
                onClick={handleIconClickCustom}
                src={settingIconOrange}
                alt="Action Buttons"
                className="cursor"
              />
              <RecordMenuPopup
                isOpen={isDropdownOpen}
                onClose={() => setDropdownOpen(false)}
                handler={{
                  handleEdit: () => {
                    onOpenEditModalProperty();
                  },
                  handleDeleteRecord: () => {
                    onOpenDeleteModal();
                    setDeleteId(id);
                    setcomponent("property");
                  },
                }}
                fields={{
                  edit: true,
                  delete: true,
                }}
              />
            </span>
          </div>
        </div>
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                inkBarColor: "#EF6B3E",
                itemColor: "#667085",
                itemSelectedColor: "#EF6B3E",
                itemHoverColor: "#EF6B3E",
                titleFontSize: 15,
                horizontalItemGutter: window.innerWidth <= 768 ? 10 : 60,
                fontFamily: "Montserrat",
              },
            },
          }}
        >
          <Tabs
            centered={window.innerWidth <= 450 ? false : true}
            defaultActiveKey="1"
            style={{ fontWeight: 500 }}
            className="property_details_view_tabs"
          >
            <TabPane tab="Overview" key="1">
              <OverviewTab id={id} propertyData={propertyData} isLoading={isLoading} />
            </TabPane>
            <TabPane tab="Units" key="4">
              <UnitTab id={id} unitsData={unitsData} isLoading={isLoading} setUpdate={setUpdate} />
            </TabPane>
            <TabPane tab="Tenants" key="5">
              <TenantsTab id={id} />
            </TabPane>
            <TabPane tab="Tasks" key="6">
              <TasksTab id={id} />
            </TabPane>
            <TabPane tab="Connect Account" key="7">
              <ConnectAccountTab id={id} />
            </TabPane>
            <TabPane tab="Reports" key="8">
              <ReportsTab id={id} />
            </TabPane>
            <TabPane tab="Files" key="9">
              <FilesTab category={"property"} id={id} />
            </TabPane>
            <TabPane tab="Notes" key="10">
              <NotesTab category={"property"} id={id} />
            </TabPane>
          </Tabs>
        </ConfigProvider>
      </div>
    </>
  );
};

export default PropertyDetailsView;
