import axios from "axios";
import config from "Helpers/config.js";

const createTask = async (data) => {
  try {
    const response = await axios.post(`${config.baseUrl}/api/tasks`, data, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getTasks = async () => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/tasks`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getTaskById = async (id) => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/tasks/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAssignees = async () => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/tasks/assignees`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteTask = async (id) => {
  try {
    const response = await axios.delete(`${config.baseUrl}/api/tasks/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteTasks = async (taskIds) => {
  try {
    const response = await axios.delete(`${config.baseUrl}/api/tasks`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      data: { taskIds },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateTask = async (id, data) => {
  try {
    const response = await axios.put(`${config.baseUrl}/api/tasks/${id}`, data, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const completeTask = async (id) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/taskupdate/${id}`,
      { status: "Completed" },
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  createTask,
  getTasks,
  getTaskById,
  getAssignees,
  deleteTask,
  deleteTasks,
  completeTask,
  updateTask,
};
