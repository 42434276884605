import React, { useState } from "react";
import { Collapse, Switch, Button, ConfigProvider } from "antd";
import plusIcon from "assets/plus.png";
import plusIconOrange from "assets/plus-orange.png";
import {
  DeleteOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  InputField,
  PhoneNumberField,
  DateField,
  SelectField,
  SubmitButton,
} from "Components/GeneralComponents";
import { formatDate } from "utils";

const { Panel } = Collapse;

const EmploymentVerification = ({
  form,
  errors,
  handleChange,
  addWorkHistory,
  removeWorkHistory,
  handleNext,
  handlePrev,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div>
      <Collapse expandIconPosition={"start"}>
        {form.map((employer, index) => (
          <Panel
            header={<b>Employer {index + 1}</b>}
            key={index}
            // extra={
            //   <DeleteOutlined
            //     onClick={(event) => {
            //       event.stopPropagation();
            //       removeWorkHistory(index);
            //     }}
            //   />
            // }
          >
            <div className="d-flex justify-content-end mb-3">
              <div
                style={{
                  fontSize: "16px",
                  color: "#ff4d4f",
                  cursor: "pointer",
                  border: "1px solid #ff4d4f",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
                onClick={() => removeWorkHistory(index)}
              >
                <DeleteOutlined />
                Delete
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <InputField
                  name={"Job Title"}
                  required={true}
                  label={"Job Title"}
                  placeholder={"Job Title"}
                  value={employer.jobTitle}
                  handler={(e) =>
                    handleChange("jobTitle", index, e.target.value)
                  }
                  errorMessage={errors.jobTitle}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <InputField
                  name={"Company Name"}
                  required={true}
                  label={"Company Name"}
                  placeholder={"Company Name"}
                  value={employer.companyName}
                  handler={(e) =>
                    handleChange("companyName", index, e.target.value)
                  }
                  errorMessage={errors.companyName}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3">
                <DateField
                  required={true}
                  label={"Start Date"}
                  placeholder={"Select Date"}
                  defaultValue={
                    employer.startDate && formatDate(employer.startDate)
                  }
                  handler={(e) => {
                    const formattedDate = new Date(e).toLocaleDateString();
                    handleChange("startDate", index, formattedDate);
                  }}
                  errorMessage={errors.startDate}
                />
              </div>
              <div className="col-md-6 mt-3">
                <DateField
                  required={true}
                  label={"End Date"}
                  placeholder={"Select Date"}
                  defaultValue={
                    employer.endDate && formatDate(employer.endDate)
                  }
                  handler={(e) => {
                    const formattedDate = new Date(e).toLocaleDateString();
                    handleChange("endDate", index, formattedDate);
                  }}
                  errorMessage={errors.endDate}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <ConfigProvider
                  theme={{
                    components: {
                      Switch: {
                        colorPrimary: "#EF6B3E",
                        colorPrimaryHover: "#EF6B3E",
                      },
                    },
                  }}
                >
                  <span className="d-flex gap-2 employer-switch-text">
                    <Switch onChange={() => {}} />
                    This is my current employer
                  </span>
                </ConfigProvider>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <InputField
                  name={"Supervisor Name"}
                  required={true}
                  label={"Supervisor Name"}
                  placeholder={"Supervisor Name"}
                  value={employer.supervisorName}
                  handler={(e) =>
                    handleChange("supervisorName", index, e.target.value)
                  }
                  errorMessage={errors.supervisorName}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mt-3">
                <InputField
                  name={"Supervisor Email"}
                  required={true}
                  label={"Supervisor Email"}
                  placeholder={"Supervisor Email"}
                  value={employer.supervisorEmail}
                  handler={(e) =>
                    handleChange("supervisorEmail", index, e.target.value)
                  }
                  errorMessage={errors.supervisorEmail}
                />
              </div>
              <div className="col-md-6 mt-3">
                <PhoneNumberField
                  name={"Supervisor Phone No"}
                  required={true}
                  label={"Supervisor Phone No"}
                  placeholder={"Supervisor Phone No"}
                  value={employer.supervisorPhone}
                  handler={(e) => handleChange("supervisorPhone", index, e)}
                  errorMessage={errors.supervisorPhone}
                />
              </div>
            </div>
          </Panel>
        ))}
      </Collapse>

      {/* <Button
        type="dashed"
        onClick={addWorkHistory}
        icon={<PlusOutlined />}
        className="mt-3"
      >
        Add Work History
      </Button> */}
      <div className="row mt-3">
        <div className="col-md-12">
          <button
            onClick={addWorkHistory}
            className="add-employment-btn-white"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={isHovered ? plusIcon : plusIconOrange}
              className="add-property-icon-white"
            />{" "}
            Add Work History
          </button>
        </div>
      </div>

      <div className="stepper-btn d-flex justify-content-between mt-5 gap-1">
        <div style={{ width: "50%" }}>
          <SubmitButton type="contained" handler={handlePrev}>
            Back
          </SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <SubmitButton handler={handleNext}>Next</SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default EmploymentVerification;
