import PropertyReport from "Components/PropertyReport/PropertyReport";
import UseGetHook from "Hooks/UseGetHook";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const PropertyReportPage = () => {
  const { FetchUser, user, FetchUserTenant, FetchUserLandlordUser } =
    UseGetHook("userinfo");
  useEffect(() => {
    FetchUser();
    FetchUserTenant();
    FetchUserLandlordUser();
  }, []);
  // const myUserRoles = user[0]?.roles?.map((e) => e.role);
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles = storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  console.log("retrievedArray ------======>", myUserRoles);

  const drawer = useSelector((state) => {
    return state.sideDrawer.drawerState;
  });
  return (
    <>
      <div className="container-fluid">
        {!["Owner/Executive", "Property Manager"].some((role) =>
          myUserRoles?.includes(role)
        ) && myUserRoles?.length !== 0 ? (
          <NoAccessPage />
        ) : (
          <PropertyReport />
        )}
      </div>
    </>
  );
};

export default PropertyReportPage;
