import { useEffect, useState } from "react";
import olivia from "assets/olivia.png";
import active_dot from "assets/_Dot.png";
import offline_dot from "assets/_red-Dot.png";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import DeleteModal from "Modals/DeleteModal/DeleteModal";
import {
  getLandlordMessages,
  getLandlordConversations,
  addOrRemoveArchive,
} from "network/landlord/chat";
import { getTenantMessages, getTenantConversations } from "network/tenant/chat";
import { Avatar, Tooltip } from "antd";
import {
  FirstCharacterIcon,
  RecordMenuPopup,
} from "Components/GeneralComponents";
import Loader from "Helpers/Loader";
import { VerticalElipsisIcon } from "assets/icons";

const ChatBody = ({ archive, text }) => {
  // States start
  const { id } = UseUrlParamsHook();
  const { data } = useOutletContext();
  const [chatPerson, setChatPerson] = useState(
    data ? (data.tenant ? data.tenant : data.landlord) : null
  );
  const [textMessage, settextMessage] = useState("");
  const [read, setread] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [setting, setSetting] = useState(false);
  const role = localStorage.getItem("role");
  let prevSenderId = null;
  const navigate = useNavigate();
  // States end

  const socket = useSelector((state) => {
    return state.Chat.socket;
  });

  useEffect(() => {
    setIsLoading(true);
    if (role === "landlord" || role === "user") {
      getLandlordConversations(1, "").then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          const chatPerson = res.message.filter((e) => e.id === id)[0];
          setChatPerson(
            chatPerson.tenant ? chatPerson.tenant : chatPerson.landlord
          );
          // console.log("Chat Person Data ========>", chatPersonData);
        }
      });
    } else {
      getTenantConversations(1, "").then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          const chatPerson = res.message.filter((e) => e.id === id)[0];
          setChatPerson(
            chatPerson.tenant ? chatPerson.tenant : chatPerson.landlord
          );
          // console.log("Chat Person Data ========>", chatPersonData);
        }
      });
    }
  }, [data]);

  useEffect(() => {
    if (socket && id) {
      socket.emit("lastReadMessage", { id: id });
    }
    if (role === "landlord" || role === "user") {
      getLandlordMessages(id).then((res) => {
        if (res.apiCallStatus === "success") {
          setChatMessages(res.message);
        }
      });
    } else {
      getTenantMessages(id).then((res) => {
        if (res.apiCallStatus === "success") {
          setChatMessages(res.message);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (socket) {
      socket.emit("join", { id: id });
      socket.on("chatMessage", (data) => {
        console.log("Chat Message Received", data);
        data._id = data.id;
        setChatMessages((prevMessage) => [...prevMessage, data]);
        socket.emit("readMessage", {
          send_by_landlord: data.send_by_landlord,
          messageId: data.id,
          id: id,
        });
      });
      socket.on("readMessage", (data) => {
        let mm = [...chatMessages];
        mm.filter((e) => e._id === data.id)[0].unread = false;
        setChatMessages(mm);
      });
    }
    return () => {
      socket?.off("chatMessage");
      socket?.off("readMessage");
    };
  }, [chatMessages, read]);

  const SendMessage = () => {
    console.log("Send Message to ", {
      content: textMessage,
      conversation: id,
      role: role,
      user: role == "user" ? localStorage.getItem("user_id") : null,
    });
    socket.emit("chatMessage", {
      content: textMessage,
      conversation: id,
      role: localStorage.getItem("role"),
      user: localStorage.getItem("user_id"),
    });
    settextMessage("");
  };

  const AddOrRemoveArchieve = () => {
    addOrRemoveArchive(id).then((res) => {
      if (res.apiCallStatus === "success") {
        navigate("/user-chat");
      }
    });
  };

  let seen = chatMessages.map((e) => e._id).slice(-1)[0];

  const deleteChat = () => {
    setOpenModal((state) => !state);
  };
  //  Chat CRUD OP End
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {openModal && (
            <DeleteModal
              onClose={deleteChat}
              component="landlord/conversation"
              route={!archive ? "user-chat" : "archive-user"}
              deleteBtnText="Delete Chat"
              delId={id}
            />
          )}
          <div className="chat-body-header d-flex justify-content-between align-items-center border-bottom flex-grow-1">
            <div className="chat-body-header-left d-flex align-items-center justify-content-center gap-3">
              <div className="chat-body-header-img">
                {chatPerson?.profileImage ? (
                  <Avatar
                    src={chatPerson?.profileImage}
                    size="large"
                    style={{ backgroundColor: "" }}
                  />
                ) : (
                  chatPerson.firstName && (
                    <FirstCharacterIcon name={chatPerson?.firstName[0]} />
                  )
                )}
                {text}
              </div>
              <div className="chat-body-header-text">
                <p className="mb-0 text-dark fw-medium">
                  {chatPerson?.firstName + " " + chatPerson?.lastName}
                </p>
                <div>
                  {chatPerson.status === true ? (
                    <p className="mb-0 online-text">
                      {" "}
                      <img src={active_dot} alt="" /> Online
                    </p>
                  ) : (
                    <p className="mb-0 offline-text">
                      {" "}
                      <img src={offline_dot} alt="" /> Offline
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="chat-body-header-right">
              {/* <button
                className={archive ? "d-none" : "move-to-archive-btn"}
                onClick={AddOrRemoveArchieve}
              >
                Move to Archieve
              </button> */}
              <VerticalElipsisIcon
                width={23}
                height={23}
                onClick={() => setSetting(!setting)}
              />
              <RecordMenuPopup
                isOpen={setting}
                onClose={() => {}}
                handler={
                  {
                    // handleView: () => {
                    //   navigate(
                    //     `/account-details/${setting.key}/${setting.account_id}`
                    //   );
                    // },
                    // handleDeleteRecord: () => {
                    //   onOpenDeleteModal();
                    //   setDeleteId(setting.account_id);
                    // },
                  }
                }
                fields={{
                  view: true,
                  delete: true,
                }}
              />
            </div>
          </div>
          <div className="chat-body-container">
            <div className="chat-body mt-5">
              {chatMessages && chatMessages.length > 0
                ? chatMessages.map((data, index) => {
                    const isConsecutive =
                      prevSenderId === data.send_by_landlord; // Check if the current sender is the same as the previous one
                    prevSenderId = data.send_by_landlord; // Update for the next iteration
                    return role === "landlord" || role === "user" ? (
                      <div key={data.id}>
                        {data.send_by_landlord === false ? (
                          <>
                            {/* Tenan side messages */}
                            <div className="chat-message-body-outgoing d-flex justify-content-start align-items-center gap-3 mt-1">
                              {!isConsecutive ? (
                                chatPerson?.profileImage ? (
                                  <Avatar
                                    src={chatPerson.profileImage}
                                    size="large"
                                    style={{ backgroundColor: "" }}
                                  />
                                ) : (
                                  chatPerson.firstName && (
                                    <FirstCharacterIcon
                                      name={chatPerson?.firstName[0]}
                                    />
                                  )
                                )
                              ) : (
                                <div style={{ width: "40px" }}></div>
                              )}
                              <div className="position-relative">
                                <div className="message-incoming mt-1">
                                  {data.text}
                                  <div className="message-time normal-grey-text fw-medium">
                                    {moment(data.timestamp)
                                      .format("h:mm a")
                                      .toUpperCase()}
                                  </div>
                                </div>
                                {data._id === seen && data.unread === false && (
                                  <div className="seen-img position-absolute end-0">
                                    <span className="normal-grey-text seen-text">
                                      Seen
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {/* Landlord or user side messages */}
                            <div className="chat-message-body-incoming d-flex justify-content-end align-items-center gap-3 mt-1">
                              <div className="position-relative">
                                <div className="message-incoming">
                                  {data.text}
                                  <div className="message-time normal-grey-text fw-medium">
                                    {moment(data.timestamp)
                                      .format("h:mm a")
                                      .toUpperCase()}
                                  </div>
                                </div>
                              </div>
                              {data.user !== null ? (
                                data.user?.profileImage ? (
                                  <Tooltip
                                    title={
                                      data.user?.firstName +
                                      " " +
                                      data.user?.lastName
                                    }
                                  >
                                    <Avatar
                                      src={data.user?.profileImage}
                                      size="large"
                                      style={{ backgroundColor: "" }}
                                    />
                                  </Tooltip>
                                ) : (
                                  data.user?.firstName && (
                                    <FirstCharacterIcon
                                      name={
                                        data.user?.firstName +
                                        " " +
                                        data.user?.lastName
                                      }
                                      tooltip={true}
                                    />
                                  )
                                )
                              ) : (
                                <FirstCharacterIcon
                                  name={"Landlord"}
                                  tooltip={true}
                                />
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div key={data.id}>
                        {data.send_by_landlord === false ? (
                          <>
                            {/* Tenant side messages on Tenant Portal */}
                            <div className="chat-message-body-outgoing d-flex justify-content-end align-items-center gap-3">
                              <div className="position-relative">
                                <div className="message-incoming mt-1">
                                  {data.text}
                                  <div className="message-time normal-grey-text fw-medium">
                                    {moment(data.timestamp)
                                      .format("h:mm a")
                                      .toUpperCase()}
                                  </div>
                                </div>
                                {data._id === seen && data.unread === false && (
                                  <div className="seen-img position-absolute end-0">
                                    <span className="normal-grey-text seen-text">
                                      Seen
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {/* Landlord side messages on Tenant Portal */}
                            <div className="chat-message-body-incoming d-flex justify-content-start align-items-center gap-3 mt-1">
                              {!isConsecutive ? (
                                chatPerson?.profileImage ? (
                                  <Avatar
                                    src={chatPerson.profileImage}
                                    size="large"
                                    style={{ backgroundColor: "" }}
                                  />
                                ) : (
                                  chatPerson.firstName && (
                                    <FirstCharacterIcon
                                      name={chatPerson?.firstName[0]}
                                    />
                                  )
                                )
                              ) : (
                                <div style={{ width: "40px" }}></div>
                              )}
                              <div className="position-relative">
                                <div className="message-incoming">
                                  {data.text}
                                  <div className="message-time normal-grey-text fw-medium">
                                    {moment(data.timestamp)
                                      .format("h:mm a")
                                      .toUpperCase()}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
          {!archive ? (
            <div className="chat-text-area border-top pt-4">
              <textarea
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    SendMessage();
                  }
                }}
                onChange={(e) => settextMessage(e.target.value)}
                value={textMessage}
                placeholder="Type your message.... "
                name=""
                id=""
                cols="30"
                rows="10"
                className="send-message-text-area"
              ></textarea>
              <button
                onClick={SendMessage}
                className="save-btn-outline fw-bold primary-orange-text"
              >
                Send
              </button>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default ChatBody;
