import reportsImg from "assets/fi_2912773.png";
import filesImg from "assets/fi_1157077.png";
import leasesImg from "assets/fi_4221578.png";
import { Link } from "react-router-dom";
import UserPermission from "libs/UserPermission";
import { useEffect } from "react";
import { LockWrapper } from "Components/GeneralComponents";
const Documentation = () => {
  const { FetchUserRole, role, ROLE } = UserPermission();
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];

  useEffect(() => {
    FetchUserRole();
  }, []);
  return (
    <>
      <div className="container-fluid p-4">
        <div className="row">
          <div className="col-md-12 text-center">
            <p className="contact-page-title my-4">Please Select Documents</p>
          </div>
        </div>
        <div className="row justify-content-center p-4">
          <div className="col-md-4">
            <LockWrapper
              isLocked={
                !["Owner/Executive", "Property Manager"].some((role) =>
                  myUserRoles?.includes(role)
                ) && myUserRoles?.length !== 0
              }
            >
              <div className="card contact-page-card-main h-100">
                <Link to="/all-reports/property-reports">
                  <div className="card-body p-4">
                    <img
                      src={reportsImg}
                      alt=""
                      className="d-block mx-auto mb-3 contact-page-card-img"
                    />
                    <p className="text-center contact-page-sub-title">
                      Reports
                    </p>
                    <p className="contact-page-sub-text text-center mb-0">
                      Detailed summaries of property performance, tenant and
                      financial records.
                    </p>
                  </div>
                </Link>
              </div>
            </LockWrapper>
          </div>
          <div className="col-md-4">
            <LockWrapper
              isLocked={
                !["Owner/Executive", "Property Manager"].some((role) =>
                  myUserRoles?.includes(role)
                ) && myUserRoles?.length !== 0
              }
            >
              <div className="card contact-page-card-main h-100">
                <Link to="/all-files">
                  <div className="card-body p-4">
                    <img
                      src={filesImg}
                      alt=""
                      className="d-block mx-auto mb-3 contact-page-card-img"
                    />
                    <p className="text-center contact-page-sub-title">Files</p>
                    <p className="contact-page-sub-text text-center mb-0">
                      A secure place where documents are uploaded, stored,
                      viewed, and downloaded.
                    </p>
                  </div>
                </Link>
              </div>
            </LockWrapper>
          </div>
        </div>
        <div className="row justify-content-center p-4">
          <div className="col-md-4"></div>

          <div className="col-md-4">
            <div className="card contact-page-card-main h-100">
              <Link to="/all-lease">
                <div className="card-body p-4">
                  <img
                    src={leasesImg}
                    alt=""
                    className="d-block mx-auto mb-3 contact-page-card-img"
                  />
                  <p className="text-center contact-page-sub-title">Leases</p>
                  <p className="contact-page-sub-text text-center mb-0">
                    Start here to create and manage your lease agreements.
                  </p>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </>
  );
};

export default Documentation;
