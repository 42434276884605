import React, { useState, useEffect } from "react";
import { availableProperties } from "network/landlord/prospect";
import { formatDate } from "utils";
import {
  selectBoolean,
  selectLeaseTerm,
  selectFrequency,
  selectFrequencyFromThree,
  selectLateFeeFrequency,
} from "utils/dropdownData";
import * as Component from "Components/GeneralComponents";
import Loader from "Helpers/Loader";

const LeaseInfo = ({
  form,
  handleChange,
  errors,
  leaseFiles,
  setLeaseFiles,
  handlePropertyType,
  handleNext,
}) => {
  const [properties, setProperties] = useState([]);
  const [units, setUnits] = useState([]);
  const [makeEmptyUnit, setMakeEmptyUnit] = useState(false);
  const [data, setData] = useState([]);
  const [isWarning, setIsWarning] = useState(false);
  const [propertyFieldValue, setPropertyFieldValue] = useState("");

  useEffect(() => {
    console.log("Property Id => ", form.property.id);
    if (form.property.id) {
      availableProperties().then((response) => {
        if (response.apiCallStatus == "success") {
          setProperties(response.message.propertiesWithUnassignedUnits);
          setData(
            response.message.propertiesWithUnassignedUnits.map((e) => ({
              id: e._id,
              heading1: e.property_type,
              heading2: e.title,
              heading3: e.address,
            }))
          );

          // console.log("INTERESTED IN => ", interestedIn);
          const value = response.message.propertiesWithUnassignedUnits.filter(
            (e) => e._id === form.property.id
          )[0];

          console.log("Value => ", value);

          if (!value) {
            setIsWarning(true);
          }
          value
            ? setPropertyFieldValue(`${value?.title} / ${value?.property_type}`)
            : setPropertyFieldValue("-");
        }
      });
    }
  }, [form.property.id]);

  const resetForm = () => {
    setIsWarning(false);
    handleChange("property", {
      id: "",
      heading1: "",
      heading2: "",
      heading3: "",
    });
    handleChange("unit", "");
  };

  // Set units based on selected property
  useEffect(() => {
    if (form.property.id && properties.length > 0) {
      const selectedProperty = properties.find(
        (property) => property._id === form.property.id
      );
      selectedProperty && handlePropertyType(selectedProperty.property_type);
      selectedProperty && setUnits(selectedProperty.units);
    }
  }, [properties]);

  // Handle property change
  const handlePropertyChange = (e) => {
    handleChange("property", e);
    const selectedProperty = properties.find(
      (property) => property._id === e.id
    );
    handlePropertyType(selectedProperty.property_type);
    setUnits(selectedProperty.units);
    setMakeEmptyUnit(true);
  };

  // Make unit field empty when property changes
  useEffect(() => {
    if (makeEmptyUnit) {
      handleChange("unit", "");
      setMakeEmptyUnit(false);
    }
  }, [form.property]);

  return (
    <>
      {propertyFieldValue ? (
        <>
          <div className="container-fluid">
            <Component.WarningModal
              isOpen={isWarning}
              onClose={() => resetForm()}
              message={
                "Intrested property is not available, please choose different one"
              }
            />
            <div className="row">
              <div className="col-md-12">
                <Component.SelectPropertyField
                  label={"Property"}
                  // value={`${form.property.heading2} / ${form.property.heading1}`}
                  value={propertyFieldValue === "-" ? "" : propertyFieldValue}
                  placeholder={"Select Property"}
                  category={"lease"}
                  isRequired={true}
                  PropertyData={data}
                  errorMessage={errors.property}
                  onPropertySelect={(selectedProperty) => {
                    handlePropertyChange(selectedProperty);
                  }}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <Component.SelectField
                  name={"unit"}
                  required={true}
                  label={"Unit"}
                  TooltipTitle={
                    "Unit can't be selected without selecting property first."
                  }
                  placeholder={"First select property then unit"}
                  value={form.unit}
                  options={units.map((e) => ({ value: e.id, label: e.name }))}
                  handler={(e) => handleChange("unit", e)}
                  errorMessage={errors.unit}
                  disabled={units.length === 0 ? true : false}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <Component.SelectField
                  name={"lease_term"}
                  required={true}
                  label={"Lease Term"}
                  placeholder={"Select Lease Term"}
                  value={form.lease_term}
                  options={selectLeaseTerm}
                  handler={(e) => handleChange("lease_term", e)}
                  errorMessage={errors.lease_term}
                />
              </div>
              <div className="col-md-4">
                <Component.DateField
                  required={true}
                  label={"Lease Term Start Date"}
                  placeholder={"Select Start Date"}
                  defaultValue={
                    form.lease_term_start_date &&
                    formatDate(form.lease_term_start_date)
                  }
                  handler={(e) => {
                    const formattedDate = new Date(e).toLocaleDateString();
                    handleChange("lease_term_start_date", formattedDate);
                  }}
                  errorMessage={errors.lease_term_start_date}
                />
              </div>
              <div className="col-md-4">
                <Component.DateField
                  required={!(form.lease_term === "Month-to-Month")}
                  label={"Lease Term End Date"}
                  placeholder={"Select End Date"}
                  defaultValue={
                    form.lease_term_end_date &&
                    formatDate(form.lease_term_end_date)
                  }
                  handler={(e) => {
                    const formattedDate = new Date(e).toLocaleDateString();
                    handleChange("lease_term_end_date", formattedDate);
                  }}
                  errorMessage={errors.lease_term_end_date}
                  disabled={form.lease_term === "Month-to-Month"}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <Component.DateField
                  required={true}
                  label={"First Rent Date"}
                  placeholder={"Select Date"}
                  defaultValue={
                    form.first_rent_date && formatDate(form.first_rent_date)
                  }
                  handler={(e) => {
                    const formattedDate = new Date(e).toLocaleDateString();
                    handleChange("first_rent_date", formattedDate);
                  }}
                  errorMessage={errors.first_rent_date}
                />
              </div>
              <div className="col-md-4">
                <Component.SelectField
                  name={"frequency"}
                  required={true}
                  label={"Frequency"}
                  placeholder={"Select Frequency"}
                  value={form.frequency}
                  options={
                    form.lease_term === "Month-to-Month"
                      ? selectFrequencyFromThree
                      : selectFrequency
                  }
                  handler={(e) => handleChange("frequency", e)}
                  errorMessage={errors.frequency}
                />
              </div>
              <div className="col-md-4">
                <Component.AmountField
                  name={"rent_amount"}
                  required={true}
                  label={"Rent Amount"}
                  placeholder={"Enter Rent Amount"}
                  value={form.rent_amount}
                  handler={(value) => handleChange("rent_amount", value)}
                  errorMessage={errors.rent_amount}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <Component.SelectField
                  name={"security_deposit"}
                  label={"Security Deposit"}
                  placeholder={"Select Security Deposit"}
                  value={form.security_deposit}
                  options={selectBoolean}
                  handler={(e) => handleChange("security_deposit", e)}
                />
              </div>
              <div className="col-md-6">
                <Component.AmountField
                  name={"security_deposit_amount"}
                  label={"Security Deposit Amount"}
                  placeholder={"Enter Security Deposit Amount"}
                  value={form.security_amount}
                  handler={(value) => handleChange("security_amount", value)}
                  disabled={form.security_deposit === "Yes" ? false : true}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <Component.SelectField
                  name={"late_fee"}
                  label={"Late Fees"}
                  placeholder={"Select Late  Fees"}
                  value={form.late_fee}
                  options={selectBoolean}
                  handler={(e) => handleChange("late_fee", e)}
                />
              </div>

              <div className="col-md-6">
                <Component.InputField
                  name={"late_fee_charge"}
                  label={"When to Charge Late Fees?"}
                  type={"number"}
                  placeholder={"Days after rent due"}
                  value={form.late_fee_charge}
                  handler={(e) =>
                    handleChange("late_fee_charge", e.target.value)
                  }
                  disabled={form.late_fee === "Yes" ? false : true}
                  TooltipTitle={
                    "Number of days, after the due date, when late fees should be applied."
                  }
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <Component.SelectField
                  name={"late_fee_frequency"}
                  label={"Frequency"}
                  placeholder={"Select Frequency"}
                  value={form.late_fee_frequency}
                  options={selectLateFeeFrequency}
                  handler={(e) => handleChange("late_fee_frequency", e)}
                  disabled={form.late_fee === "Yes" ? false : true}
                />
              </div>

              <div className="col-md-6">
                <Component.AmountField
                  name={"late_fees_amount"}
                  label={"Late Fee Amount"}
                  placeholder={
                    form.late_fees_amount.type === "%"
                      ? "Enter Late Fees Percentage"
                      : "Enter Late Fees Amount"
                  }
                  value={form.late_fees_amount}
                  handler={(value) => handleChange("late_fees_amount", value)}
                  amountTypes={["$", "%"]}
                  disabled={form.late_fee === "Yes" ? false : true}
                />
              </div>
            </div>
            <div className="stepper-first-btn property-details-next-btn-main d-flex justify-content-between gap-3 mt-5">
              <Component.SubmitButton handler={handleNext}>
                Next
              </Component.SubmitButton>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default LeaseInfo;
