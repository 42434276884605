import React, { useState, useEffect } from "react";
import { Tabs, ConfigProvider } from "antd";
import { message } from "antd";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import settingIconOrange from "assets/dots-vertical.png";
import AddVendorsDetailsEditModal from "Modals/EditVendorsDetailsModal/EditVendorsDetailsModal";
import { useNavigate } from "react-router-dom";
import { FilesTab, NotesTab } from "Components/TenantDetailsView";
import { OverviewTab } from "./OverviewTab";
import * as API from "network/landlord/vendor";
import * as Component from "Components/GeneralComponents";

const VendorDetailView = () => {
  const { id } = UseUrlParamsHook();
  const { TabPane } = Tabs;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openEditModalTask, setOpenEditModalTask] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [force, setForce] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isDeleteVendorRender, setIsDeleteVendorRender] = useState(false);
  const [forceDeleteMsg, setForceDeleteMsg] = useState(
    "Are you sure you want to delete this vendor?"
  );

  const navigate = useNavigate();

  const onCloseEditModalTask = () => {
    setOpenEditModalTask(false);
  };
  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
    setDeleteId(id);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleIconClickCustom = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // handle delete vendor
  useEffect(() => {
    if (deleteId) {
      setIsLoader(true);
      API.deleteVendor(deleteId, force).then((res) => {
        setIsLoader(false);
        if (res.apiCallStatus === "success") {
          onCloseDeleteModal();
          message.success("Vendor Deleted Successfully");
          setDeleteId("");
          setForce("");
          navigate(`/all-vendor`);
        } else if (res.apiCallStatus === "error") {
          setForce("true");
          setForceDeleteMsg(res.message);
        }
      });
    }
  }, [isDeleteVendorRender]);

  return (
    <>
      <Component.DeleteModal
        isOpen={openDeleteModal}
        onClose={onCloseDeleteModal}
        message={forceDeleteMsg}
        deleteCall={() => setIsDeleteVendorRender(!isDeleteVendorRender)}
        isLoading={isLoader}
        isForceDelete={force}
      />
      {openEditModalTask && (
        <AddVendorsDetailsEditModal id={id} onClose={onCloseEditModalTask} />
      )}
      <div className="container-fluid bg-white p-3">
        <div className="row">
          <div className="col-md-12 text-end">
            <span className="position-relative">
              <img
                onClick={handleIconClickCustom}
                src={settingIconOrange}
                alt="Action Buttons"
                className="cursor"
              />
              <Component.RecordMenuPopup
                isOpen={dropdownOpen}
                onClose={() => setDropdownOpen(false)}
                fields={{ edit: true, delete: true }}
                handler={{
                  handleDeleteRecord: () => onOpenDeleteModal(),
                  handleEdit: () => navigate(`/edit-vendor-details/${id}`),
                }}
              />
            </span>
          </div>
        </div>
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                inkBarColor: "#EF6B3E",
                itemColor: "#667085",
                itemSelectedColor: "#EF6B3E",
                itemHoverColor: "#EF6B3E",
                titleFontSize: 15,
                fontFamily: "Montserrat",
              },
            },
          }}
        >
          <Tabs
            centered
            defaultActiveKey="1"
            className="property_details_view_tabs"
          >
            <TabPane tab="Overview" key="1">
              <OverviewTab id={id} />
            </TabPane>
            <TabPane tab="Files" key="3">
              <FilesTab category={"vendor"} id={id} />
            </TabPane>
            <TabPane tab="Notes" key="4">
              <NotesTab category={"vendor"} id={id} />
            </TabPane>
          </Tabs>
        </ConfigProvider>
      </div>
    </>
  );
};

export default VendorDetailView;
