import { InputField, PhoneNumberField, SelectField, SubmitButton } from "Components/GeneralComponents";
import React from "react";

const ScreeningQuestions = ({
  form,
  errors,
  handleChange,
  handleNext,
  handlePrev,
}) => {
  return (
    <div>
      <div
        style={{
          fontFamily: "Montserrat",
          fontWeight: "600",
          fontSize: "20px",
          lineHeight: "24px",
          color: "#344054",
        }}
      >
        Standard Questions
      </div>

      <div
        style={{
          fontFamily: "Montserrat",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#344054A1",
        }}
        className="mt-3"
      >
        Please answer to the best of your ability all of the questions below.
      </div>

      <div className="row mt-3">
        <div className="col-md-12">
          <SelectField
            name={"pets"}
            label={"How many pets will be living in the unit with you?"}
            placeholder={"Select options"}
            options={[
              { value: "0", label: "0" },
              { value: "1", label: "1" },
              { value: "2", label: "2" },
              { value: "3", label: "3" },
              { value: "4", label: "4" },
              { value: "5", label: "5" },
              { value: "6", label: "6" },
              { value: "7", label: "7" },
              { value: "8", label: "8" },
              { value: "9", label: "9" },
              { value: "10", label: "10" },
            ]}
            value={form.pets}
            handler={(e) => handleChange("pets", e)}
            errorMessage={errors.pets}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <SelectField
            name={"people"}
            label={"How many people will be living in the unit with you?"}
            placeholder={"Select options"}
            options={[
              { value: "0", label: "0" },
              { value: "1", label: "1" },
              { value: "2", label: "2" },
              { value: "3", label: "3" },
              { value: "4", label: "4" },
              { value: "5", label: "5" },
              { value: "6", label: "6" },
              { value: "7", label: "7" },
              { value: "8", label: "8" },
              { value: "9", label: "9" },
              { value: "10", label: "10" },
            ]}
            value={form.people}
            handler={(e) => handleChange("people", e)}
            errorMessage={errors.people}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <SelectField
            name={"individuals"}
            label={
              "How many total individuals will be applying for this unit, including yourself?"
            }
            placeholder={"Select options"}
            options={[
              { value: "0", label: "0" },
              { value: "1", label: "1" },
              { value: "2", label: "2" },
              { value: "3", label: "3" },
              { value: "4", label: "4" },
              { value: "5", label: "5" },
              { value: "6", label: "6" },
              { value: "7", label: "7" },
              { value: "8", label: "8" },
              { value: "9", label: "9" },
              { value: "10", label: "10" },
            ]}
            value={form.individuals}
            handler={(e) => handleChange("individuals", e)}
            errorMessage={errors.individuals}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mt-3">
          <InputField
            name={"Full Name"}
            required={true}
            label={"Full Name"}
            placeholder={"Full Name"}
            value={form.fullName}
            handler={(e) => handleChange("fullName", e.target.value)}
            errorMessage={errors.fullName}
          />
        </div>
        <div className="col-md-6 mt-3">
          <PhoneNumberField
            name={"Phone No"}
            required={true}
            label={"Phone No"}
            placeholder={"Phone No"}
            value={form.phone}
            handler={(e) => handleChange("phone", e)}
            errorMessage={errors.phone}
          />
        </div>
      </div>
      <div className="stepper-btn d-flex justify-content-between mt-5 gap-1">
        <div style={{ width: "50%" }}>
          <SubmitButton type="contained" handler={handlePrev}>
            Back
          </SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <SubmitButton handler={handleNext}>Next</SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default ScreeningQuestions;
