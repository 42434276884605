import React from "react";
import { Radio, ConfigProvider, message } from "antd";

const PremiumPackageCard = ({
  icon,
  item,
  details,
  selectedPlan,
  selectedId,
  handlePlanValue,
  handlePayNow,
  UpdateSubscription,
}) => {
  const isSelected = selectedPlan && item?._id === selectedPlan?.plan?._id;
  const buttonText = isSelected
    ? "Selected"
    : selectedPlan?.plan?.price < item.price
    ? "Upgrade"
    : !selectedPlan?.plan?._id
    ? "Upgrade"
    : "Downgrade";

  const handleButtonClick = () => {
    console.log("selectedPlan handle plan ===>", selectedPlan?.plan?._id);
    if (isSelected) {
      message.success("This plan is already selected");
    } else if (!selectedPlan?.plan?._id) {
      handlePayNow(item?.planName);
    } else {
      UpdateSubscription(
        item?.planName,
        selectedPlan?.plan?.price < item.price
      );
    }
  };

  return (
    <div key={item?._id} className="col-lg-3 col-md-6 col-sm-12 mt-4 d-flex justify-content-evenly">
      <div className="land-lord-package rounded border">
        <div className="package-name text-start border-bottom d-flex align-items-center justify-content-between">
          <div className="package-name-and-icon-container">
            <img src={icon} alt="" /> {item?.planName}
          </div>
          {!selectedPlan && (
            <div className="package-select-radio-container">
              <Radio.Group
                onChange={(e) =>
                  handlePlanValue(
                    e.target.value,
                    item._id,
                    item.planName,
                    item.price
                  )
                }
                value={selectedId === item?._id ? 1 : 0}
              >
                <ConfigProvider
                  theme={{
                    components: {
                      Radio: {
                        colorPrimary: "#EF6B3E",
                        radioSize: 18,
                        fontSize: 16,
                        fontFamily: "Montserrat",
                      },
                    },
                  }}
                >
                  <Radio value={1}></Radio>
                </ConfigProvider>
              </Radio.Group>
            </div>
          )}
        </div>
        <div className="package-details text-start">
          <span className="limited-time-text-subscription">
            Limited time only
          </span>
          <p className="mt-3">
            <span className="package-big-text text-dark">
              ${ details.interval === "Annually" ? (item?.price.toLocaleString()/12) : item?.price.toLocaleString() }
            </span>{" "}
            per month
            <br />
            {details.description}
          </p>
          <p className="fw-bold">Includes:</p>
          {details.features.map((feature, index) => (
            <p key={index}>{feature}</p>
          ))}
          {selectedPlan && (
            <button
              onClick={handleButtonClick}
              className={isSelected ? "save-btn p-2" : "table-delete-btn w-100"}
            >
              {buttonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PremiumPackageCard;
