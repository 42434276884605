import React from "react";

const Stepper = ({ currentStep }) => {
  return (
    <div className="stepper-container step-container-responsive">
      <div
        className={
          currentStep === 1 || currentStep === 2 || currentStep === 3
            ? "step-1 property-type stepper-active"
            : "step-1 property-type stepper-inactive"
        }
      >
        1<div className="form-step-text">Property Type</div>
      </div>
      <div
        className={
          currentStep === 2 || currentStep === 3
            ? "step-2 property-details stepper-active"
            : "step-2 property-details stepper-inactive"
        }
      >
        2<div className="form-step-text">Property Details</div>
      </div>
      <div
        className={
          currentStep === 3
            ? "step-3 unit information stepper-active"
            : "step-3 unit information stepper-inactive"
        }
      >
        3<div className="form-step-text">Unit Information</div>
      </div>
      <div className="lines-property">
        <div
          className={`line ${
            currentStep === 2 || currentStep === 3 ? "active" : ""
          }`}
        ></div>
        <div className={`line ${currentStep === 3 ? "active" : ""}`}></div>
      </div>
    </div>
  );
};

export default Stepper;
