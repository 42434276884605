import { useEffect, useState } from "react";
import { Table, ConfigProvider } from "antd";
import oval from "assets/Oval.png";
import { useNavigate, Link } from "react-router-dom";
import trashIconWhite from "assets/trash-icon-white.png";
import PostUpdateModal from "Modals/PostUpdateModal/PostUpdateModal";
import PaymentSuccessModal from "Modals/PaymentSuccessModal/PaymentSuccessModal";
import SearchBar from "Helpers/SearchBar";
import { useSelector } from "react-redux";
import Loader from "Helpers/Loader";
import UserPermission from "libs/UserPermission";
import SearchBarWithOutBtn from "Helpers/SearchBarWithOutBtn";
import { ButtonVariant1 } from "Components/GeneralComponents";
import { useDispatch } from "react-redux";
import { FilterValue } from "Store/Slices/FilterSlice";
import * as Component from "Components/GeneralComponents";
import * as InnerComponent from "./index";
import * as Icon from "assets/icons";
import {
  getTasks,
  deleteTask,
  deleteTasks,
  completeTask,
} from "network/landlord/task";
import { message } from "antd";

const AllTasks = () => {
  // States start
  const [selectionType, setSelectionType] = useState("checkbox");
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [openModalPostUpdate, setOpenModalPostUpdate] = useState(false);
  const [openModalTask, setOpenModalTask] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [key, setKey] = useState([]);
  const [DeleteId, setDeleteId] = useState("");
  const [taskId, setTaskId] = useState("");
  const [openEditModalTask, setOpenEditModalTask] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRerender, setIsRerender] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDeleteTasksModal, setIsDeleteTasksModal] = useState(false);
  const [isDeleteTaskRender, setIsDeleteTaskRender] = useState(false);
  const [isDeleteTasksRender, setIsDeleteTasksRender] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [selectedTaskData, setSelectedTaskData] = useState([]);
  // States end
  const { ROLE } = UserPermission();
  const dispatch = useDispatch();

  const resetFilter = () => {
    dispatch(FilterValue({ reset: "reset" }));
  };

  const search = useSelector((state) => {
    return state.Search.value;
  });
  // Fetch Data
  const range = useSelector((state) => {
    return state.FilterValue.value;
  });
  const property = useSelector((state) => {
    return state.FilterValue.property;
  });
  const fromDate = useSelector((state) => {
    return state.FilterValue.fromDate;
  });
  const toDate = useSelector((state) => {
    return state.FilterValue.toDate;
  });
  const handleTaskComplete = (id) => {
    completeTask(id).then((res) => {
      if (res.apiCallStatus === "success") {
        message.success("Task updated successfully");
        setIsRerender(!isRerender);
      } else {
        message.error("Unable to update task");
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getTasks().then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setTaskData(
          res.message.data.map((e) => ({
            key: e._id,
            title: e.title,
            image: e?.image,
            assignees: e.assignees,
            due: {
              dueDate: new Date(e?.dueDate).toLocaleDateString(),
              priority: e?.priority,
            },
            related: e?.propertyAddress,
            status: e.status || "N/A",
            propertyId: e?.property,
            unitId: e?.unit,
            updates: e?.updates,
          }))
        );
      }
    });
  }, [range, property, fromDate, toDate, isRerender]);

  const navigate = useNavigate();
  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  // Modal Function
  const onOpenModalPostUpdate = () => {
    setOpenModalPostUpdate(true);
  };
  const onCloseModalPostUpdate = () => {
    setOpenModalPostUpdate(false);
  };
  const onOpenEditModalTask = () => {
    setOpenEditModalTask(true);
  };
  const onCloseEditModalTask = () => {
    setOpenEditModalTask(false);
  };
  const onOpenModalTask = () => {
    setOpenModalTask(true);
  };
  const onCloseModalTask = () => {
    setOpenModalTask(false);
  };

  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = taskData.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      render: (text, task) => (
        <Link
          to={`/task-details?id=${task.key}`}
          // className="all-task-table-title-text"
          className="d-flex align-items-center gap-2"
        >
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              overflow: "hidden",
              border: `0.5px solid ${task.image ? "#c9c9c9" : "#ef6b3e"}`,
              position: "relative",
              backgroundColor: "white",
            }}
          >
            {task.image ? (
              <img
                src={task.image}
                alt="task"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <Icon.TaskIcon width={20} height={20} />
            )}
          </div>
          {text}
        </Link>
      ),
    },
    {
      title: "Assigned To",
      dataIndex: "assignees",
      render: (text, assignees) => (
        <>
          {" "}
          <Component.IconList
            properties={assignees.assignees}
            defaultimage={oval}
            name={true}
          />
        </>
      ),
    },
    {
      title: "Due At",
      dataIndex: "due",
      render: (text) => (
        <>
          {text.priority ? (
            <div className="assign-date-container">
              <div
                className={
                  text.priority === "High"
                    ? "priority-text bg-error"
                    : text.priority === "Low"
                    ? "priority-text bg-grey"
                    : text.priority === "Medium"
                    ? "priority-text bg-yellow"
                    : text.priority === "Very High"
                    ? "priority-text bg-error"
                    : ""
                }
              >
                <span>{`${text.priority} Priority`}</span>
              </div>
              <br />
              <span className="assign-to-date">{text.dueDate}</span>
            </div>
          ) : (
            <span>Not Set</span>
          )}
        </>
      ),
    },
    {
      title: "Related To",
      dataIndex: "related",
      render: (text) => <div className="all-lease-property-text">{text}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <>
          <span
            className={
              status === "Completed"
                ? "completed-status text-white"
                : status === "In Progress"
                ? "in-progress-status text-white"
                : status === "Not Started"
                ? "not-started-status text-dark"
                : ""
            }
          >
            {status}
          </span>{" "}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleView: () => navigate(`/task-details?id=${setting.key}`),
                handleEdit: () => {
                  setSelectedTaskData(setting);
                  onOpenEditModalTask();
                  setTaskId(setting.key);
                },
                handlePostAnUpdate: () => {
                  setSelectedTaskData(setting);
                  console.log("setting", setting);
                  onOpenModalPostUpdate();
                  setTaskId(setting.key);
                },
                handleComplete: () => {
                  handleTaskComplete(setting.key);
                  setIsRerender(!isRerender);
                },
                handleDeleteRecord: () => {
                  onOpenDeleteModal();
                  setDeleteId(setting.key);
                },
              }}
              fields={{
                view: true,
                edit: setting.updates.length > 1 ? false : true,
                postAnUpdate: true,
                complete: true,
                delete: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedTableItem([...selectedRowKeys]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  // handle delete task
  useEffect(() => {
    if (DeleteId) {
      setIsLoading(true);
      deleteTask(DeleteId).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          setOpenDeleteModal(false);
          message.success("Task Deleted Successfully");
          setIsRerender(!isRerender);
          setDeleteId("");
        }
      });
    }
  }, [isDeleteTaskRender]);

  // handle multiple delete tasks
  useEffect(() => {
    if (selectedTableItem.length > 0) {
      setIsLoading(true);
      deleteTasks(selectedTableItem).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          setIsDeleteTasksModal(false);
          message.success("Tasks Deleted Successfully");
          setIsRerender(!isRerender);
          setSelectedTableItem([]);
        }
      });
    }
  }, [isDeleteTasksRender]);

  return (
    <>
      <Component.DeleteModal
        isOpen={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        message={"Are you sure you want to delete this task?"}
        deleteCall={() => setIsDeleteTaskRender(!isDeleteTaskRender)}
        isLoading={isLoading}
      />

      <Component.DeleteModal
        isOpen={isDeleteTasksModal}
        onClose={() => setIsDeleteTasksModal(false)}
        message={"Are you sure want to delete selected tasks?"}
        deleteCall={() => setIsDeleteTasksRender(!isDeleteTasksRender)}
        isLoading={isLoading}
      />

      <Component.FormModalLayout
        title={"Post Update"}
        isOpen={openModalPostUpdate}
        onClose={onCloseModalPostUpdate}
      >
        <PostUpdateModal
          onClose={() => {
            onCloseModalPostUpdate();
            setIsRerender(!isRerender);
          }}
          id={taskId}
          data={selectedTaskData}
        />
      </Component.FormModalLayout>

      {/* Create New Task */}
      <Component.FormModalLayout
        title={"Add New Tasks"}
        isOpen={openModalTask}
        onClose={onCloseModalTask}
      >
        <InnerComponent.CreateTaskForm
          onClose={() => {
            onCloseModalTask();
            setIsRerender(!isRerender);
          }}
        />
      </Component.FormModalLayout>

      {openModal === true ? (
        <PaymentSuccessModal
          onClose={onCloseModal}
          message="Task details inserted successfully"
          success="All Tasks"
        />
      ) : (
        ""
      )}

      <Component.FormModalLayout
        title={"Edit Task"}
        isOpen={openEditModalTask}
        onClose={onCloseEditModalTask}
      >
        <InnerComponent.EditTaskForm
          id={taskId}
          onClose={() => {
            onCloseEditModalTask();
            setIsRerender(!isRerender);
          }}
          data={selectedTaskData}
        />
      </Component.FormModalLayout>

      <div className="container-fluid bg-white p-3">
        <div className="row">
          <div className="col-md-12">
            <SearchBar
              innerPage={false}
              onClick={onOpenModalTask}
              btnTitle="Add New Task"
              taskFilter={true}
            />
          </div>
        </div>
        {selectedTableItem.length >= 1 && (
          <div className="table-delete-icon mt-3">
            <button
              onClick={() => setIsDeleteTasksModal(true)}
              className="table-delete-btn next-btn-main"
            >
              <img src={trashIconWhite} />
              Delete
            </button>
          </div>
        )}
        <>
          <div className="task-table-container  mt-3">
            {isLoading ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                {range || property || fromDate || toDate ? (
                  <div className="mt-2" style={{ width: "fit-content" }}>
                    <ButtonVariant1 handler={resetFilter} children={"Reset"} />
                  </div>
                ) : (
                  ""
                )}
                <ConfigProvider
                  theme={{
                    components: {
                      Table: {
                        colorTextHeading: "#667085",
                        colorText: "#667085",
                        fontSize: 12,
                        fontWeightStrong: 500,
                        fontFamily: "Montserrat",
                        padding: 10,
                        //cellFontSize: 14,
                      },
                      Pagination: {
                        itemActiveBg: "#EF6B3E",
                      },
                      Checkbox: {
                        colorPrimary: "#EF6B3E",
                        colorPrimaryHover: "#EF6B3E",
                      },
                    },
                  }}
                >
                  <Table
                    pagination={true}
                    className="all-task-table"
                    rowSelection={{
                      type: selectionType,
                      ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={taskData}
                  />
                </ConfigProvider>
              </>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default AllTasks;
