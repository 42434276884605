import React, { useState, useEffect } from "react";
import { ConfigProvider, Tabs } from "antd";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import * as Innercomponent from "./index";
import { getTaskById } from "network/tenant/task";

const { TabPane } = Tabs;
const TenantTaskDetails = () => {
  // States end
  const { id } = UseUrlParamsHook();

  const [isLoading, setIsLoading] = useState(false);
  const [task, setTask] = useState("");

  useEffect(() => {
    setIsLoading(true);
    getTaskById(id).then((res) => {
      if (res.apiCallStatus === "success") {
        setTask(res.message.data);
        setIsLoading(false);
      }
    });
  }, [id]);

  return (
    <>
      <div className="container-fluid bg-white p-3 ">
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                inkBarColor: "#EF6B3E",
                itemColor: "#667085",
                itemSelectedColor: "#EF6B3E",
                itemHoverColor: "#EF6B3E",
                titleFontSize: 15,
                horizontalItemGutter: window.innerWidth <= 425 ? 10 : 30,
                fontFamily: "Montserrat",
              },
            },
          }}
        >
          <Tabs
            centered
            defaultActiveKey="1"
            style={{ fontWeight: 500 }}
            className="property_details_view_tabs"
          >
            <TabPane tab="Overview" key="1">
              <Innercomponent.OverviewTab task={task} isLoading={isLoading} />
            </TabPane>
            <TabPane tab="Notes" key="2">
              <Innercomponent.NotesTab  />
            </TabPane>
            <TabPane tab="Files" key="3">
              <Innercomponent.FilesTab  />
            </TabPane>
          </Tabs>
        </ConfigProvider>
      </div>
    </>
  );
};

export default TenantTaskDetails;
