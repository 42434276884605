import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import SideDrawer from "Components/SideDrawer/SideDrawer";
import InvoiceReports from "Components/InvoiceReports/InvoiceReports";
import ResponsiveSideBar from "Components/ResponsiveSideBar/ResponsiveSideBar";
import { useSelector } from "react-redux";
const InvoiceReportsPage = () => {
  const drawer = useSelector((state) => {
    return state.sideDrawer.drawerState;
  });
  return (
    <>
      <div className="container-fluid">
        <InvoiceReports />
      </div>
    </>
  );
};

export default InvoiceReportsPage;
