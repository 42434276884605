import React, { useState } from "react";
import { Tabs } from "antd";
import editIcon from "assets/edit-05.png";
import deleteIcon from "assets/trash-01.png";
import viewIcon from "assets/Icon.png";
import { ConfigProvider } from "antd";
import { useNavigate } from "react-router-dom";
import UnitDetailsSliderModal from "Modals/UnitDetailsSliderModal/UnitDetailsSliderModal";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import TenantTaskUnitModal from "Modals/TenantTaskUnitModal/TenantTaskUnitModal";
import DeleteModal from "Modals/DeleteModal/DeleteModal";
import settingIconOrange from "assets/dots-vertical.png";
import * as InnerComponent from "./index";
const TenantPropertyUnitsView = () => {
  const { TabPane } = Tabs;
  // Search Start

  const handleIconClickCustom = () => {
    // Toggle the dropdown state when the icon is clicked
    setDropdownOpen(!isDropdownOpen);
  };

  const [openModalTask, setOpenModalTask] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  // Modal Function
  const onOpenModalTask = () => {
    setOpenModalTask(true);
  };

  const onCloseModalTask = () => {
    setOpenModalTask(false);
  };

  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const navigate = useNavigate();

  // Get Property by id
  const { id, property_id } = UseUrlParamsHook();

  return (
    <>
      {openModal === true && <UnitDetailsSliderModal onClose={onCloseModal} />}

      {/* {openDeleteModal === true && (
        <DeleteModal
          deleteBtnText={`Delete ${component}`}
          route="properties-delete-view"
          onClose={onCloseDeleteModal}
          component={component}
          fetchFun={fetchFun}
          delId={deleteId}
        />
      )} */}
      <div className="container-fluid bg-white p-3">
        <div className="row">
          <div className="col-md-12 text-end">
            <img
              onClick={handleIconClickCustom}
              src={settingIconOrange}
              alt="Action Buttons"
              className="cursor"
            />
            {isDropdownOpen && (
              <div className="task-table-setting-dropdown-prospect bg-white box-shadow text-start">
                <ul className="p-0 d-flex flex-column gap-3">
                  <li className="list-style-none cursor lease-details-dropdown-icons">
                    {" "}
                    <img src={viewIcon} alt="" /> View
                  </li>
                  <li
                    onClick={() => {
                      navigate("/");
                    }}
                    className="list-style-none cursor lease-details-dropdown-icons"
                  >
                    {" "}
                    <img src={editIcon} alt="" /> Edit
                  </li>
                  <li className="list-style-none cursor lease-details-dropdown-icons">
                    {" "}
                    <img src={deleteIcon} alt="" /> Delete
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                inkBarColor: "#EF6B3E",
                itemColor: "#667085",
                itemSelectedColor: "#EF6B3E",
                itemHoverColor: "#EF6B3E",
                titleFontSize: 15,
                horizontalItemGutter: window.innerWidth <= 768 ? 10 : 60,
                fontFamily: "Montserrat",
              },
            },
          }}
        >
          <Tabs
            defaultActiveKey="1"
            centered
            style={{ fontWeight: 500 }}
            className="properties_units_view_tabs"
          >
            <TabPane tab="Overview" key="1">
              <InnerComponent.OverviewTab />
            </TabPane>
            <TabPane tab="Tasks" key="4">
              <InnerComponent.TasksTab id={id} />
            </TabPane>
            <TabPane tab="Files" key="5">
              <InnerComponent.FilesTab id={id} />
            </TabPane>
            <TabPane tab="Notes" key="6">
              <InnerComponent.NotesTab id={id} />
            </TabPane>
          </Tabs>
        </ConfigProvider>
      </div>
    </>
  );
};

export default TenantPropertyUnitsView;
