import React from "react";
import { LockFilled } from "@ant-design/icons";
import "style/LockWrapper.css";
import { Tooltip } from "antd";

const LockWrapper = ({ isLocked, tooltip, children }) => {
  return (
    <Tooltip title={tooltip}>
      <div className="lock-wrapper">
        {isLocked && (
          <div className="lock-overlay">
            <LockFilled className="lock-icon" />
          </div>
        )}
        <div className={isLocked ? "locked-content" : ""}>{children}</div>
      </div>
    </Tooltip>
  );
};

export default LockWrapper;
