import React, { useState, useEffect } from "react";
import { availableProperties } from "network/landlord/prospect";
import { formatDate } from "utils";
import {
  selectBoolean,
  selectLeaseTerm,
  selectFrequency,
  selectFrequencyFromThree,
  selectLateFeeFrequency,
} from "utils/dropdownData";
import * as Component from "Components/GeneralComponents";

const DocumentInfo = ({
  form,
  handleChange,
  errors,
  leaseFiles,
  setLeaseFiles,
  handleNext,
  handleBackButton,
  isLoading,
  isSubmitForm,
}) => {
  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col-md-12">
          <Component.SelectField
            name={"isSigned"}
            label={"Is the lease signed?"}
            placeholder={"Select Signed"}
            required={true}
            value={form.isSigned}
            options={selectBoolean}
            handler={(e) => handleChange("isSigned", e)}
          />
        </div>
      </div>
      {errors.isSigned && (
        <div className="text-danger fw-bold mt-2" style={{ fontSize: "12px" }}>
          {errors.isSigned}
        </div>
      )}
      <div className="row mt-4">
        <div className="col-md-12">
          <Component.MediaField
            label={""}
            setImages={setLeaseFiles}
            Images={leaseFiles}
            errorMessage={errors.files}
            height={266}
          />
        </div>
      </div>
      {form.isSigned == "No" && (
        <>
          <div className="tenant-personal-details-title mt-4">
            Would you like to get a new lease sign?
          </div>

          <div className="row mt-3">
            <div className="col-md-12">
              <Component.RadioGroupField
                value={form.newLeaseSign}
                options={[
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ]}
                handler={(e) => handleChange("newLeaseSign", e)}
                direction="row"
                gap="5"
              />
            </div>
          </div>
        </>
      )}
      <div className="d-flex justify-content-between gap-1 pb-3 mt-5">
        <div style={{ width: "50%" }}>
          <Component.SubmitButton type="contained" handler={handleBackButton}>
            Back
          </Component.SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <Component.SubmitButton handler={handleNext} isLoading={isLoading}>
            {isSubmitForm ? "Submit" : "Next"}
          </Component.SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default DocumentInfo;
