import axios from "axios";
import config from "Helpers/config.js";

const headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };

const fetchAccount = async () => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/stripe/accounts`, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteaccount = async (id) => {
  try {
    const response = await axios.delete(
      `${config.baseUrl}/api/stripe/Account/${id}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchAccountProperty = async ({ id }) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/stripe/propertiesAvailableToAddAccount?landlordId=${id}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchClientSecret = async (properties) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/stripe/connect`,
      properties,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchAccountbyId = async (id) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/stripe/accounts/${id}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const postclientproperties = async (account_id, property) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/stripe/connect/${account_id}`,
      { properties: property },
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const Deleteproperty = async (data, account_id) => {
  try {
    // delete property from account by using DELETE method
    const response = await axios.delete(
      `${config.baseUrl}/api/stripe/connect/${account_id}`,
      {
        headers,
        data: data,
      }
    );

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchClientSecrets = async (account_id) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/stripe/stripeConnectAgain`,
      {
        account_id: account_id,
      },
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchtenant = async () => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/landlord/tenants`, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }

};




const fetchInvoiceNo = async () => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/getInvoiceNo`, {
      
      headers,
    });
    console.log(response.data)
    return response.data;
  } catch (error) {
    return error.response.data;
  }

};
const getTenants = async () => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/landLord/tenants/activeleases`, {
      headers,
    });
    console.log("Tenant: ",response.data)
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const fetchTenantProperty = async (tenantId) => {
  console.log(tenantId)
  try {
    const response = await axios.get(`${config.baseUrl}/api/landLord/tenants/activeleases`, {
      headers,
    });
    console.log("Tenant Property: ",response?.data)
    return response?.data;
  } catch (error) {
    console.log("Tenant Property: ",error)
    return error.data.message;
  }
};
const getLease = async (propertyId,unitId) => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/leases/unit/${unitId}/${propertyId}`, {
      headers,
     
    });
    console.log("Tenant Lease: ",response.data)
    return response.data;
  } catch (error) {
    console.log("Tenant Lease: ",error)
    return error.response.data;
  }
};
const createinvoice = async (formdata) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/createInvoice`,
      formdata,
      {
        headers,
      }
    );
    console.log("creating invoice: ",response.data)
    return response.data;
  } catch (error) {
    console.log("creating invoice: ",error)
    return error.response.data;
  }
};

const getAllInvoices = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/getLandlordAllInvoices`,
      
      {
        headers,
      }
    );
    console.log("getting invoices: ",response.data)
    return response.data;
  } catch (error) {
    console.log("creating gettting invoice: ",error)
    return error.response.data;
  }
};
const getAllTenantInvoices = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/getAllTenantInvoices`,
      
      {
        headers,
      }
    );
    console.log("getting invoices: ",response.data)
    return response.data;
  } catch (error) {
    console.log("creating gettting invoice: ",error)
    return error.response.data;
  }
};

const filterAllInvoices = async (filter) => {
  const params = {
    fromDate: filter.fromDate,
    toDate: filter.toDate,
    status: filter.invoiceFilter, // Assuming this is the status
  };

  console.log("Filter Data: ", params);

  try {
    const response = await axios.get(`${config.baseUrl}/api/filterInvoices`, {
      params, // This will send the data as query parameters
      headers, // Headers go in this object
    });

    console.log("Filtering invoices:", response.data);
    return response.data; // Ensure a Promise is returned
  } catch (error) {
    console.log("Error filtering invoices:", error);
    throw error; // Throw error to handle it properly in handleFilter
  }
};
const archiveInvoice = async (invoiceNo) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/archiveInvoiceById?invoiceNo=${invoiceNo}`, // Pass invoiceNo as a query parameter
      {  },
      {
        headers,
      }
    );
    console.log("archiving invoices: ",response.data)
    return response.data;
  } catch (error) {
    console.log("archiving invoices: ",error)
    return error.response.data;
  }
}



const selectinvoicecatogries = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/invoice/getInvoiceCategories`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const connectstripeaccount = async (account_id) => {
  try {
    const response = await axios.put(
      `${config.baseUrl}/api/stripe/connectConfirm`,
      { account_id: account_id },
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// const FetchUser = () => {
//   fetch(`${config["baseUrl"]}/api/auth/landlord/${url}`, {
//     method: "GET",
//     headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//   })
//     .then((res) => {
//       return res.json();
//     })
//     .then((res) => {
//       if (res.apiCallStatus === "success") {
//         let temp = [];
//         temp = res.message;
//         setuser([temp]);
//         console.log(temp, "user info success");
//       } else {
//       }
//     });
// };

const FetchUser = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/auth/landlord/userinfo`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// const FetchUserTenant = () => {
//   fetch(`${config["baseUrl"]}/api/auth/tenant/${url}`, {
//     method: "GET",
//     headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//   })
//     .then((res) => {
//       return res.json();
//     })
//     .then((res) => {
//       if (res.apiCallStatus === "success") {
//         let temp = [];
//         temp = res.message;
//         setuser([temp]);
//         console.log(temp, "user info success");
//       } else {
//         //console.log(res, 'error')
//       }
//     });
// };

const FetchUserTenant = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/auth/tenant/userinfo`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// const FetchUserLandlordUser = () => {
//   fetch(`${config["baseUrl"]}/api/auth/landlord/user/${url}`, {
//     method: "GET",
//     headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//   })
//     .then((res) => {
//       return res.json();
//     })
//     .then((res) => {
//       if (res.apiCallStatus === "success") {
//         let temp = [];
//         temp = res.message;
//         setuser([temp]);
//         console.log(temp, "user info success");
//       } else {
//         //console.log(res, 'error')
//       }
//     });
// };

const FetchUserLandlordUser = async () => {
 
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/auth/landlord/user/userinfo`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// const FetchLandlordPlans = () => {
//   fetch(`${config["baseUrl"]}/api/${url}/plans`, {
//     method: "GET",
//     headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//   })
//     .then((res) => {
//       return res.json();
//     })
//     .then((res) => {
//       if (res.apiCallStatus === "success") {
//         let temp = [];
//         temp = res.message;
//         setplan(temp);
//         console.log(temp, "LANDLORD PLANS SUCCESS");
//       } else {
//         console.log(res, "error");
//       }
//     });
// };

const FetchLandlordPlans = async () => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/landlord/plans`, {
      headers,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// const FetchPaymentMethod = () => {
//   fetch(`${config["baseUrl"]}/api/stripe/${url}?email=${id}`, {
//     method: "GET",
//     headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//   })
//     .then((res) => {
//       return res.json();
//     })
//     .then((res) => {
//       if (res.apiCallStatus === "success") {
//         const { message } = res;
//         setPayment(message.data);
//         console.log(message.data, "payment method success");
//       } else {
//         console.log(res, "error");
//       }
//     });
// };

const FetchPaymentMethod = async (id) => {
  try {
    const response = await axios.get(
      `${
        config.baseUrl
      }/api/stripe/getCustomerPaymentMethod?email=${localStorage.getItem(
        "email"
      )}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const addPaymentMethod = async (formdata) => {
  try {
    const response = await axios.post(
      `https://api.stripe.com/v1/tokens`,
      formdata,
      { headers: { Authorization: `Bearer ${config.stripe_publish_key}` } }
    );
    return response.data;
  } catch (error) {}
};

const addpostpaymnent = async (data) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/stripe/addPaymentMethod`,
      {
        email: localStorage.getItem("email"),
        token: data,
        role: localStorage.getItem("role"),
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {}
};

// const UsePostHook = (url, data, setLoader, modal, setUpdate) => {
//   fetch(`${config.baseUrl}/${url}`, {
//       method: "POST",
//       headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//           "Content-Type": "application/json",
//       },
//       body: JSON.stringify(data),
//   }).then((res) => {
//       return res.json();
//   })
//       .then((res) => {
//           if (res.apiCallStatus === "success") {
//               if (setLoader) setLoader(true);
//               setTimeout(() => {
//                   if (setLoader) setLoader(false);
//                   if (modal) modal();
//                   if (setUpdate) setUpdate(true);
//               }, 2000);
//               console.log(res, "success");
//           } else {
//               message.error(res.error.validations || res.error.message)
//               if (setLoader) setLoader(false);
//           }
//       }).catch(err => {
//           message.error(err.error.validations || err.error.message)
//           if (setLoader) setLoader(false);
//       })
// }



// const FetchEditUserRole = () => {
//   fetch(`${config["baseUrl"]}/api/user/${id}`, {
//     method: "GET",
//     headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//   })
//     .then((res) => {
//       return res.json();
//     })
//     .then((res) => {
//       if (res.apiCallStatus === "success") {
//         const { message } = res;
//         setRole(message);
//         console.log(message, " Edit User role success");
//       } else {
//         console.log(res, "error");
//       }
//     });
// };

export {
  fetchAccount,
  deleteaccount,
  fetchAccountProperty,
  fetchClientSecret,
  fetchAccountbyId,
  postclientproperties,
  Deleteproperty,
  fetchClientSecrets,
  fetchtenant,

  fetchInvoiceNo,
  getTenants,
  fetchTenantProperty, 
  getLease,
  createinvoice,
  getAllInvoices,
  archiveInvoice,

  getAllTenantInvoices,
  filterAllInvoices,




  selectinvoicecatogries,
  connectstripeaccount,
  FetchUser,
  FetchUserTenant,
  FetchUserLandlordUser,
  FetchLandlordPlans,
  FetchPaymentMethod,
  addPaymentMethod,
  addpostpaymnent,
};

