import axios from 'axios';
import config from "Helpers/config.js"

const getLeases = async (params) => {
    try{
        const response = await axios.get(`${config.baseUrl}/api/leases`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            params: params
        });
        return response.data;
    } catch (error){
        return error.response.data;
    }
};

const getLeaseById = async (leaseId , params) => {
    try{
        const response = await axios.get(`${config.baseUrl}/api/leases/${leaseId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            params: params
        });
        return response.data;
    } catch (error){
        return error.response.data;
    }
};

const createLease = async (data) => {
    try{
        const response = await axios.post(`${config.baseUrl}/api/leases`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
        return response.data;
    } catch (error){
        return error.response.data;
    }
};

const updateLease = async (leaseId, data) => {
    try{
        const response = await axios.put(`${config.baseUrl}/api/leases/${leaseId}`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
        return response.data;
    } catch (error){
        return error.response.data;
    }
};

const deleteLease = async (leaseId) => {
    try{
        const response = await axios.delete(`${config.baseUrl}/api/leases/${leaseId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
        return response.data;
    } catch (error){
        return error.response.data;
    }
};

const deleteMultipleLeases = async (leaseIds) => {
    try{
        const response = await axios.delete(`${config.baseUrl}/api/leases`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            data: { leaseIds }
        });
        return response.data;
    } catch (error){
        return error.response.data;
    }
};

const getTenantsList = async () => {
    try{
        const response = await axios.get(`${config.baseUrl}/api/leases/tenants`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
        return response.data;
    } catch (error){
        return error.response.data;
    }
};

export {
    getLeases,
    getLeaseById,
    createLease,
    deleteLease,
    deleteMultipleLeases,
    getTenantsList,
    updateLease
};