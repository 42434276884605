import axios from "axios";
import config from "Helpers/config.js";

const fetchUserTenant = async () => {
    try {
        const response = await axios.get(
        `${config.baseUrl}/api/auth/tenant/userinfo`,
        {
            headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }
        );
        return response.data;
    }
    catch (error) {
        return error.response.data;
    }
};

export { fetchUserTenant };