import React from "react";

const Stepper = ({ currentStep, isCommercial }) => {
  return (
    <div className="stepper-container step-container-responsive tenant-stepper">
      <div
        className={
          currentStep === 1 ||
          currentStep === 2 ||
          currentStep === 3 ||
          currentStep === 4 ||
          currentStep === 5
            ? "step-1 personal-details stepper-active"
            : "step-1 personal-details stepper-inactive"
        }
      >
        1<div className="form-step-text">Edit Profile</div>
      </div>
      <div
        className={
          currentStep === 2 ||
          currentStep === 3 ||
          currentStep === 4 ||
          currentStep === 5
            ? "step-1 personal-details stepper-active"
            : "step-1 personal-details stepper-inactive"
        }
      >
        2<div className="form-step-text">Employment Verification</div>
      </div>
      <div
        className={
          currentStep === 3 || currentStep === 4 || currentStep === 5
            ? "step-2 emergency-info stepper-active"
            : "step-2 emergency-info stepper-inactive"
        }
      >
        3<div className="form-step-text">Rental History</div>
      </div>

      <div
        className={
          currentStep === 4 || currentStep === 5
            ? "step-3 pet-info stepper-active"
            : "step-3 pet-info stepper-inactive"
        }
      >
        4<div className="form-step-text">Credit & Background Check</div>
      </div>

      <div
        className={
          currentStep === 5
            ? "step-4 vehicle-info stepper-active"
            : "step-4 vehicle-info stepper-inactive"
        }
      >
        5<div className="form-step-text">Screening Questions</div>
      </div>
      {/* <div
        className={
          currentStep === 4 || currentStep === 6
            ? "step-5 vehicle-info stepper-active"
            : "step-5 vehicle-info stepper-inactive"
        }
      >
        {!isCommercial ? 6 : 4}
        <div className="form-step-text">Document Info</div>
      </div> */}
      <div className="lines-tenant vender-tab-line">
        <div
          className={`line ${
            currentStep === 2 ||
            currentStep === 3 ||
            currentStep === 4 ||
            currentStep === 5
              ? "active"
              : ""
          }`}
        ></div>
        <div
          className={`line ${
            currentStep === 3 || currentStep === 4 || currentStep === 5
              ? "active"
              : ""
          }`}
        ></div>
        <div
          className={`line ${
            currentStep === 4 || currentStep === 5 ? "active" : ""
          }`}
        ></div>
        <div className={`line ${currentStep === 5 ? "active" : ""}`}></div>
      </div>
    </div>
  );
};

export default Stepper;
