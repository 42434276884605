import React, { useEffect, useState } from "react";
import { Tabs, ConfigProvider, message } from "antd";
import { useNavigate } from "react-router-dom";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import settingIconOrange from "assets/dots-vertical.png";
import { OverviewTab, FilesTab, NotesTab } from "./index";
import { DeleteModal, RecordMenuPopup } from "Components/GeneralComponents";
import { deleteTenant, getTenantById } from "network/landlord/tenant";
import Loader from "Helpers/Loader";
import PropertyTab from "./PropertyTab";

const TenantDetailsView = () => {
  const { id } = UseUrlParamsHook();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [tenant, setTenant] = useState([]);
  const [properties, setProperties] = useState([]);
  const { TabPane } = Tabs;
  const navigate = useNavigate();

  // Get Tenant By Id
  useEffect(() => {
    setIsLoader(true);
    getTenantById(id).then((res) => {
      setIsLoader(false);
      if (res.apiCallStatus === "success") {
        setTenant(res.message);
        setProperties(res.message.properties.reduce((acc, current) => {
          const x = acc.find(item => item.id === current._id);
          if (!x) {
            return acc.concat([{
              id: current._id,
              heading1: current.type,
              heading2: current.title,
              heading3: current.address,
            }]);
          } else {
            return acc;
          }
        }, []));

      } else {
        message.error(res.error.message);
      }
    });
  }, []);

  // Delete Tenant
  const handleDeleteTenant = () => {
    setIsLoading(true);
    deleteTenant(id).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        message.success("Tenant Deleted Successfully");
        setOpenDeleteModal(false);
        navigate("/all-tenants");
      } else {
        message.error(res.error.message);
      }
    });
  };

  const handleIconClickCustom = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        message={"Are you sure you want to delete this tenant?"}
        deleteCall={handleDeleteTenant}
        isLoading={isLoading}
      />

      <div className="container-fluid bg-white p-3">
        <div className="row">
          <div className="col-md-12 text-end">
            <span className="position-relative">
              <img
                onClick={handleIconClickCustom}
                src={settingIconOrange}
                alt="Action Buttons"
                className="cursor"
              />
              <RecordMenuPopup
                isOpen={isDropdownOpen}
                onClose={() => setDropdownOpen(false)}
                fields={{ edit: true, delete: true }}
                handler={{
                  handleDeleteRecord: () => setOpenDeleteModal(true),
                  handleEdit: () => navigate(`/edit-tenant-details/${id}`),
                }}
              />
            </span>
          </div>
        </div>
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                inkBarColor: "#EF6B3E",
                itemColor: "#667085",
                itemSelectedColor: "#EF6B3E",
                itemHoverColor: "#EF6B3E",
                titleFontSize: 15,
                horizontalItemGutter: window.innerWidth <= 768 ? 10 : 60,
                fontFamily: "Montserrat",
              },
            },
          }}
        >
          <Tabs
            centered
            defaultActiveKey="1"
            style={{ fontWeight: 500 }}
            className="property_details_view_tabs"
          >
            <TabPane tab="Overview" key="1">
              {isLoader ? <Loader /> : <OverviewTab tenant={tenant} />}
            </TabPane>
            <TabPane tab="Property" key="2">
              <PropertyTab id={id} category={"tenant"} />
            </TabPane>
            <TabPane tab="Notes" key="3">
              <NotesTab id={id} category={"tenant"} />
            </TabPane>
            <TabPane tab="Files" key="4">
              <FilesTab id={id} category={"tenant"} properties={properties} />
            </TabPane>
          </Tabs>
        </ConfigProvider>
      </div>
    </>
  );
};

export default TenantDetailsView;
