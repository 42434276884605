import React from "react";
import { MapContainer, Marker, TileLayer, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import { Tabs, Skeleton } from "antd";

const DescriptionTab = ({ data }) => {
  const markers = [
    {
      geocode: [48.86, 2.3522],
      popUp: "Hello, I am pop up 1",
    },
  ];
  const customIcon = new Icon({
    iconUrl: require("assets/map-icon.png"),
    iconSize: [38, 38],
  });

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <p className="properties_details_view_description_content">
            {data?.description || <Skeleton paragraph={{ rows: 3 }} />}
          </p>
        </div>
      </div>
      <div className="row mt-5 mb-5">
        <div className="col-12 d-flex  flex-md-row flex-column">
          <div className="col-md-7 col-12 "></div>
          <div className="col-md-5 col-12">
            <MapContainer center={[48.8566, 2.3522]} zoom={13}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {markers.map((marker, index) => (
                <Marker key={index} position={marker.geocode} icon={customIcon}>
                  <Popup>{marker.popUp}</Popup>
                </Marker>
              ))}
            </MapContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default DescriptionTab;
