import React from "react";
import * as Icon from "assets/icons";

function FormModalLayout({ isOpen, onClose, title, children }) {
  return (
    <>
      {isOpen && (
        <div className="maindiv ">
          <div className="internalmaindiv">
            <div className="heading-div sticky-top">
              <h1 className="divtitleh1"  >{title}</h1>
              <button onClick={onClose} className="modal-cancel-btn">
                <Icon.CloseIcon />
              </button>
            </div>
            <div className="childdiv">
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FormModalLayout;
