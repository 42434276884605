import React from "react";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const Checkout = ({ options }) => {
  const stripePromise = loadStripe("pk_test_51Oh0tgF6Gkk6c638flTLOJlT79V1F0aPz3d89neY9x54VqcrOPj4OOqX98dFIlvAabt2wkxFsFkrouAYWVoa4XsW00A9skuqLe");

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options} // Use the options directly here
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default Checkout;
