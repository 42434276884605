import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import {
  AmountField,
  Checkbox,
  InputField,
  MediaField,
  SelectMultipleColumns,
  SubmitButton,
  SuccessModal,
  TextAreaField,
} from "Components/GeneralComponents";
import { selectMultiColumns } from "utils/dropdownData";
import { validateAddUnitDetails } from "utils/validations";
import { addUnit, getProperty } from "network/landlord/property";
import { message } from "antd";

const AddUnitDetails = () => {
  const [Images, setImages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isUnitActive, setIsUnitActive] = useState(false);
  const [loader, setLoader] = useState(false);
  const [form, setForm] = useState({
    property_title: "",
    unit_name: "",
    rent: "",
    bedroom: "",
    bathroom: "",
    area: "",
    amenities: [],
    description: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const navigate = useNavigate();

  // Get Property by id
  const { id } = UseUrlParamsHook();

  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    navigate(`/property-details-view?id=${id}`);
  };

  useEffect(() => {
    getProperty(id).then((res) => {
      if (res.apiCallStatus === "success") {
        setForm({
          ...form,
          property_title: res.message.title,
        });
      }
    });
  }, []);

  // Add Unit
  const AddUnit = () => {
    console.log("form", form);
    const formData = new FormData();
    formData.append("property_id", id);
    formData.append("name", form.unit_name);
    formData.append("description", form.description);
    formData.append("bedroom", form.bedroom);
    formData.append("bathroom", form.bathroom);
    formData.append("area", form.area);
    formData.append("rent_amount", form.rent);
    for (let i = 0; i < form.amenities.length; i++) {
      formData.append(`amenities[${[i]}]`, form.amenities[i]);
    }
    Images.forEach((img) => {
      formData.append("images", img);
    });
    const newErrors = validateAddUnitDetails(form);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setLoader(true);
      addUnit(formData).then((res) => {
        setLoader(false);
        if (res.apiCallStatus === "success") {
          message.success("Unit Added Successfully");
          onOpenModal();
        }
      });
    }
  };

  return (
    <>
      {openModal && (
        <SuccessModal
          heading={"Unit Added Successfully"}
          title={"Unit has been added successfully check your units."}
          route={`property-details-view?id=${id}`}
          success={"Go to Property Details"}
          onClose={onCloseModal}
        />
      )}
      <div className="container-fluid mt-5">
        <div className="container">
          <div className="row mt-3">
            <div className="col-md-12">
              <InputField
                name={"property_title"}
                required={true}
                label={"Property Title"}
                placeholder={"Property Title"}
                value={form.property_title}
                handler={(e) => handleChange("property_title", e.target.value)}
                errorMessage={errors.property_title}
                disabled={true}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <InputField
                name={"unit_name"}
                required={true}
                label={"Unit Name"}
                placeholder={"Unit Name"}
                value={form.unit_name}
                handler={(e) => handleChange("unit_name", e.target.value)}
                errorMessage={errors.unit_name}
              />
            </div>
            <div className="col-md-6">
              <AmountField
                name={"rent"}
                required={true}
                label={"Rent Amount"}
                placeholder={"Enter Rent Amount"}
                value={form.rent}
                handler={(value) => handleChange("rent", value.amount)}
                errorMessage={errors.rent}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <InputField
                name={"bedroom"}
                required={true}
                type="number"
                label={"Bedrooms"}
                placeholder={"Bedrooms"}
                value={form.bedroom}
                handler={(e) => handleChange("bedroom", e.target.value)}
                errorMessage={errors.bedroom}
              />
            </div>
            <div className="col-md-4">
              <InputField
                name={"bathroom"}
                required={true}
                type="number"
                label={"Bathrooms"}
                placeholder={"Bathrooms"}
                value={form.bathroom}
                handler={(e) => handleChange("bathroom", e.target.value)}
                errorMessage={errors.bathroom}
              />
            </div>
            <div className="col-md-4">
              <InputField
                name={"area"}
                required={true}
                type="number"
                label={"SqFt"}
                placeholder={"SqFt"}
                value={form.area}
                handler={(e) => handleChange("area", e.target.value)}
                errorMessage={errors.area}
              />
            </div>
          </div>
          <SelectMultipleColumns
            label={"Select Amenities"}
            placeholder={"Select Amenities"}
            values={form.amenities}
            options={selectMultiColumns}
            handleChange={(e) => handleChange("amenities", e)}
          />
          <div className="row mt-3">
            <div className="col-md-12">
              <TextAreaField
                name={"description"}
                label={"Description"}
                placeholder={"Add your unit description here"}
                value={form.description}
                handler={(e) => handleChange("description", e.target.value)}
                rows={"10"}
                cols={"30"}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <MediaField
                label={"Upload Images"}
                Images={Images}
                setImages={setImages}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <Checkbox
                label={"This unit is active"}
                onChange={(e) => setIsUnitActive(e.target.checked)}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="notes-btn d-flex align-items-center justify-content-center gap-1 my-5">
                <button
                  className="cancel-prev-btn"
                  onClick={() => {
                    navigate(`/property-details-view?id=${id}`);
                  }}
                >
                  Cancel
                </button>
                <SubmitButton handler={AddUnit} isLoading={loader}>
                  Save
                </SubmitButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUnitDetails;
