import React from 'react'

const Stepper = ({currentStep}) => {
  return (
    <>
        <div className="stepper-container step-container-responsive">
          <div
            className={
              currentStep <= 1 || currentStep === 2 || currentStep === 3
                ? "step-1 account-info stepper-active"
                : "step-1 account-info stepper-inactive"
            }
          >
            1<div className="form-step-text">Property Info</div>
          </div>
          <div
            className={
              currentStep === 2 || currentStep === 3
                ? "step-2 property-info stepper-active"
                : "step-2 property-info stepper-inactive"
            }
          >
            2<div className="form-step-text">Account Info</div>
          </div>
          <div className="lines-account">
            <div
              className={`line ${currentStep === 2 || currentStep === 3 ? "active" : ""
                }`}
            ></div>
            <div className={`line ${currentStep === 3 ? "active" : ""}`}></div>
          </div>
        </div>
    </>
  )
}

export default Stepper
