// // import React, { useState } from "react";
// // import notificationIcon from "assets/bell-01.png";
// // import helpIcon from "assets/Help icon.png";
// // import { useNavigate, Link, NavLink } from "react-router-dom";
// // import unread from "assets/unread-icon.jpg";
// // import user from "assets/notification-img.png";
// // import "../../../hamburgers/dist/hamburgers.css";
// // import { motion } from "framer-motion";
// // import olivia from "assets/olivia.png";
// // import { useSelector } from "react-redux";

// // const MobileChatBody = () => {
  
// //   const notifications=useSelector(state=>state.notifications)
// //   console.log("notification ==> ",notifications);
// //   // States
// //   const [notifyData, setNotifyData] = useState(notifications);
// //   const [notifyDropdown, setNotifyDropdown] = useState(false);
// //   const navigate = useNavigate();
// //   return (
// //     <>
// //       <motion.div
// //         initial={{ transform: "translateX(100%)" }}
// //         animate={{ transform: "translateX(0)" }}
// //         exit={{ transform: "translateX(100%)", transition: ".2s all ease" }}
// //         className="mobile-chat-container"
// //       >
// //         <div className="mobile-chat-header">
// //           <div className="responsive-top-bar d-flex justify-content-between align-items-center  d-lg-flex d-xl-none ">
// //             <div className="page-title-box">
// //               <div className="top-heading-h1 d-flex align-items-center gap-4">
// //                 <h1 className="fw-bold top-bar-heading">
// //                   {" "}
// //                   <span
// //                     onClick={() => {
// //                       navigate("/user-chat");
// //                     }}
// //                     className="me-2 cursor"
// //                   >
// //                     <svg
// //                       width={30}
// //                       height={30}
// //                       fill="none"
// //                       stroke="currentColor"
// //                       strokeLinecap="round"
// //                       strokeLinejoin="round"
// //                       strokeWidth={2}
// //                       viewBox="0 0 24 24"
// //                       xmlns="http://www.w3.org/2000/svg"
// //                     >
// //                       <path d="m15 18-6-6 6-6" />
// //                     </svg>
// //                   </span>{" "}
// //                 </h1>
// //               </div>
// //             </div>
// //             <div className="page-title-box">
// //               <div className="top-heading-h1 d-flex align-items-center gap-4">
// //                 <h1 className="fw-bold top-bar-heading chat-heading"> Chat</h1>
// //               </div>
// //             </div>
// //             <div className="notification-box-container">
// //               <div className="dashboard-right-top-header d-flex align-items-center gap-2">
// //                 <div className="notify-icon me-3 position-relative cursor">
// //                   <img
// //                     onClick={() => {
// //                       setNotifyDropdown(!notifyDropdown);
// //                     }}
// //                     src={notificationIcon}
// //                     alt=""
// //                   />
// //                   <div
// //                     className={
// //                       notifyDropdown === true
// //                         ? "notification-dropdown-show"
// //                         : "notification-dropdown"
// //                     }
// //                   >
// //                     <div className="notification-dropdown-header d-flex justify-content-between align-items-center">
// //                       <p className="notification-left-heading">Notifications</p>
// //                       <p className="notification-right-heading">
// //                         {" "}
// //                         Mark all as read
// //                       </p>
// //                     </div>
// //                     <div className="notification-dropdown-second-header d-flex gap-3">
// //                       <p className="notification-left-second-heading">All</p>
// //                       <p className="notification-right-second-heading">
// //                         {" "}
// //                         Unread <span className="unread-count">01</span>{" "}
// //                       </p>
// //                     </div>
// //                     {notifyData.map((item, index) => {
// //                       return (
// //                         <div key={item._id}>
// //                           <div
// //                             className={
// //                               item.unread === true
// //                                 ? "notification-box-unread mt-4 d-flex gap-1 position-relative"
// //                                 : "notification-box mt-4 d-flex gap-1 position-relative"
// //                             }
// //                           >
// //                             <div className="notification-box-img d-flex gap-1">
// //                               {item.read === true ? (
// //                                 <img
// //                                   src={unread}
// //                                   alt=""
// //                                   className="object-fit-contain "
// //                                 />
// //                               ) : (
// //                                 ""
// //                               )}{" "}
// //                               <img
// //                                 className="object-fit-contain "
// //                                 src={item.profileImage}
// //                                 alt=""
// //                               />
// //                             </div>
// //                             <div className="notification-box-text">
// //                               <p className="mb-0 notification-text-one">
// //                                 {item.title}
// //                               </p>
// //                               <p className="mb-0 notification-text-second">
// //                                 {item.message}
// //                               </p>
// //                             </div>
// //                             <div
// //                               className="notification-box-time position-absolute top-0 "
// //                               style={{ right: "10px" }}
// //                             >
// //                               <span className="normal-grey-text">
// //                                 {item.create_at}
// //                               </span>
// //                             </div>
// //                           </div>
// //                         </div>
// //                       );
// //                     })}
// //                     <div className="see-all-notification-box text-center border-top p-2">
// //                       <Link to="/notification" className="text-dark fw-bold td">
// //                         See All Notifications
// //                       </Link>
// //                     </div>
// //                   </div>
// //                 </div>
// //                 <span className="help-icon">
// //                   <NavLink to="/help">
// //                     <img src={helpIcon} alt="" />
// //                   </NavLink>
// //                 </span>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //         <div className="mobile-chat-body bg-white">
// //           <div className="chat-body-container">
// //             <div className="chat-body mt-5">
// //               <div className="chat-message-body-outgoing d-flex justify-content-end align-items-center gap-3 mt-4">
// //                 <div className="message-time normal-grey-text fw-medium">
// //                   11:43
// //                 </div>
// //                 <div className="message-outgoing">
// //                   Hey Olivia. Can we get on a quick call?
// //                 </div>
// //                 <div className="message-img">
// //                   <img src={olivia} alt="" />
// //                 </div>
// //               </div>
// //               <div className="chat-message-body-incoming d-flex justify-content-start align-items-center gap-3 mt-4">
// //                 <div className="message-img">
// //                   <img src={olivia} alt="" />
// //                 </div>
// //                 <div className="message-incoming">
// //                   Hey Olivia. Can we get on a quick call?
// //                 </div>
// //                 <div className="message-time normal-grey-text fw-medium">
// //                   11:43
// //                 </div>
// //               </div>
// //               <div className="chat-message-body-outgoing d-flex justify-content-end align-items-center gap-3 mt-4">
// //                 <div className="message-time normal-grey-text fw-medium">
// //                   11:43
// //                 </div>
// //                 <div className="message-outgoing">
// //                   Hey Olivia. Can we get on a quick call?
// //                 </div>
// //                 <div className="message-img">
// //                   <img src={olivia} alt="" />
// //                 </div>
// //               </div>
// //               <div className="chat-message-body-incoming d-flex justify-content-start align-items-center gap-3 mt-4">
// //                 <div className="message-img">
// //                   <img src={olivia} alt="" />
// //                 </div>
// //                 <div className="message-incoming">
// //                   Hey Olivia. Can we get on a quick call?
// //                 </div>
// //                 <div className="message-time normal-grey-text fw-medium">
// //                   11:43
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //           <div className="chat-text-area border-top pt-4">
// //             <textarea
// //               placeholder="Type your message.... "
// //               name=""
// //               id=""
// //               cols="30"
// //               rows="10"
// //               className="send-message-text-area"
// //             ></textarea>
// //             <button className="save-btn-outline fw-bold primary-orange-text">
// //               Send
// //             </button>
// //           </div>
// //         </div>
// //       </motion.div>
// //     </>
// //   );
// // };

// // export default MobileChatBody;


// import React, { useState } from "react";
// import notificationIcon from "assets/bell-01.png";
// import helpIcon from "assets/Help icon.png";
// import { useNavigate, Link, NavLink } from "react-router-dom";
// import unread from "assets/unread-icon.jpg";
// import user from "assets/notification-img.png";
// import { motion } from "framer-motion";
// import olivia from "assets/olivia.png";
// import { useSelector } from "react-redux";

// const MobileChatBody = () => {
//   const notifications = useSelector((state) => state.notifications) || []; // Ensure notifications fallback
//   console.log("notification ==> ", notifications);

//   // States
//   const [notifyDropdown, setNotifyDropdown] = useState(false);
//   const navigate = useNavigate();

//   return (
//     <motion.div
//       initial={{ transform: "translateX(100%)" }}
//       animate={{ transform: "translateX(0)" }}
//       exit={{ transform: "translateX(100%)", transition: ".2s all ease" }}
//       className="mobile-chat-container"
//     >
//       <div className="mobile-chat-header">
//         <div className="responsive-top-bar d-flex justify-content-between align-items-center">
//           <div className="page-title-box">
//             <h1 className="fw-bold top-bar-heading">
//               <span
//                 onClick={() => navigate("/user-chat")}
//                 className="me-2 cursor"
//               >
//                 <svg
//                   width={30}
//                   height={30}
//                   fill="none"
//                   stroke="currentColor"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   viewBox="0 0 24 24"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path d="m15 18-6-6 6-6" />
//                 </svg>
//               </span>
//             </h1>
//           </div>
//           <div className="page-title-box">
//             <h1 className="fw-bold top-bar-heading chat-heading">Chat</h1>
//           </div>
//           <div className="notification-box-container">
//             <div className="dashboard-right-top-header d-flex align-items-center gap-2">
//               <div className="notify-icon me-3 position-relative cursor">
//                 <img
//                   onClick={() => setNotifyDropdown(!notifyDropdown)}
//                   src={notificationIcon}
//                   alt="notification"
//                 />
//                 <div
//                   className={
//                     notifyDropdown
//                       ? "notification-dropdown-show"
//                       : "notification-dropdown"
//                   }
//                 >
//                   <div className="notification-dropdown-header d-flex justify-content-between align-items-center">
//                     <p className="notification-left-heading">Notifications</p>
//                     <p className="notification-right-heading">Mark all as read</p>
//                   </div>
//                   <div className="notification-dropdown-second-header d-flex gap-3">
//                     <p className="notification-left-second-heading">All</p>
//                     <p className="notification-right-second-heading">
//                       Unread <span className="unread-count">01</span>
//                     </p>
//                   </div>
//                   {notifications.length > 0 ? (
//                     notifications.map((item) => (
//                       <div key={item._id}>
//                         <div
//                           className={`${
//                             item.unread ? "notification-box-unread" : "notification-box"
//                           } mt-4 d-flex gap-1 position-relative`}
//                         >
//                           <div className="notification-box-img d-flex gap-1">
//                             {item.unread && <img src={unread} alt="unread" />}
//                             <img src={item.profileImage || user} alt="profile" />
//                           </div>
//                           <div className="notification-box-text">
//                             <p className="mb-0 notification-text-one">{item.title}</p>
//                             <p className="mb-0 notification-text-second">{item.message}</p>
//                           </div>
//                           <div className="notification-box-time position-absolute top-0" style={{ right: "10px" }}>
//                             <span className="normal-grey-text">{item.create_at}</span>
//                           </div>
//                         </div>
//                       </div>
//                     ))
//                   ) : (
//                     <p>No notifications available</p>
//                   )}
//                   <div className="see-all-notification-box text-center border-top p-2">
//                     <Link to="/notification" className="text-dark fw-bold td">
//                       See All Notifications
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//               <span className="help-icon">
//                 <NavLink to="/help">
//                   <img src={helpIcon} alt="help" />
//                 </NavLink>
//               </span>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Chat Body */}
//       <div className="mobile-chat-body bg-white">
//         <ChatMessages />
//         <div className="chat-text-area border-top pt-4">
//           <textarea
//             placeholder="Type your message.... "
//             className="send-message-text-area"
//           ></textarea>
//           <button className="save-btn-outline fw-bold primary-orange-text">
//             Send
//           </button>
//         </div>
//       </div>
//     </motion.div>
//   );
// };

// const ChatMessages = () => {
//   const messages = [
//     {
//       time: "11:43",
//       type: "outgoing",
//       content: "Hey Olivia. Can we get on a quick call?",
//       img: olivia,
//     },
//     {
//       time: "11:43",
//       type: "incoming",
//       content: "Sure, let’s do it!",
//       img: olivia,
//     },
//   ];

//   return (
//     <div className="chat-body-container">
//       {messages.map((msg, index) => (
//         <div
//           key={index}
//           className={`chat-message-body-${msg.type} d-flex justify-content-${
//             msg.type === "outgoing" ? "end" : "start"
//           } align-items-center gap-3 mt-4`}
//         >
//           {msg.type === "incoming" && (
//             <div className="message-img">
//               <img src={msg.img} alt="profile" />
//             </div>
//           )}
//           <div className={`message-${msg.type}`}>{msg.content}</div>
//           <div className="message-time normal-grey-text fw-medium">{msg.time}</div>
//           {msg.type === "outgoing" && (
//             <div className="message-img">
//               <img src={msg.img} alt="profile" />
//             </div>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default MobileChatBody;

import React, { useState, useEffect } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";
import moment from "moment";

// Assets
import notificationIcon from "assets/bell-01.png";
import helpIcon from "assets/Help icon.png";
import unread from "assets/unread-icon.jpg";
import user from "assets/notification-img.png";
import olivia from "assets/olivia.png";
import active_dot from "assets/_Dot.png";
import offline_dot from "assets/_red-Dot.png";
import settingIcon from "assets/three-dots.png";
import deleteIcon from "assets/trash-01 - Orange.png";

// Styles
import "../../../hamburgers/dist/hamburgers.css";

// Custom Hooks
import UseGetHook from "Hooks/UseGetHook";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import UseUpdateHook from "Hooks/UseUpdateHook";

// Components
import DeleteModal from "Modals/DeleteModal/DeleteModal";

const MobileChatBody = ({archive}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // URL Parameters
  const { id } = UseUrlParamsHook();

  // Redux State
  const socket = useSelector((state) => state.Chat.socket);
  const allConversation = useSelector((state) => state.Chat.conversation);
  const online = useSelector((state) => state.Chat.online);
  const notifications = useSelector((state) => state.notifications) || [];

  // Custom Hook for Fetching Messages
  const {
    FetchLandlordMessage,
    FetchTenantMessage,
    FetchCoversationTenant,
    FetchCoversationLandlord,
    messages,
    setmessage,
    setconvo,
  } = UseGetHook("messages", id);

  // Local States
  const [notifyDropdown, setNotifyDropdown] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);

  // Determine User Role
  const userRole = localStorage.getItem("role");

  // Determine Current Conversation
  const tenant = allConversation.find((e) => e.id === id);

  // Determine Last Seen Message
  const seen = messages.length > 0 ? messages[messages.length - 1]._id : null;

  // Fetch Messages and Conversations on Mount
  useEffect(() => {
    if (socket && id) {
      socket.emit("lastReadMessage", { id: id });
    }
    if (userRole === "landlord") {
      FetchLandlordMessage();
      FetchCoversationLandlord();
    } else {
      FetchTenantMessage();
      FetchCoversationTenant();
    }
  }, [id, socket, userRole]);

  // Handle Socket Events
  useEffect(() => {
    if (socket) {
      socket.emit("join", { id: id });

      // Listen for incoming chat messages
      socket.on("chatMessage", (data) => {
        data._id = data.id; // Ensure consistency in ID
        setmessage((prevMessage) => [...prevMessage, data]);
        socket.emit("readMessage", {
          send_by_landlord: data.send_by_landlord,
          messageId: data.id,
          id: id,
        });
      });

      // Listen for read receipts
      socket.on("readMessage", (data) => {
        setmessage((prevMessages) =>
          prevMessages.map((msg) =>
            msg._id === data.id ? { ...msg, unread: false } : msg
          )
        );
      });
    }

    // Cleanup on unmount
    return () => {
      socket?.off("chatMessage");
      socket?.off("readMessage");
    };
  }, [socket, id]);

  // Handle Dropdown Toggle
  const handleIconClickCustom = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  // Send Message Function
  const SendMessage = () => {
    if (textMessage.trim() === "") return; // Prevent sending empty messages
    socket.emit("chatMessage", {
      content: textMessage,
      conversation: id,
      role: userRole,
    });
    setTextMessage("");
  };

  // Handle Navigation on Close
  const onClose = () => {
    if (!archive) {
      navigate("/archive-user");
    } else {
      navigate("/user-chat");
    }
  };

  // Chat CRUD Operations
  const archiveChat = () => {
    UseUpdateHook("conversation/archive", id, "", onClose, userRole);
  };

  const deleteChat = () => {
    setOpenModal((prevState) => !prevState);
  };

  // Determine Online Status
  const isOnline =
    tenant?.tenant?.id === online?.id || tenant?.landlord?.id === online?.id
      ? online?.status
      : false;

  // Determine Profile Image
  const profileImage =
    userRole === "landlord"
      ? tenant?.tenant?.profileImage
      : tenant?.landlord?.profileImage;

  return (
    <>
      {/* Delete Modal */}
      {openModal && (
        <DeleteModal
          onClose={deleteChat}
          component={`${userRole}/conversation`}
          route={!archive ? "user-chat" : "archive-user"}
          deleteBtnText="Delete Chat"
          delId={id}
        />
      )}

      {/* Notification Dropdown */}
      <motion.div
        initial={{ transform: "translateX(100%)" }}
        animate={{ transform: "translateX(0)" }}
        exit={{ transform: "translateX(100%)", transition: ".2s all ease" }}
        className="mobile-chat-container"
      >
        {/* Header */}
        <div className="mobile-chat-header">
          <div className="responsive-top-bar d-flex justify-content-between align-items-center">
            {/* Back Button */}
            <div className="page-title-box">
              <h1 className="fw-bold top-bar-heading">
                <span
                  onClick={() => navigate("/user-chat")}
                  className="me-2 cursor"
                >
                  <svg
                    width={30}
                    height={30}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m15 18-6-6 6-6" />
                  </svg>
                </span>
              </h1>
            </div>

            {/* Chat Title */}
            <div className="page-title-box">
              <h1 className="fw-bold top-bar-heading chat-heading">Chat</h1>
            </div>

            {/* Notification and Help Icons */}
            <div className="notification-box-container">
              <div className="dashboard-right-top-header d-flex align-items-center gap-2">
                {/* Notification Icon */}
                <div className="notify-icon me-3 position-relative cursor">
                  <img
                    onClick={() => setNotifyDropdown(!notifyDropdown)}
                    src={notificationIcon}
                    alt="notification"
                  />
                  {notifyDropdown && (
                    <div className="notification-dropdown-show">
                      <div className="notification-dropdown-header d-flex justify-content-between align-items-center">
                        <p className="notification-left-heading">Notifications</p>
                        <p className="notification-right-heading">
                          Mark all as read
                        </p>
                      </div>
                      <div className="notification-dropdown-second-header d-flex gap-3">
                        <p className="notification-left-second-heading">All</p>
                        <p className="notification-right-second-heading">
                          Unread <span className="unread-count">01</span>
                        </p>
                      </div>
                      {notifications.length > 0 ? (
                        notifications.map((item) => (
                          <div key={item._id}>
                            <div
                              className={`${
                                item.unread
                                  ? "notification-box-unread"
                                  : "notification-box"
                              } mt-4 d-flex gap-1 position-relative`}
                            >
                              <div className="notification-box-img d-flex gap-1">
                                {item.unread && (
                                  <img src={unread} alt="unread" />
                                )}
                                <img
                                  src={item.profileImage || user}
                                  alt="profile"
                                />
                              </div>
                              <div className="notification-box-text">
                                <p className="mb-0 notification-text-one">
                                  {item.title}
                                </p>
                                <p className="mb-0 notification-text-second">
                                  {item.message}
                                </p>
                              </div>
                              <div
                                className="notification-box-time position-absolute top-0"
                                style={{ right: "10px" }}
                              >
                                <span className="normal-grey-text">
                                  {item.create_at}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>No notifications available</p>
                      )}
                      <div className="see-all-notification-box text-center border-top p-2">
                        <Link to="/notification" className="text-dark fw-bold td">
                          See All Notifications
                        </Link>
                      </div>
                    </div>
                  )}
                </div>

                {/* Help Icon */}
                <span className="help-icon">
                  <NavLink to="/help">
                    <img src={helpIcon} alt="help" />
                  </NavLink>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Chat Header */}
        <div className="chat-body-header d-flex justify-content-between align-items-center border-bottom flex-grow-1">
          <div className="chat-body-header-left d-flex align-items-center justify-content-center gap-3">
            <div className="chat-body-header-img">
              <img
                className="property-table-image mw_40 mh_40 me-2 rounded-5"
                src={profileImage || olivia}
                alt="profile"
              />
              {/* Additional Text if Needed */}
            </div>
            <div className="chat-body-header-text">
              <p className="mb-0 text-dark fw-medium">
                {userRole === "landlord"
                  ? tenant?.tenant?.firstName
                  : tenant?.landlord?.firstName}
              </p>
              {tenant && (
                <p
                  className={
                    "mb-0 " +
                    (!archive
                      ? isOnline
                        ? "online-text"
                        : "offline-text"
                      : "d-none")
                  }
                >
                  <img
                    src={isOnline ? active_dot : offline_dot}
                    alt="status"
                  />{" "}
                  {isOnline ? "Online" : "Offline"}
                </p>
              )}
            </div>
          </div>
          <div className="chat-body-header-right">
            <div className="setting-icon me-4">
              <img
                onClick={handleIconClickCustom}
                src={settingIcon}
                alt="settings"
                className="cursor"
              />
              {isDropdownOpen && (
                <div className="task-table-setting-dropdown-prospect bg-white box-shadow text-start">
                  <ul className="p-0 d-flex flex-column gap-3">
                    <li
                      onClick={archiveChat}
                      className="list-style-none cursor lease-details-dropdown-icons"
                    >
                      <span className="me-2">
                        <svg
                          width={21}
                          height={21}
                          fill="#98A2B3"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M21 6a3 3 0 0 0-3-3H6a3 3 0 0 0-2 5.22V18a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8.22A3 3 0 0 0 21 6ZM6 5h12a1 1 0 1 1 0 2H6a1 1 0 0 1 0-2Zm12 13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V9h12v9Z" />
                          <path d="M14.13 12H9.87a.87.87 0 0 0-.87.87v.26c0 .48.39.87.87.87h4.26c.48 0 .87-.39.87-.87v-.26a.87.87 0 0 0-.87-.87Z" />
                        </svg>
                      </span>
                      {!archive ? "Archive" : "Restore"}
                    </li>
                    <li
                      onClick={deleteChat}
                      className="list-style-none cursor lease-details-dropdown-icons"
                    >
                      <img src={deleteIcon} className="me-2" alt="delete" /> Delete
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Chat Body */}
        <div className="mobile-chat-body bg-white">
          <div className="chat-body-container">
            <div className="chat-body mt-5">
              {messages && messages.length > 0 ? (
                messages.map((data) => (
                  <div key={data._id}>
                    {userRole === "landlord" ? (
                      data.send_by_landlord === false ? (
                        // Landlord (Incoming Message)
                        <div className="chat-message-body-outgoing d-flex justify-content-start align-items-center gap-3 mt-3">
                          <div className="message-img">
                            <img src={olivia} alt="profile" />
                          </div>
                          <div className="position-relative">
                            <div className="message-incoming mt-3">
                              {data.text}
                              <div className="message-time normal-grey-text fw-medium">
                                {moment(data.timestamp).format("h:mm a").toUpperCase()}
                              </div>
                            </div>
                            {data._id === seen && !data.unread && (
                              <div className="seen-img position-absolute end-0">
                                <span className="normal-grey-text seen-text">Seen</span>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        // Tenant (Outgoing Message)
                        <div className="chat-message-body-incoming d-flex justify-content-end align-items-center gap-3 mt-3">
                          <div className="position-relative">
                            <div className="message-incoming">
                              {data.text}
                              <div className="message-time normal-grey-text fw-medium">
                                {moment(data.timestamp).format("h:mm a").toUpperCase()}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    ) : data.send_by_landlord === false ? (
                      // Tenant (Outgoing Message)
                      <div className="chat-message-body-outgoing d-flex justify-content-end align-items-center gap-3 mt-3">
                        <div className="position-relative">
                          <div className="message-incoming mt-3">
                            {data.text}
                            <div className="message-time normal-grey-text fw-medium">
                              {moment(data.timestamp).format("h:mm a").toUpperCase()}
                            </div>
                          </div>
                          {data._id === seen && !data.unread && (
                            <div className="seen-img position-absolute end-0">
                              <span className="normal-grey-text seen-text">Seen</span>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      // Landlord (Incoming Message)
                      <div className="chat-message-body-incoming d-flex justify-content-start align-items-center gap-3 mt-3">
                        <div className="message-img">
                          <img src={olivia} alt="profile" />
                        </div>
                        <div className="position-relative">
                          <div className="message-incoming">
                            {data.text}
                            <div className="message-time normal-grey-text fw-medium">
                              {moment(data.timestamp).format("h:mm a").toUpperCase()}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p className="text-center mt-4">No messages yet.</p>
              )}
            </div>
          </div>

          {/* Message Input Area */}
          {!archive && (
            <div className="chat-text-area border-top pt-4">
              <textarea
                onKeyPress={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    SendMessage();
                  }
                }}
                onChange={(e) => setTextMessage(e.target.value)}
                value={textMessage}
                placeholder="Type your message.... "
                className="send-message-text-area"
              ></textarea>
              <button
                onClick={SendMessage}
                className="save-btn-outline fw-bold primary-orange-text"
              >
                Send
              </button>
            </div>
          )}
        </div>
      </motion.div>
    </>
  );
};

export default MobileChatBody;
