import React from "react";
import { LockOutlined } from "@ant-design/icons";
import "style/LockWrapper.css";
import cancelIcon from "assets/x-circle.png";
import alertIcon from "assets/alert-circle.png";

const NoAccessPage = () => {
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="no-access" style={{ width: "100%", height: "calc(100vh - 125px)" }}>
          <div className="vendor-success-modal no-access-modal">
            <div className="row">
              <div className="col-md-12 text-center">
                <img src={alertIcon} alt="" />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12 text-center">
                <p className="normal-grey-text convert-tenant-modal-text">
                  You don't have permission to access this page. Please contact
                  your administrator.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoAccessPage;
