import React, { useEffect } from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import SideDrawer from "Components/SideDrawer/SideDrawer";
import PropertiesDashboard from "Components/PropertiesDashboard/PropertiesDashboard";
import ResponsiveSideBar from "Components/ResponsiveSideBar/ResponsiveSideBar";
import { useSelector } from "react-redux";
import UseGetHook from "Hooks/UseGetHook";
import NoAccessPage from "Pages/NoAccessMainPage/NoAccessPage";

const PropertiesDashboardPage = () => {
  // const { FetchUser, user, FetchUserTenant, FetchUserLandlordUser } =
  //   UseGetHook("userinfo");
  // useEffect(() => {
  //   FetchUser();
  //   FetchUserTenant();
  //   FetchUserLandlordUser();
  // }, []);
  // const myUserRoles = user[0]?.roles?.map((e) => e.role);
  const storedRoles = localStorage.getItem("myUserRoles");
  const myUserRoles =
    storedRoles !== "undefined" ? JSON.parse(storedRoles) : [];
  console.log("retrievedArray ------======>", myUserRoles, myUserRoles?.length);
  console.log(
    "Check if user has access to this page",
    ![
      "Owner/Executive",
      "Leasing Agent",
      "Maintenance Technician",
      "Property Manager",
    ].some((role) => myUserRoles?.includes(role)) || myUserRoles?.length !== 0
  );

  const drawer = useSelector((state) => {
    return state.sideDrawer.drawerState;
  });
  return (
    <>
      <div className="container-fluid">
        <div className="row hv-100">
          <div className="col-xl-2 p-sm-0 d-md-none d-lg-none d-xl-block d-none position-relative drawer-bg-primary">
            <SideDrawer />
          </div>
          <div
            className={
              drawer === true ? "res-drawer-bg-open" : "res-drawer-bg-close"
            }
          >
            <div
              className={
                drawer === true
                  ? "drawer-bg-primary responsive-drawer-container-open"
                  : "drawer-bg-primary responsive-drawer-container "
              }
            >
              <ResponsiveSideBar />
            </div>
          </div>
          <div className="col-xl-10 d-lg-10 d-lg-block mt-3 d-xl-block">
            <div className="right-component bg-white h-100">
              <div className="container-fluid bg-default">
                <TitleTopBar topBarTitle={"Properties"} />
              </div>
              {![
                "Owner/Executive",
                "Leasing Agent",
                "Maintenance Technician",
                "Property Manager",
              ].some((role) => myUserRoles?.includes(role)) &&
              myUserRoles?.length !== 0 ? (
                <NoAccessPage />
              ) : (
                <PropertiesDashboard />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertiesDashboardPage;
