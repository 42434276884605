import { calculateAge } from "utils";

const validateLandlordSignIn = (form) => {
  const newErrors = {};
  // email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (form.email.trim() === "") {
    newErrors.email = "Email is required";
  } else if (!emailRegex.test(form.email)) {
    newErrors.email = "Please enter a valid email address";
  }

  // Password validation

  if (form.password === "") {
    newErrors.password = "Password is required";
  }

  return newErrors;
};

const LandlorSignupvalidationforom = (form) => {
  const newErrors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const fields = {
    Firstname: "First Name",
    Lastname: "Last Name",
    Email: "Email",
    Password: "Password",
    ConfirmPassword: "Confirm Password",
    Phone: "Phone Number",
    PhoneType: "Phone Type ",
    City: "City",
    State: "State",
    Zipcode: "Zip code",
    Country: "USA",
    BusinessName: "Bussines Name",
    Address: "Address",
  };

  for (const key in form) {
    if (form[key] === "") {
      newErrors[key] = `${fields[key]} is required`;
      console.log("My Error", newErrors);
    }
  }

  if (form.Email.trim() === "") {
    newErrors.Email = "Email is required";
  } else if (!emailRegex.test(form.Email)) {
    newErrors.Email = "Please enter a valid Email address";
  }

  const checkPasswordCriteria = (password) => {
    const hasMinimumLength = /.{8,}/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasSpecialCharacter = /[!@#$%^&*()_+={}\\[\]:;,<.>?/\\~-]/.test(
      password
    );

    return {
      hasMinimumLength,
      hasNumber,
      hasUpperCase,
      hasSpecialCharacter,
    };
  };
  checkPasswordCriteria(form.Password);

  if (form.Password === "") {
    newErrors.Password = "Password is required";
  } else {
    if (!checkPasswordCriteria(form.Password).hasMinimumLength) {
      newErrors.Password = "Password must be at least 8 characters";
    }
    if (!checkPasswordCriteria(form.Password).hasNumber) {
      newErrors.Password = "Password must contain a number";
    }
    if (!checkPasswordCriteria(form.Password).hasUpperCase) {
      newErrors.Password = "Password must contain an uppercase letter";
    }
    if (!checkPasswordCriteria(form.Password).hasSpecialCharacter) {
      newErrors.Password = "Password must contain a special character";
    }
  }

  if (form.ConfirmPassword) {
    if (form.Password !== form.ConfirmPassword) {
      newErrors.ConfirmPassword = "Password does not match";
    }
  }

  if (form.Phone && form.Phone.length !== 10) {
    newErrors.Phone = "Phone number must be 10 digits";
  }
  if (form.Zipcode && form.Zipcode.length !== 5) {
    newErrors.Zipcode = "Zip Code must be 05 digits";
  }

  return newErrors;
};

const TenantSignUpValidation = (form) => {
  const newErrors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  function isRequiredField(key) {
    const requiredFields = [
      "fname",
      "lname",
      "email",
      "password",
      "cpassword",
      "phone",
    ];
    return requiredFields.includes(key);
  }

  const fields = {
    fname: "First Name",
    lname: "Last Name",
    email: "Email",
    password: "Password",
    cpassword: "Confirm Password",
    phone: "Phone Number",
  };

  for (const key in form) {
    if (form[key] === "") {
      if (isRequiredField(key)) {
        newErrors[key] = `${fields[key]} is required`;
      }
    }
  }

  if (form.email.trim() === "") {
    newErrors.email = "Email is required";
  } else if (!emailRegex.test(form.email)) {
    newErrors.email = "Please enter a valid Email address";
  }

  const checkPasswordCriteria = (password) => {
    const hasMinimumLength = /.{8,}/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasSpecialCharacter = /[!@#$%^&*()_+={}\\[\]:;,<.>?/\\~-]/.test(
      password
    );

    return {
      hasMinimumLength,
      hasNumber,
      hasUpperCase,
      hasSpecialCharacter,
    };
  };
  checkPasswordCriteria(form.password);

  if (form.password === "") {
    newErrors.password = "Password is required";
  } else {
    if (!checkPasswordCriteria(form.password).hasMinimumLength) {
      newErrors.password = "Password must be at least 8 characters";
    }
    if (!checkPasswordCriteria(form.password).hasNumber) {
      newErrors.password = "Password must contain a number";
    }
    if (!checkPasswordCriteria(form.password).hasUpperCase) {
      newErrors.password = "Password must contain an uppercase letter";
    }
    if (!checkPasswordCriteria(form.password).hasSpecialCharacter) {
      newErrors.password = "Password must contain a special character";
    }
  }

  if (form.cpassword) {
    if (form.password !== form.cpassword) {
      newErrors.cpassword = "Password does not match";
    }
  }

  if (form.phone && form.phone.length !== 10) {
    newErrors.phone = "Phone number must be 10 digits";
  }

  return newErrors;
};

// ServiceProfessionalSignUp
// const [form, setForm] = useState({
//   fname: "",
//   lname: "",
//   email: "",
//   password: "",
//   cpassword: "",
//   phone: "",
// });

const validateServiceProfessionalSignUp = (form) => {
  const newErrors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  function isRequiredField(key) {
    const requiredFields = [
      "fname",
      "lname",
      "email",
      "password",
      "cpassword",
      "phone",
    ];
    return requiredFields.includes(key);
  }

  const fields = {
    fname: "First Name",
    lname: "Last Name",
    email: "Email",
    password: "Password",
    cpassword: "Confirm Password",
    phone: "Phone Number",
  };

  for (const key in form) {
    if (form[key] === "") {
      if (isRequiredField(key)) {
        newErrors[key] = `${fields[key]} is required`;
      }
    }
  }

  if (form.email.trim() === "") {
    newErrors.email = "Email is required";
  } else if (!emailRegex.test(form.email)) {
    newErrors.email = "Please enter a valid Email address";
  }

  const checkPasswordCriteria = (password) => {
    const hasMinimumLength = /.{8,}/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);

    return {
      hasMinimumLength,
      hasNumber,
      hasUpperCase,
    };
  };
  checkPasswordCriteria(form.password);

  if (form.password === "") {
    newErrors.password = "Password is required";
  } else {
    if (!checkPasswordCriteria(form.password).hasMinimumLength) {
      newErrors.password = "Password must be at least 8 characters";
    }
    if (!checkPasswordCriteria(form.password).hasNumber) {
      newErrors.password = "Password must contain a number";
    }
    if (!checkPasswordCriteria(form.password).hasUpperCase) {
      newErrors.password = "Password must contain an uppercase letter";
    }
  }

  if (form.cpassword) {
    if (form.password !== form.cpassword) {
      newErrors.cpassword = "Password does not match";
    }
  }

  if (form.phone && form.phone.length !== 10) {
    newErrors.phone = "Phone number must be 10 digits";
  }

  return newErrors;
};

const validateLandlordUserInfo = (form) => {
  const newErrors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  function isRequiredField(key) {
    const requiredFields = [
      "fName",
      "lName",
      "email",
      "phone",
      "address",
      "city",
      "state",
      "zipcode",
      "phoneType",
    ];
    return requiredFields.includes(key);
  }

  const fields = {
    fName: "First Name",
    mName: "Middle Name",
    lName: "Last Name",
    phone: "Phone Number",
    email: "Email",
    address: "Address",
    city: "City",
    state: "State",
    zipcode: "Zip Code",
    phoneType: "Phone Type",
  };

  for (const key in form) {
    if (form[key] === "") {
      if (isRequiredField(key)) {
        newErrors[key] = `${fields[key]} is required`;
      }
    }
  }

  if (form.email.trim() === "") {
    newErrors.email = "Email is required";
  }

  if (!emailRegex.test(form.email)) {
    newErrors.email = "Please enter a valid Email address";
  }

  if (form.phone && form.phone.length !== 10) {
    newErrors.phone = "Phone number must be 10 digits";
  }

  if (form.zipcode && form.zipcode.length !== 5) {
    newErrors.zipcode = "Zip Code must be 05 digits";
  }

  return newErrors;
};

// const [form, setForm] = useState({
//   firstName: "",
//   middleName: "",
//   lastName: "",
//   email: "",
//   phone: "",
//   address: "",
//   city: "",
//   state: "",
//   zipcode: "",
//   role: "",
// });

const validateLandlordAddUserInfo = (form) => {
  const newErrors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  console.log("My Form", form);

  function isRequiredField(key) {
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "phone",
      "address",
      "city",
      "state",
      "roles",
    ];
    return requiredFields.includes(key);
  }

  const fields = {
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone Number",
    address: "Address",
    city: "City",
    state: "State",
    zipcode: "Zip Code",
    roles: "Roles",
  };

  for (const key in form) {
    if (form[key] === "") {
      if (isRequiredField(key)) {
        newErrors[key] = `${fields[key]} is required`;
      }
    }
  }

  if (form.roles.length === 0) {
    console.log("Role is required");
    newErrors["roles"] = "Role is required";
  }

  if (form.email.trim() === "") {
    newErrors.email = "Email is required";
  }

  if (!emailRegex.test(form.email)) {
    newErrors.email = "Please enter a valid Email address";
  }

  if (form.phone && form.phone.length !== 10) {
    newErrors.phone = "Phone number must be 10 digits";
  }

  if (form.zipcode && form.zipcode.length !== 5) {
    newErrors.zipcode = "Zip Code must be 05 digits";
  }

  return newErrors;
};

const validateProspectPersonalDetails = (form) => {
  function isRequiredField(key) {
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "phone",
      "dob",
      "addressLine_1",
      "zipCode",
      "state",
      "country",
      "city",
      "phoneType",
      "property",
      "unit",
    ];
    return requiredFields.includes(key);
  }

  const fieldsMap = {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone",
    dob: "Date of Birth",
    addressLine_1: "Address Line 1",
    zipCode: "Zip Code",
    state: "State",
    country: "Country",
    city: "City",
    phoneType: "Phone Type",
    property: "Property",
    unit: "Unit",
  };

  const newErrors = {};
  for (const key in form) {
    if (form[key] === "") {
      // Check if the field is required
      if (isRequiredField(key)) {
        newErrors[key] = `${fieldsMap[key]} is required`;
      }
    }
  }

  if (form.phone && form.phone.length !== 10) {
    newErrors.phone = "Phone number must be 10 digits";
  }
  if (form.zipCode && form.zipCode.length !== 5) {
    newErrors.zipCode = "Zip Code must be 05 digits";
  }
  if (form.dob) {
    const age = calculateAge(form.dob);
    if (age < 18) {
      newErrors.dob = "You must be at least 18 years old.";
    }
  }

  return newErrors;
};

const validatePropertyDetails = (form, choosePropertyTypeCommercial) => {
  function isRequiredField(key) {
    const requiredFields = [
      "title",
      "status",
      "bedroom",
      "bathroom",
      "area",
      "rent",
      "address",
      "country",
      "city",
      "state",
      "zipCode",
    ];
    return requiredFields.includes(key);
  }

  const fieldsMap = {
    title: "Title",
    status: "Status",
    bedroom: "Bedroom",
    bathroom: "Bathroom",
    area: "Area",
    rent: "Rent",
    address: "Address",
    country: "Country",
    city: "City",
    state: "State",
    zipCode: "Zip Code",
  };

  const newErrors = {};

  for (const key in form) {
    if (
      choosePropertyTypeCommercial &&
      (key === "bedroom" || key === "bathroom")
    ) {
      continue;
    }
    if (form[key] === "") {
      if (isRequiredField(key)) {
        newErrors[key] = `${fieldsMap[key]} is required`;
      }
    }
  }

  if (form.zipCode && form.zipCode.length !== 5) {
    newErrors.zipCode = "Zip Code must be 05 digits";
  }

  return newErrors;
};

const validateUnitInfo = (form) => {
  function isRequiredField(key) {
    const requiredFields = ["propertyUnit"];
    return requiredFields.includes(key);
  }

  const fieldsMap = {
    propertyUnit: "Property Unit",
  };

  const newErrors = {};

  for (const key in form) {
    if (form[key] === "") {
      if (isRequiredField(key)) {
        newErrors[key] = `${fieldsMap[key]} is required`;
      }
    }
  }

  return newErrors;
};

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (email.trim() === "") {
    return { email: "Please fill in the required email field to continue" };
  } else if (!emailRegex.test(email)) {
    return { email: "Please enter a valid email address" };
  }
  return {};
};

const validateTenantPersonalDetails = (form) => {
  function isRequiredField(key) {
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "phone",
      "dob",
      "addressLine_1",
      "zipCode",
      "state",
      "country",
      "city",
      "phoneType",
    ];
    return requiredFields.includes(key);
  }

  const fieldsMap = {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone",
    dob: "Date of Birth",
    addressLine_1: "Address Line 1",
    zipCode: "Zip Code",
    state: "State",
    country: "Country",
    city: "City",
    phoneType: "Phone Type",
  };
  const newErrors = {};
  for (const key in form) {
    if (form[key] === "") {
      if (isRequiredField(key)) {
        newErrors[key] = `${fieldsMap[key]} is required`;
      }
    }
  }

  if (form.phone && form.phone.length !== 10) {
    newErrors.phone = "Phone number must be 10 digits";
  }
  if (form.zipCode && form.zipCode.length !== 5) {
    newErrors.zipCode = "Zip Code must be 05 digits";
  }
  if (form.dob) {
    const age = calculateAge(form.dob);
    if (age < 18) {
      newErrors.dob = "You must be at least 18 years old.";
    }
  }

  return newErrors;
};

const validateTenantFileForm = (form, category) => {
  const newErrors = {};
  if (form.files.length === 0) {
    newErrors.files = "File is required";
  }
  if (!form.file_name || form.file_name === "") {
    newErrors.file_name = "File Name is required";
  }
  if (
    category === "tenant" &&
    (!form.relatedToProperty || form.relatedToProperty === "")
  ) {
    newErrors.relatedToProperty = "Property is required";
  }
  return newErrors;
};

const validateNoteForm = (form) => {
  const mapErrors = {
    note_name: "Note Name",
    description: "Description",
  };

  const newErrors = {};
  for (const key in form) {
    if (form[key] === "") {
      newErrors[key] = `${mapErrors[key]} is required`;
    }
  }

  return newErrors;
};

const validateLeaseInfoForm = (form) => {
  function isRequiredField(key) {
    const requiredFields = [
      "property",
      "unit",
      "lease_term",
      "lease_term_start_date",
      "lease_term_end_date",
      "first_rent_date",
      "frequency",
      "rent_amount",
    ];

    if (form.lease_term === "Month-to-Month") {
      console.log("done");
      return requiredFields.includes(key) && key !== "lease_term_end_date";
    }

    return requiredFields.includes(key);
  }

  const fieldsMap = {
    property: "Property",
    unit: "Unit",
    lease_term: "Lease Term",
    lease_term_start_date: "Lease Term Start Date",
    lease_term_end_date: "Lease Term End Date",
    first_rent_date: "First Rent Date",
    frequency: "Frequency",
    rent_amount: "Rent Amount",
    security_deposit: "Security Deposit",
    security_amount: "Security Amount",
    late_fee: "Late Fee",
    late_fees_amount: "Late Fee Amount",
  };

  const newErrors = {};
  for (const key in form) {
    if (key === "property") {
      if (form.property.id === "") {
        newErrors[key] = `${fieldsMap[key]} is required`;
      }
    }
    if (key === "rent_amount" && form[key].amount === "") {
      newErrors[key] = `${fieldsMap[key]} is required`;
    } else if (form[key] === "") {
      if (isRequiredField(key)) {
        newErrors[key] = `${fieldsMap[key]} is required`;
      }
    }
  }

  return newErrors;
};

const validateLeaseTenantsForm = (form) => {
  const newErrors = {};
  for (const key in form) {
    if (key === "tenant") {
      if (form[key]._id === "") {
        newErrors[key] = `Tenant is required`;
      }
    } else if (key === "coTenants") {
      if (form[key].length > 0) {
        for (let i = 0; i < form[key].length; i++) {
          if (form[key][i]._id === "") {
            newErrors[`coTenant${i}`] = `Co-Tenant is required`;
          }
        }
      }
    }
  }

  console.log("My Tanant Errors >>>>>", newErrors);

  return newErrors;
};

const validateLeaseDocumentForm = (form, files) => {
  // if isSigned is No, then newLeaseSign should be required
  const newErrors = {};

  if (form.isSigned === "") {
    newErrors.isSigned = "Please select an option";
  }

  if (form.isSigned === "No" && form.newLeaseSign === "") {
    newErrors.newLeaseSign = "Please select an option";
  }

  if (files.length === 0 && form.isSigned === "No" && form.newLeaseSign === "Yes") {
    newErrors.files = "File is required";
  } else if (files.length > 1) {
    newErrors.files = "Only one file can be uploaded";
  }

  console.log("My Errors", newErrors);

  return newErrors;
};

const validateVendorPersonalDetailsForm = (form) => {
  function isRequiredField(key) {
    const requiredFields = [
      "first_name",
      "last_name",
      "email",
      "phone_no",
      "company",
      "job",
      "address1",
      "state",
      "country",
      "city",
      "zipCode",
    ];
    return requiredFields.includes(key);
  }

  const fieldsMap = {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    phone_no: "Phone No",
    company: "Company",
    job: "Job",
    address1: "Address 1",
    state: "State",
    country: "Country",
    city: "City",
    zipCode: "Zip Code",
  };

  const newErrors = {};

  for (const key in form) {
    if (form[key] === "") {
      if (isRequiredField(key)) {
        newErrors[key] = `${fieldsMap[key]} is required`;
      }
    }
  }

  if (form.phone_no && form.phone_no.length !== 10) {
    newErrors.phone_no = "Phone number must be 10 digits";
  }

  if (form.zipCode && form.zipCode.length !== 5) {
    newErrors.zipCode = "Zip Code must be 05 digits";
  }

  return newErrors;
};

const validateVendorInsuranceForm = (form) => {
  function isRequiredField(key) {
    const requiredFields = [
      "provider",
      "policyNum",
      "coverage",
      "effectiveDate",
      "expireDate",
    ];
    return requiredFields.includes(key);
  }

  const fieldsMap = {
    provider: "Provider",
    policyNum: "Policy Number",
    coverage: "Coverage",
    effectiveDate: "Effective Date",
    expireDate: "Expire Date",
  };

  const newErrors = {};

  for (const key in form) {
    if (form[key] === "") {
      if (isRequiredField(key)) {
        newErrors[key] = `${fieldsMap[key]} is required`;
      }
    }
  }

  return newErrors;
};

const validateCreateTaskForm = (form, vendor) => {
  console.log(form);
  function isRequiredField(key) {
    const requiredFields = [
      "title",
      "due_date",
      "status",
      "priority",
      "description",
      "start_date",
      "end_date",
      "frequency",
    ];
    return requiredFields.includes(key);
  }

  const fieldsMap = {
    title: "Title",
    due_date: "Due Date",
    status: "Status",
    priority: "Priority",
    description: "Description",
    start_date: "Start Date",
    end_date: "End Date",
    frequency: "Frequency",
  };

  const newErrors = {};

  if (vendor.length === 0) {
    newErrors["assignee"] = `Assignee is required`;
  }

  const currentDate = new Date().setHours(0, 0, 0, 0);

  for (const key in form) {
    if (key === "property") {
      if (form.property.propertyId === "") {
        newErrors[key] = `Property is required`;
      }
      if (form.property.unitId === "") {
        newErrors["unit"] = `Unit is required`;
      }
    }
    if (key === "due_date") {
      const dueDate = new Date(form.due_date).setHours(0, 0, 0, 0); // Get due date without time
      if (dueDate < currentDate) {
        newErrors[key] = "Due Date must be today or a future date.";
      }
    }
    if (form[key] === "") {
      if (key === "start_date" || key === "end_date" || key === "frequency") {
        console.log(form.recurring);
        if (form.recurring && isRequiredField(key)) {
          newErrors[key] = `${fieldsMap[key]} is required`;
        }
      } else if (isRequiredField(key)) {
        newErrors[key] = `${fieldsMap[key]} is required`;
      }
    }
  }

  console.log(newErrors);
  return newErrors;
};

const validationtenantSignIn = (form) => {
  const newErrors = {};

  // email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (form.email.trim() === "") {
    newErrors.email = "Email is required";
  } else if (!emailRegex.test(form.email)) {
    newErrors.email = "Please enter a valid email address";
  }

  //password validation
  if (form.password === "") {
    newErrors.password = "Password is required";
  }

  return newErrors;
};

const formfeilds = {
  title: "Title",
  description: "Description",
  unit: "Unit",
};

const validateTenantTaskForm = (form) => {
  const newErrors = {};

  for (const key in form) {
    if (key === "property") {
      if (form.property.propertyId === "") {
        newErrors[key] = `Property is required`;
      }
      if (form.property.unitId === "") {
        newErrors["unit"] = `Unit is required`;
      }
    }
    if (form[key] === "") {
      newErrors[key] = `${formfeilds[key]} is required`;
    }
  }

  return newErrors;
};

const serviceProfessionalSignIn = (form) => {
  const newErrors = {};

  // email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (form.email.trim() === "") {
    newErrors.email = "Email is required";
  } else if (!emailRegex.test(form.email)) {
    newErrors.email = "Please enter a valid email address";
  }

  //password validation
  if (form.password === "") {
    newErrors.password = "Password is required";
  }

  return newErrors;
};

const validatecreateTaskForm = (form) => {
  const newErrors = {};

  for (const key in form) {
    if (form[key] === "") {
      newErrors[key] = `${formfeilds[key]} is required`;
    }
  }

  return newErrors;
};

const validateTenantPassportProfile = (form) => {
  const newErrors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  function isRequiredField(key) {
    const requiredFields = [
      "firstName",
      "lastName",
      "email",
      "phone",
      "ssn",
      "moveInDate",
      "itin",
      "address",
      "country",
      "city",
      "state",
      "zipCode",
    ];
    return requiredFields.includes(key);
  }

  const fields = {
    firstName: "First Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone",
    ssn: "SSN",
    moveInDate: "Move In Date",
    itin: "ITIN",
    address: "Address",
    country: "Country",
    city: "City",
    state: "State",
    zipCode: "Zip Code",
  };

  for (const key in form) {
    if (form[key] === "") {
      if (isRequiredField(key)) {
        newErrors[key] = `${fields[key]} is required`;
      }
    }
  }

  if (form.email.trim() === "") {
    newErrors.email = "Email is required";
  }
  if (!emailRegex.test(form.email)) {
    newErrors.email = "Please enter a valid Email address";
  }

  if (form.phone && form.phone.length !== 10) {
    newErrors.phone = "Phone number must be 10 digits";
  }

  if (form.zipCode && form.zipCode.length !== 5) {
    newErrors.zipCode = "Zip Code must be 05 digits";
  }

  return newErrors;
};

const validatePostUpdateTask = (form) => {
  const newErrors = {};
  const currentDate = new Date().setHours(0, 0, 0, 0);
  const dueDate = new Date(form.due_date).setHours(0, 0, 0, 0);

  function isRequiredField(key) {
    const requiredFields = ["priority", "due_date", "status", "inputDetails"];
    return requiredFields.includes(key);
  }

  const fieldsMap = {
    priority: "Priority",
    due_date: "Due Date",
    status: "Status",
    inputDetails: "Input Details",
  };

  for (const key in form) {
    if (form[key] === "") {
      if (isRequiredField(key)) {
        newErrors[key] = `${fieldsMap[key]} is required`;
      }
    }
  }

  if (dueDate < currentDate) {
    newErrors.due_date = "Due Date must be today or a future date.";
  }

  return newErrors;
};

// const [form, setForm] = useState({
//   title: "",
//   property_sub_type: "",
//   status: "",
//   amenities: [],
//   bedroom: "",
//   bathroom: "",
//   area: "",
//   rent: "",
//   address: "",
//   address2: "",
//   city: "",
//   state: "",
//   zipCode: "",
//   description: "",
// });
const validateEditPropertyDetails = (form) => {
  const newErrors = {};

  function isRequiredField(key) {
    const requiredFields = [
      "title",
      "property_sub_type",
      "status",
      "bedroom",
      "bathroom",
      "area",
      "rent",
      "address",
      "city",
      "state",
      "zipCode",
    ];
    return requiredFields.includes(key);
  }

  const fieldsMap = {
    title: "Property Title",
    property_sub_type: "Property Sub Type",
    status: "Property Status",
    bedroom: "Bedroom",
    bathroom: "Bathroom",
    area: "Area",
    rent: "Rent Amount",
    address: "Address",
    city: "City",
    state: "State",
    zipCode: "Zip Code",
  };

  for (const key in form) {
    if (form[key] === "") {
      if (isRequiredField(key)) {
        newErrors[key] = `${fieldsMap[key]} is required`;
      }
    }
  }

  if (form.zipCode && form.zipCode.length !== 5) {
    newErrors.zipCode = "Zip Code must be 05 digits";
  }

  return newErrors;
};

const validateAddUnitDetails = (form) => {
  const newErrors = {};

  function isRequiredField(key) {
    const requiredFields = [
      "property_title",
      "unit_name",
      "rent",
      "bedroom",
      "bathroom",
      "area",
    ];
    return requiredFields.includes(key);
  }

  const fieldsMap = {
    property_title: "Property Title",
    unit_name: "Unit Name",
    rent: "Rent Amount",
    bedroom: "Bedroom",
    bathroom: "Bathroom",
    area: "Area",
  };

  for (const key in form) {
    if (form[key] === "") {
      if (isRequiredField(key)) {
        newErrors[key] = `${fieldsMap[key]} is required`;
      }
    }
  }

  return newErrors;
};

const validateCompanyDetails = (form) => {
  const newErrors = {};

  function isRequiredField(key) {
    const requiredFields = [
      "company_name",
      "company_email",
      "company_phone",
      "address",
      "state",
      "zipcode",
    ];
    return requiredFields.includes(key);
  }

  const fieldsMap = {
    company_name: "Company Name",
    company_email: "Company Email",
    company_phone: "Company Phone",
    address: "Address",
    state: "State",
    zipcode: "Zip Code",
  };

  for (const key in form) {
    if (form[key] === "") {
      if (isRequiredField(key)) {
        newErrors[key] = `${fieldsMap[key]} is required`;
      }
    }
  }

  if (
    form.company_email &&
    !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.company_email)
  ) {
    newErrors.company_email = "Please enter a valid email address";
  }

  if (form.company_phone && form.company_phone.length !== 10) {
    newErrors.company_phone = "Phone number must be 10 digits";
  }

  if (form.zipcode && form.zipcode.length !== 5) {
    newErrors.zipcode = "Zip Code must be 05 digits";
  }

  return newErrors;
};

const validateLandlordUserRoles = (form) => {
  const newErrors = {};

  if (form.role === "") {
    newErrors.role = "Role is required";
  }

  if (form.users.length === 0) {
    newErrors.users = "User is required";
  }

  return newErrors;
};

const validateLandlordUserRoleGeneralInfo = (form) => {
  const newErrors = {};

  if (form.role === "") {
    newErrors.role = "Role is required";
  }

  if (form.description === "") {
    newErrors.description = "Description is required";
  }

  return newErrors;
};

const invoiceformvalidation = (form, otherform, options) => {
  const newErrors = {};
  if (form.InvoiceDate === "") {
    newErrors.InvoiceDate = "Invoice Date is required";
  }

  if (form.Duedate === "") {
    newErrors.Duedate = "Due Date is required";
  }
  if (form.SelectTenant === "") {
    newErrors.SelectTenant = "Tenant is required";
  }
  if (form.property.propertyId === "") {
    newErrors.property = "Property is required";
  }
  if (form.property.unitId === "") {
    newErrors.unit = "Unit is required";
  }
  if (form.Select_BankAccount === "") {
    newErrors.Select_BankAccount = "Bank Account is required";
  }
  if (options === 2) {
    if (otherform.start_date === "") {
      newErrors.start_date = "Start Date is required";
    }
    if (otherform.end_date === "") {
      newErrors.end_date = "End Date is required";
    }
    if (otherform.frequency === "") {
      newErrors.frequency = "Frequency is required";
    }
    if (otherform.number_of_days_until_due === "") {
      newErrors.number_of_days_until_due =
        "Number of days until due is required";
    }
  }
  return newErrors;
};

const addpaymnetformvalidation = (form) => {
  const newErrors = {};
  if (form.name === "") {
    newErrors.name = "Card Holder Name is required";
  }
  if (form.cardnumber === "") {
    newErrors.cardnumber = "Card Number is required";
  }
  if (form.expiry === "") {
    newErrors.expiry = "Expiry Date is required";
  }
  if (form.cvv === "") {
    newErrors.cvv = "CVV is required";
  }
  return newErrors;
}


// firstName: "",
// middleName: "",
// lastName: "",
// email: "",
// addressLine_1: "",
// city: "",
// phone: "",
// country: "",
// state: "",
// zipcode: "",
// role: "",

const userformrolevalidation = (form) => {
  const newErrors = {};
  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (form.firstName === "") {
    newErrors.firstName = "First Name is required";
  }
  if (form.lastName === "") {
    newErrors.lastName = "Last Name is required";
  }
  if(form.middleName === ""){
    newErrors.middleName = "Middle Name is required";
  }
  if (form.email === "") {
    newErrors.email = "Email is required";
  }else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email)) {
    newErrors.email = "Please enter a valid email address"; 
    console.log("email---------------->",form.email)
  }
  if (form.phone === "") {
    newErrors.phone = "Phone is required";
  }
  if (form.addressLine_1 === "") {
    newErrors.addressLine_1 = "Address is required";
  }
  if (form.city === "") {
    newErrors.city = "City is required";
  }
  if (form.country === "") {
    newErrors.country = "Country is required";
  }
  if (form.state === "") {
    newErrors.state = "State is required";
  }
  if( form.role === ""){
    newErrors.role = "Role is required";
  }
  return newErrors;
}


export {
  validateProspectPersonalDetails,
  validateEmail,
  validateTenantPersonalDetails,
  validateTenantFileForm,
  validateNoteForm,
  validateLeaseInfoForm,
  validateLeaseTenantsForm,
  validateVendorPersonalDetailsForm,
  validateVendorInsuranceForm,
  validateCreateTaskForm,
  validatePropertyDetails,
  validateLandlordSignIn,
  LandlorSignupvalidationforom,
  validationtenantSignIn,
  validateUnitInfo,
  validateTenantTaskForm,
  TenantSignUpValidation,
  validateServiceProfessionalSignUp,
  validateLandlordUserInfo,
  validateLandlordAddUserInfo,
  serviceProfessionalSignIn,
  validatecreateTaskForm,
  validateTenantPassportProfile,
  validatePostUpdateTask,
  validateEditPropertyDetails,
  validateAddUnitDetails,
  validateCompanyDetails,
  validateLandlordUserRoles,
  validateLandlordUserRoleGeneralInfo,
  invoiceformvalidation,
  addpaymnetformvalidation,
  userformrolevalidation,
  validateLeaseDocumentForm,
};
