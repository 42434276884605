import React, { useState, useEffect } from "react";
import * as Component from "Components/GeneralComponents";
import { getTenantsList } from "network/landlord/lease";

const TenantInfo = ({
  form,
  handleChange,
  errors,
  handleAddCoTenant,
  handleRemoveCoTenant,
  handleBackButton,
  handleNext,
  isSubmitForm = false,
}) => {
  const [tenants, setTenants] = useState([]);
  const [tenantList, setTenantList] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    getTenantsList().then((res) => {
      if (res.apiCallStatus === "success") {
        setTenants(
          res.message.data.map((e) => ({
            value: e._id,
            label: `${e.email} (${e.type})`,
          }))
        );
        console.log("Tenant Data =>>", res.message.data);
        setData(
          res.message.data.map((e) => ({
            id: e._id,
            heading1: e.type,
            heading2: e.name,
            heading3: e.email,
          }))
        );
      }
    });
  }, []);

  useEffect(() => {
    const coTenantIds = form.coTenants.map((e) => e._id);
    const newTenants = tenants.filter(
      (e) => e.value !== form.tenant._id && !coTenantIds.includes(e.value)
    );
    setTenantList(newTenants);
  }, [tenants]);

  useEffect(() => {
    const coTenantIds = form.coTenants.map((e) => e._id);
    const newTenants = data.filter((e) => {
      if (e.id !== form.tenant._id && !coTenantIds.includes(e.id)) {
        return e;
      }
    });
    setTenantList(newTenants);
  }, [form.tenant, form.coTenants]);

  // const setTenantOptions = () => {
  //   const options = data
  //     .filter((e) => e.id === form.tenant._id)
  //     .concat(tenantList);
  //   return options;
  // };

  // const setCoTenantOptions = (coTenant) => {
  //   const options = data
  //     .filter((e) => e.id === coTenant._id)
  //     .concat(tenantList);

  //   return options;
  // };

  // const handleTenantChange = (e) => {
  //   console.log("Tenant Data e =>>", e);
  //   const value = data.filter((tenant) => tenant.id === e)[0];
  //   const selectedTenant = {
  //     _id: value.id,
  //     email: value.heading3,
  //     type: value.heading1,
  //   };
  //   handleChange("tenant", selectedTenant);
  // };

  // const handleCoTenantChange = (e, index) => {
  //   const value = data.filter((tenant) => tenant.id === e)[0];
  //   const selectedCoTenant = {
  //     _id: value.id,
  //     email: value.heading3,
  //     type: value.heading1,
  //   };
  //   handleChange("coTenant", selectedCoTenant, index);
  // };

  const filterSelectedTenants = (index) => {
    const coTenantIds = form.coTenants.map((e) => e._id);
    const allSelectedIds = [...coTenantIds, form.tenant._id];

    return data.filter(
      (tenant) =>
        !allSelectedIds.includes(tenant.id) ||
        (index !== undefined && form.coTenants[index] === tenant.id)
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          {/* <Component.SelectField
            name={"tenant"}
            required={true}
            label={"Select Tenant"}
            placeholder={"Select Tenant"}
            value={form.tenant._id}
            options={setTenantOptions()}
            handler={handleTenantChange}
            errorMessage={errors.tenant}
          /> */}
          <Component.SelectPropertyField
            label={"Select Tenant"}
            value={
              form.tenant._id === ""
                ? ""
                : `${form.tenant.name} / ${form.tenant.type}`
            }
            placeholder={"Select Tenant"}
            category={"tenant"}
            isRequired={true}
            PropertyData={filterSelectedTenants()}
            errorMessage={errors.tenant}
            onPropertySelect={(e) =>
              handleChange("tenant", {
                _id: e.id,
                name: e.heading2,
                email: e.heading3,
                type: e.heading1,
              })
            }
            disabled={true}
          />
        </div>
      </div>
      {form.coTenants.map((coTenant, index) => (
        <div className="row mt-3" key={index}>
          <div className="col-md-12">
            {/* <Component.SelectField
              name={"cotenant"}
              required={true}
              label={"Select Co-Tenant"}
              placeholder={"Select Co-Tenant"}
              value={coTenant._id}
              options={setCoTenantOptions(coTenant)}
              handler={(e) => handleCoTenantChange(e, index)}
              errorMessage={errors[`coTenant${index}`]}
            /> */}
            <Component.SelectPropertyField
              label={"Select Co-Tenant"}
              value={
                coTenant._id === "" ? "" : `${coTenant.name} / ${coTenant.type}`
              }
              placeholder={"Select Co-Tenant"}
              category={"tenant"}
              isRequired={true}
              PropertyData={filterSelectedTenants(index)}
              errorMessage={errors[`coTenant${index}`]}
              onPropertySelect={(e) => {
                handleChange(
                  "coTenant",
                  {
                    _id: e.id,
                    name: e.heading2,
                    email: e.heading3,
                    type: e.heading1,
                  },
                  index
                );
              }}
            />
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <button
                onClick={() => handleRemoveCoTenant(index)}
                className="primary-orange-text add-new-btn"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      ))}

      <div className="row mt-3">
        <div className="col-md-12">
          <button
            onClick={handleAddCoTenant}
            className="primary-orange-text add-new-btn"
          >
            + Add Co-Tenant
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-between gap-1 mt-5">
        <div style={{ width: "50%" }}>
          <Component.SubmitButton type="contained" handler={handleBackButton}>
            Back
          </Component.SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <Component.SubmitButton handler={handleNext}>
            {isSubmitForm ? "Submit" : "Next"}
          </Component.SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default TenantInfo;
