import React, { useEffect, useState } from "react";
import { Tabs, Skeleton } from "antd";
import sizeIcon from "assets/list-icon-3.png";
import homeIcon from "assets/icon_house.png";
import bedroomIcon from "assets/list-icon-1.png";
import bathroomIcon from "assets/list-icon-2.png";
import buildingIcon from "assets/icon_building.png";
import tickIcon from "assets/icon_tick_circle.png";
import { MapContainer, Marker, TileLayer, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import Loader from "Helpers/Loader";
import "swiper/css/navigation";
import ImageSwiper from "Components/GeneralComponents/ImageSwiper";
import { AmenitiesTab } from "Components/TenantPropertyUnitsView";

export const OverviewTab = ({ propertyData, isLoading }) => {
  // const [isLoading, setIsLoading] = useState(true);
  const markers = [
    {
      geocode: [48.86, 2.3522],
      popUp: "Hello, I am pop up 1",
    },
  ];
  const customIcon = new Icon({
    iconUrl: require("assets/map-icon.png"),
    iconSize: [38, 38],
  });

  const { TabPane } = Tabs;

  const formateImageViewerData = (data) => {
    return {
      title: data?.title || "",
      images: data?.images || [],
      address: `${data?.address?.address_line_1} , ${data?.address?.city}`,
      date: data.createdAt,
    };
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <ImageSwiper data={formateImageViewerData(propertyData)} />
          <Tabs
            defaultActiveKey="2"
            className="property_details_view_sub_tabs mt-5"
          >
            <TabPane tab="Description" key="2">
              <div className="row">
                <div className="col-md-12">
                  <p className="properties_details_view_description_content">
                    {propertyData?.description || (
                      <Skeleton paragraph={{ rows: 3 }} />
                    )}
                  </p>
                </div>
              </div>
              <div className="row mt-5">
                <div className={window.innerWidth <= 980 ? "" : "col-md-8"}>
                  <div className="d-flex icons-list-main">
                    <ul className="list-group landlord-property-details-overview">
                      <li className="list-group-item icons-list mb-4">
                        <img
                          alt=""
                          src={bedroomIcon}
                          className="icons-list-image"
                        />
                        {propertyData?.bedroom || "0"}
                        <sup className="icons-list-sub-title">Bedrooms</sup>
                      </li>
                      <li className="list-group-item icons-list description-icon-center mb-4">
                        <img
                          src={bathroomIcon}
                          className="icons-list-image"
                          alt="bathroom"
                        />
                        {propertyData?.bathroom || "0"}
                        <sup className="icons-list-sub-title">Bathrooms</sup>
                      </li>
                      <li className="list-group-item icons-list mb-4">
                        <img
                          src={sizeIcon}
                          className="icons-list-image"
                          alt="size"
                        />
                        {propertyData?.area || "0"}
                        <sup className="icons-list-sub-title-size">Size</sup>
                      </li>
                    </ul>
                  </div>
                  <div className="row text-center">
                    <div className="col">
                      <div className="counter">
                        <img
                          src={homeIcon}
                          className="fa-2x"
                          alt="property type"
                        />
                        <h2 className="timer count-title count-number">
                          Property Type
                        </h2>
                        <p className="count-text ">
                          {propertyData?.property_sub_type
                            ?.split("_")
                            .join(" ") || "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="counter">
                        <img
                          src={buildingIcon}
                          className="fa-2x"
                          alt="active units"
                        />
                        <h2 className="timer count-title count-number">
                          Active Units
                        </h2>
                        <p className="count-text ">
                          {propertyData?.active_units || "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="counter">
                        <img src={tickIcon} className="fa-2x" alt="status" />
                        <h2 className="timer count-title count-number">
                          Status
                        </h2>
                        <p className="count-text-unique ">Active</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={window.innerWidth <= 980 ? "" : "col-md-4"}>
                  <MapContainer center={[48.8566, 2.3522]} zoom={13}>
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {markers.map((marker, index) => (
                      <Marker
                        key={index}
                        position={marker.geocode}
                        icon={customIcon}
                      >
                        <Popup>{marker.popUp}</Popup>
                      </Marker>
                    ))}
                  </MapContainer>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Amenities" key="3">
              <AmenitiesTab amenities={propertyData?.amenities} />
            </TabPane>
          </Tabs>
        </div>
      )}
    </>
  );
};
