import React from "react";
import ChatUserList from "./ChatUserList/ChatUserList";

const Chat = () => {
  return (
    <>
      <div
        className="container-fluid bg-white p-3"
        style={{ height: "calc(100vh - 120px)", overflowY: "hidden" }}
      >
        <ChatUserList />
      </div>
    </>
  );
};

export default Chat;
