import React from "react";
import cancelIcon from "assets/x-circle.png";

const ModalLayout1 = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <div className="payment-modal-container" onClick={onClose}>
          <div className="payment-success-modal position-relative">
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default ModalLayout1;
