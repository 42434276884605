import React, { useEffect, useState } from "react";
import { message, Tabs } from "antd";
import { ConfigProvider } from "antd";
import UnitDetailsSliderModal from "Modals/UnitDetailsSliderModal/UnitDetailsSliderModal";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import AddTaskUnit from "Modals/AddTaskUnit/AddTaskUnit";
import settingIconOrange from "assets/dots-vertical.png";
import AddUnitDetailsEditModal from "Modals/AddUnitDetailsEditModal/AddUnitDetailsEditModal";
import OverviewTab from "./OverviewTab";
import { getUnit } from "network/landlord/property";
import LeasesTab from "./LeasesTab";
import TenantsTab from "./TenantsTab";
import { FilesTab, NotesTab } from "Components/TenantDetailsView/index.js";
import TasksTab from "./TasksTab";
import { deleteUnit } from "network/landlord/property";
import { DeleteModal, RecordMenuPopup } from "Components/GeneralComponents";
import { useNavigate } from "react-router-dom";

const PropertiesUnitsView = () => {
  const { TabPane } = Tabs;
  const navigate = useNavigate();

  // States
  const [showAddFile, setShowAddFile] = useState(false);
  const [openModalTask, setOpenModalTask] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [component, setcomponent] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [EditUnit, setEditUnit] = useState(false);
  const [update, setUpdate] = useState(false);
  const [unitData, setUnitData] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [isDeleteRender, setIsDeleteRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // States end

  // Modal Function
  const onOpenModalTask = () => {
    setOpenModalTask(true);
  };
  const onCloseModalTask = () => {
    setOpenModalTask(false);
  };
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };
  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleOpenFile = () => {
    setShowAddFile(true);
  };

  const onOpenEditModalUnit = () => {
    setEditUnit(true);
  };
  const onCloseEditModalUnit = () => {
    setEditUnit(false);
  };

  const handleIconClickCustom = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  // Get Property by id
  const { id, property_id } = UseUrlParamsHook();

  useEffect(() => {
    setIsLoading(true);
    getUnit(id).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setUnitData(res.message);
      }
    });
  }, []);

  useEffect(() => {
    if (deleteId) {
      setIsLoader(true);
      deleteUnit(deleteId).then((res) => {
        setIsLoader(false);
        if (res.apiCallStatus === "success") {
          onCloseDeleteModal();
          message.success("Unit Deleted Successfully");
          // setIsRerender(!isRerender);
          setDeleteId("");
          setUpdate(true);
        } else {
          message.error("Error in deleting unit");
        }
      });
    }
  }, [isDeleteRender]);

  return (
    <>
      {EditUnit && (
        <AddUnitDetailsEditModal
          setUpdate={setUpdate}
          id={id}
          onClose={onCloseEditModalUnit}
        />
      )}
      {openModal === true && <UnitDetailsSliderModal onClose={onCloseModal} />}
      {openModalTask === true && (
        <AddTaskUnit
          id={id}
          property={property_id}
          onOpen={onOpenModal}
          onClose={onCloseModalTask}
        />
      )}
      <DeleteModal
        isOpen={openDeleteModal}
        onClose={onCloseDeleteModal}
        message={"Are you sure you want to delete this unit"}
        deleteCall={() => setIsDeleteRender(!isDeleteRender)}
        isLoading={isLoader}
      />
      <div className="container-fluid bg-white p-3">
        <div className="row">
          <div className="col-md-12 text-end">
            <span className="position-relative">
              <img
                onClick={handleIconClickCustom}
                src={settingIconOrange}
                alt="Action Buttons"
                className="cursor"
              />
              <RecordMenuPopup
                isOpen={isDropdownOpen}
                onClose={() => setDropdownOpen(false)}
                handler={{
                  handleEdit: () => {
                    navigate(
                      `/properties-units-edit?id=${id}&propertyId=${property_id}`
                    );
                  },
                  handleDeleteRecord: () => {
                    onOpenDeleteModal();
                    setDeleteId(id);
                  },
                }}
                fields={{
                  edit: true,
                  delete: true,
                }}
              />
            </span>
          </div>
        </div>
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                inkBarColor: "#EF6B3E",
                itemColor: "#667085",
                itemSelectedColor: "#EF6B3E",
                itemHoverColor: "#EF6B3E",
                titleFontSize: 15,
                horizontalItemGutter: window.innerWidth <= 768 ? 10 : 60,
                fontFamily: "Montserrat",
              },
            },
          }}
        >
          <Tabs
            defaultActiveKey="1"
            centered
            style={{ fontWeight: 500 }}
            className="properties_units_view_tabs"
          >
            <TabPane tab="Overview" key="1">
              <OverviewTab unitData={unitData} isLoading={isLoading} />
            </TabPane>
            <TabPane tab="Leases" key="2">
              <LeasesTab />
            </TabPane>
            <TabPane tab="Tenants" key="3">
              <TenantsTab id={id} property={property_id}/>
            </TabPane>
            <TabPane tab="Tasks" key="4">
              <TasksTab id={id} />
            </TabPane>
            <TabPane tab="Files" key="5">
              <FilesTab category={"property"} id={property_id} />
            </TabPane>
            <TabPane tab="Notes" key="6">
              <NotesTab category={"property"} id={property_id} />
            </TabPane>
          </Tabs>
        </ConfigProvider>
      </div>
    </>
  );
};

export default PropertiesUnitsView;
