import axios from "axios";
import config from "Helpers/config.js";

const uploadDocument = async (data) => {
  console.log("Data =>>", data);
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/signnow/document/upload`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getDocument = async (id) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/signnow/document/${id}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getDocumentEditorLink = async (id, leaseId) => {
  try {
    const response = await axios.get(
      `${config.baseUrl}/api/signnow/document/${id}/editor`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { leaseId },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const addRecipientToDoc = async (documentId, data) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/signnow/document/${documentId}/invite`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const subscribeDocumentEvent = async (data) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/signnow/event-subscription`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  uploadDocument,
  getDocument,
  getDocumentEditorLink,
  addRecipientToDoc,
  subscribeDocumentEvent,
};
