import React from "react";
import SideDrawer from "Components/SideDrawer/SideDrawer";
import TitleTopBar from "Helpers/TitleTopBar";
import PropertiesUnitsEdit from "Components/PropertiesUnitsEdit/PropertiesUnitsEdit";
import ResponsiveSideBar from "Components/ResponsiveSideBar/ResponsiveSideBar";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";

const PropertiesUnitsEditPage = () => {
  const drawer = useSelector((state) => {
    return state.sideDrawer.drawerState;
  });
  // const { propertyId } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const propertyId = searchParams.get("propertyId");

  console.log("id", propertyId);

  return (
    <>
      <div className="container-fluid">
        <div className="row h-vh-100">
          <div className="col-xl-2 p-sm-0 d-md-none d-lg-none d-xl-block d-none position-relative drawer-bg-primary">
            <SideDrawer />
          </div>
          <div
            className={
              drawer === true
                ? "drawer-bg-primary responsive-drawer-container-open"
                : "drawer-bg-primary responsive-drawer-container "
            }
          >
            <ResponsiveSideBar />
          </div>
          <div className="col-xl-10 col-lg-12 col-md-12 mt-3">
            <div className="right-component bg-white h-100">
              <div className="container-fluid bg-default">
                <TitleTopBar
                  topBarTitleDetail={"Edit Unit Details"}
                  back={`property-details-view?id=${propertyId}`}
                />
              </div>
              <PropertiesUnitsEdit />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertiesUnitsEditPage;
