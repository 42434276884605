import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import SideDrawer from "Components/SideDrawer/SideDrawer";
import PropertiesDeleteView from "Components/PropertiesDeleteView/PropertiesDeleteView";

const PropertiesDeleteViewPage = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2 p-sm-0 position-relative drawer-bg-primary">
            <SideDrawer />
          </div>
          <div className="col-xl-10 col-lg-12 col-md-12 mt-3">
            <div className="right-component bg-white h-100">
              <div className="container-fluid bg-default">
                <TitleTopBar topBarTitle={"Archive Properties"} />
              </div>
              <PropertiesDeleteView />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertiesDeleteViewPage;
