import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import SideDrawer from "Components/SideDrawer/SideDrawer";
import Chat from "Components/Chat/Chat";
import ResponsiveSideBar from "Components/ResponsiveSideBar/ResponsiveSideBar";
import { useSelector } from "react-redux";
import MobileChatBody from "Components/Chat/MobileChatBody/MobileChatBody";
const MobileChatBodyPage = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 mt-3">
            <MobileChatBody />
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileChatBodyPage;
