import { useState } from "react";
import NotFound from "assets/not-found-img.png";
import { Table, ConfigProvider } from "antd";
import settingIcon from "assets/three-dots.png";
import viewIcon from "assets/Icon.png";
import { Link } from "react-router-dom";
import SearchBarWithOutBtn from "Helpers/SearchBarWithOutBtn";
import { ButtonVariant1 } from "Components/GeneralComponents";
import { FilterValue } from "Store/Slices/FilterSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const CostumerQueries = () => {
  const [selectionType, setSelectionType] = useState("checkbox");
  const [key, setKey] = useState([]);

  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };
  const dispatch = useDispatch()


  const resetFilter = () => {
    dispatch(FilterValue({ reset: "reset" }));
  };

  const range = useSelector((state) => {
    return state.FilterValue.value;
  });
  const property = useSelector((state) => {
    return state.FilterValue.property;
  });
  const fromDate = useSelector((state) => {
    return state.FilterValue.fromDate;
  });
  const toDate = useSelector((state) => {
    return state.FilterValue.toDate;
  });
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      render: (text) => (
        <Link className="text-dark" to="/costumer-queries-inner">
          {text}
        </Link>
      ),
    },
    {
      title: "QUERY DETAILS",
      dataIndex: "query",
    },
    {
      title: "ACTIONS",
      dataIndex: "Actions",
      render: (button) => (
        <>
          <div className="">
            <button className="btn btn-danger m-2 ps-3 pe-3 customer-decline-btn">
              Decline
            </button>
            <button className="btn btn-danger m-2 ps-3 pe-3 customer-accept-btn">
              Accept
            </button>
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div className="task-table-setting-container position-relative cursor">
            <img
              src={settingIcon}
              alt=""
              onClick={() => {
                handleIconClick(setting.key);
              }}
            />
            {setting.key === key && (
              <div className="task-table-setting-dropdown-prospect bg-white box-shadow">
                <ul className="p-0 d-flex flex-column gap-3">
                  <Link to="/costumer-queries-inner">
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={viewIcon} alt="" /> View
                    </li>
                  </Link>
                </ul>
              </div>
            )}
          </div>
        </>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      name: "Olivia Rhye",
      query:
        "Youthful, adventurous and fun, treehouses hold a special place in our hearts – and those of....",
    },
    {
      key: "2",
      name: "Phoenix Baker",
      query:
        "Youthful, adventurous and fun, treehouses hold a special place in our hearts – and those of....",
    },
    {
      key: "3",
      name: "Lana Steiner",
      query:
        "Youthful, adventurous and fun, treehouses hold a special place in our hearts – and those of....",
    },
    {
      key: "4",
      name: "John Brown",
      query:
        "Youthful, adventurous and fun, treehouses hold a special place in our hearts – and those of....",
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div>
      <div className="container-fluid bg-white p-3">
        <div className="row">
          <div className="col-md-12 ">
            <SearchBarWithOutBtn
              // btnTitle="Add New Prospects"
              // route="add-prospect-details"
              customerQueriesFilter={true}
            />
          </div>
        </div>

        <div className="not-found-container text-center d-none">
          <img src={NotFound} alt="" />
          <p>
            <strong>No Queries found</strong>
          </p>
          <p>
            No Queries were found; the folder is empty. <br /> Please try again.
          </p>
          
        </div>
      </div>
      <div className="task-table-container  mt-3">
        {range || property || fromDate || toDate ?
          <div className="mt-2" style={{ width: 'fit-content' }}>
            <ButtonVariant1 handler={resetFilter} children={"Reset"} />

          </div>
          : ""
        }
        <ConfigProvider
          theme={{
            components: {
              Table: {
                colorTextHeading: "#667085",
                //colorTextDescription: '#667085',
                colorText: "#667085",
                fontSize: 14,
                fontWeightStrong: 500,
                fontFamily: "Montserrat",
                //cellFontSize: 14,
              },
            },
          }}
        >
          <Table
            pagination={true}
            className="all-prospects-table-list scroll-remove scroll-responsive-tablet"
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            columns={columns}
            dataSource={data}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default CostumerQueries;
