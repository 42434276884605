import React,{ useState, useEffect } from "react";
import Leasedstatuscards from "Components/GeneralComponents/Leasedstatuscards";
import trashIconWhite from "assets/trash-icon-white.png";
import * as Component from "Components/GeneralComponents";
import activeDot from "assets/_Dot.png";
import redDot from "assets/svg/_Dot.svg";
import { useNavigate, Link } from "react-router-dom";
import * as Icon from "assets/icons";
import * as icon from "assets/svg";
import { getAllInvoices, archiveInvoice, filterAllInvoices } from "network/landlord/Account";
import { message, Switch } from "antd";
import { formatDate } from "utils";
import { isRejected } from "@reduxjs/toolkit";



const Payment = () => {
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [button, setButton] = useState(true);
  const [mulDeleteMsg, setMulDeleteMsg] = useState(
    "Are you sure want to delete selected tenants?"
  );
  const [disableMulDltBtn, setDisableMulDltBtn] = useState(false);
  const [isDeleteTenantModal, setIsDeleteTenantModal] = useState(false);
  const [key, setKey] = useState("");
  const [isDeleteTenantsRender, setIsDeleteTenantsRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [update , setUpdate] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [allInvoices,setAllInvoices] = useState([]);
  const [leaseStatusCard,setLeaseStatusCard] = useState({
    total_invoices: 0,
    paid_invoices: 0,
    pending_invoices: 0,
    overDue_invoices: 0
  })
  
  const navigate = useNavigate();

  useEffect(() => {
  setTimeout(() => {
    setIsTableLoading(false);
  }, 1000);
  }, [update]);


  

  


  const [data, setData] = useState([
    {
      key: "1",
    },
    {
      key: "2",
    },
    {
      key: "3",
    },
  ]);
  const [invoiceData, setInvoiceData] = useState([
    // {
    //   invoice_no: "INV-001",
    //   key: "1",
    //   name: "John Doe",
    //   invoice_date: "01/01/2021",
    //   amount: 1000,
    //   Description: "Rent abcd",
    //   status: "Paid",
    // },
    // {
    //   invoice_no: "INV-002",
    //   key: "2",
    //   name: "John Doe",
    //   invoice_date: "01/01/2021",
    //   amount: 1000,
    //   Description: "Rent efgh",
    //   status: "Unpaid",
    // },
    // {
    //   invoice_no: "INV-003",
    //   key: "3",
    //   name: "John Doe",
    //   invoice_date: "01/01/2021",
    //   amount: 1000,
    //   Description: "ijklz",
    //   status: "Overdue",
    // }
  ]);
  const [filter, setFilter] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    range: "",
    property: "",
  });

  useEffect(() => {
    if (allInvoices.length > 0) {
      filterInvoices("one time");
    }
  }, [allInvoices]); 

  const filterInvoices = (type)=>{
    console.log("type", type)
    console.log("allInvoices: ",allInvoices)
    const filteredInvoices = allInvoices.filter(item=>  item.type=== type)
    console.log("filteredInvoices", filteredInvoices)
    setInvoiceData(filteredInvoices)
  }

  const fetchInvoices = async () => {
    try {
      const response = await getAllInvoices();
      if (response.apiCallStatus === "success") {
        const transformedInvoices = response.message.invoices.allInvoices.invoices.map((invoice, index) => ({
          display_invoice_no: invoice.invoiceNo.substring(invoice.invoiceNo.indexOf("INV")),
          invoice_no: invoice.invoiceNo,
          due_date: invoice.dueDate,
          type: invoice.type,
          sendStatus: invoice.sendStatus,
         

          
          key: index.toString(),
          name: `${invoice.tenantId.firstName} ${invoice.tenantId.lastName}`,
          invoice_date: new Date(invoice.invoiceDate).toLocaleDateString(), 
          amount: invoice.total,
          Description: "Rent", 
          status: invoice.paidStatus.charAt(0).toUpperCase() + invoice.paidStatus.slice(1), 
        }));


        setLeaseStatusCard({
            total_invoices: response.message.invoices.allInvoices.totalInvoices,
            paid_invoices: response.message.invoices.allInvoices.paidInvoices,
            pending_invoices: response.message.invoices.allInvoices.pendingInvoices,
            overDue_invoices: response.message.invoices.allInvoices.overdueInvoices
          });

        setAllInvoices(transformedInvoices);
        console.log("Invoices set successfully!", transformedInvoices);
        setInvoiceData(transformedInvoices);

        filterInvoices("one time");
      } else {
        message.error("Failed to get Invoice", response.message);
      }
    } catch (error) {
      console.error("Error fetching invoices", error);
      message.error("Something went wrong while fetching the invoices!");
    }
  };
  useEffect(() => {
    fetchInvoices(); 
  }, []); 

  const handleFilter = async () => {
    console.log("Filter called", filter);
    try {
      const response = await filterAllInvoices(filter); // Ensure response is awaited
  
      if (response?.apiCallStatus === "success") { // Ensure response and apiCallStatus are properly checked
        console.log(response);
  
        const transformedInvoices = response.message?.invoices?.map(
          (invoice, index) => ({
            display_invoice_no: invoice.invoiceNo.substring(
              invoice.invoiceNo.indexOf("INV")
            ),
            invoice_no: invoice.invoiceNo,
            due_date: invoice.dueDate,
            sendStatus: invoice.sendStatus,
            type: invoice.type,
            key: index.toString(),
            invoice_date: new Date(invoice.invoiceDate).toLocaleDateString(),
            amount: invoice.total,
            Description: "Rent",
            status:
              invoice.paidStatus.charAt(0).toUpperCase() +
              invoice.paidStatus.slice(1),
          })
        );
        console.log("Transformed in filter invoices:", transformedInvoices);
        setAllInvoices(transformedInvoices)
        if(button){
          filterInvoices('one time')
        }else{
          filterInvoices("recurring");
        }

        setInvoiceData(transformedInvoices)
      } else {
        console.log("API call was unsuccessful", response);
      }
    } catch (error) {
      message.error("Failed to filter invoices", error);
      console.error("Error:", error);
    }
  };
    
  const handleFilterReset = ()=>{
    setFilter({
        
    })
    fetchInvoices(); 
}

  const archiveInvoiceById = async(invoice_no) =>{
    // console.log(invoice_no)
    const resposne = await archiveInvoice(invoice_no);
    if(resposne.apiCallStatus === "success"){
      message.success("Invoice Archived Successfully");

      console.log(resposne?.message)
      // setUpdate(!update);
      
    }else{
      message.error("Failed to archive invoice", resposne?.message);
    }

  }

  const invoice = () => {
    filterInvoices("one time");

    setButton(true);
  
  };
  const recuring = () => {
    filterInvoices("recurring");
    setButton(false);

  };
  const columns = [
    {
      title: "Invoice No",
      dataIndex: "display_invoice_no",
      render: (text, data) => (
        <>
        {
          text
        }
        </>
      ),
    },
    {
      title:"Name",
      dataIndex: "name",
      render: (text,data) => (
        <>
          {text}
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "Description",
      render: (text,date) => (
        <>
        {
          text
        }
        </>
      ),
    },
    {
      title:"Amount",
      dataIndex: "amount",
      render: (text,date) => (
        <>
        {
          text
        }
        </>
      ),
    },
    {
      title:"Invoice Date",
      dataIndex: "invoice_date",
      render: (text,record) => (
        <>
        <div className="d-flex flex-column ">
        <span className="d-flex flex-row">
          <span className="text-nowrap">{text}</span>
          <span
            className={`${record.sendStatus === "sent" ? "prospect-active-bar" : "prospect-unpaid-bar"}` } style={{width:'50px', marginLeft: '8px'} }
          >
            {/* <img src={text === "sent" ? activeDot : redDot } alt="" />{" "} */}
            <span>{record.sendStatus}</span>
          </span>
          </span>

          <span className="text-nowrap"> Due Date: {record.due_date}</span>
        </div>
        </>
      ),
    },
    {
      title:"Status",
      dataIndex: "status",
      render: (text, status) => (
        <>
          <div
            className={`${text === "Paid" ? "prospect-active-bar" : "prospect-unpaid-bar"}` } style={{width:'70px'} }
          >
            <img src={text === "Paid" ? activeDot : redDot } alt="" />{" "}
            <span>{text}</span>
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting,) => (
        <>
        {
          button ?<div  className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                editinvoice: () => console.log("Edit Invoice"),
                reminder: () => console.log("Send Reminder"),
                stopautoreminder: () => console.log("Stop Auto Reminder"),
                recordpayment: () => console.log("Record Payment"),
                downloadaspdf: () => console.log("Download as PDF"),
                print: () =>  console.log("Print"),
                archive: () => archiveInvoiceById(setting.invoice_no),
                cancel: () => console.log("Cancel"),
              }}
              fields={{
                editinvoice: true,
                sendreminder: true,
                stopautoreminder: true,
                recordpayment : true,
                downloadaspdf: true,
                print: true,
                archive: true,
                cancel: true,
              }}
              />
          </div> : <div  className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleView: () => console.log("View"),
                cancel: () => console.log("Cancel"),
              }}
              fields={{
                editseries: true,
                view: true,
                cancel: true,
              }}
              />
          </div>
          }
        </>
      ),
    },
  ];
  const handleIconClick = (result) => {
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  useEffect(() => {
    // setIsLoading(true);
  }, [isDeleteTenantsRender]);

  useEffect(() => {
    if (filter.search) {
      console.log("filter.search", filter.search);  
      setData(
        invoiceData.filter((item) => {
          if (item.invoice_no.toLowerCase().includes(filter.search.toLowerCase()))
            return item , console.log("item", item);
          ;
          if (item.Description.toLowerCase().includes(filter.search.toLowerCase()))
            return item;
          if (item.status.toLowerCase().includes(filter.search.toLowerCase()))
            return item;
          if (item.name.includes(filter.search)) return item;
        })
      );
    } else {
      setData(invoiceData);
    }
  }, [filter.search, invoiceData]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableItem([...selectedRowKeys]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const onCloseDeleteTenantModal = () => {
    setIsDeleteTenantModal(false);
    setMulDeleteMsg("Are you sure want to delete selected tenants?");
    setDisableMulDltBtn(false);
  };




  return (
    <>
        <Component.DeleteModal
        isOpen={isDeleteTenantModal}
        onClose={onCloseDeleteTenantModal}
        message={mulDeleteMsg}
        deleteCall={() => setIsDeleteTenantsRender(!isDeleteTenantsRender)}
        isLoading={isLoading}
        disableBtn={disableMulDltBtn}
      />

      <div className="container-fluid bg-white p-4">
        <div className="row mt-3">
          <Leasedstatuscards
            icon={icon.file4}
            balance={true}
            value={leaseStatusCard.total_invoices}
            title="Total Invoices"
          />
          <Leasedstatuscards
            icon={icon.file1}
            balance={true}
            value={leaseStatusCard.paid_invoices}
            title="Paid Invoices"
          />
          <Leasedstatuscards
            icon={icon.file3}
            balance={true}
            value={leaseStatusCard.pending_invoices}
            title="Pending Invoice"
          />
          <Leasedstatuscards
            icon={icon.file2}
            balance={true}
            value={leaseStatusCard.overDue_invoices}
            title="Overdue Invoice"
          />
        </div>
      </div>
      <div className="row mt-1 ps-md-4 col-md-5 col-12">
          <div className="stepper-btn d-flex justify-content-between  gap-1 pb-3">
            <button onClick={invoice} className={`${button ? 'next-btn-same-class' : 'recuring-btn' }`}>
              Invoices
            </button>
            <button onClick={recuring} className={`${button ? 'recuring-btn text-nowrap' : 'next-btn-same-class text-nowrap' }`}>
              Recuring Series
            </button>
          </div>
      </div>
      <div className="container-fluid bg-white p-3 ">
        <Component.FilterRow
          filter={filter}
          setFilter={setFilter}
          btnTitle={"Create New Invoice"}
          btnHandler={() => navigate("/create-payable")}
          handleSubmit={handleFilter}
          handleReset={handleFilterReset}
          filterList={{
            date: true,
            // range: true,
            invoiceFilter: true,
          }}
          />
      </div>
      <div className="row p-4">
          <div className="col-md-12">
            {selectedTableItem.length !== 0 && (
              <div className="table-delete-icon mb-3">
                <button
                  onClick={() => setIsDeleteTenantModal(true)}
                  className="table-delete-btn next-btn-main"
                >
                  <img src={trashIconWhite} alt="" />
                  Delete
                </button>
              </div>
            )}
            <Component.CustomTable
              loading={isTableLoading}
              pagination={true}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              columns={columns}
              dataSource={invoiceData}
            />
          </div>
        </div>
    </>
  );
};

export default Payment;

// import { useEffect, useState } from "react";
// import {
//   EmbeddedCheckout,
//   EmbeddedCheckoutProvider,
// } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import config from "Helpers/config.js";
// import leaseStatusIcon from "assets/lease-status.png";
// import leaseRentIcon from "assets/lease-rent.png";
// import leaseBalanceIcon from "assets/lease-balance.png";
// import SearchBar from "Helpers/SearchBar";
// import { Table, ConfigProvider } from "antd";
// import viewIcon from "assets/Icon.png";
// import deleteIcon from "assets/trash-01.png";
// import settingIconOrange from "assets/dots-vertical.png";
// import { useNavigate, Link } from "react-router-dom";
// import trashIconWhite from "assets/trash-icon-white.png";
// import dot from "assets/_Dot.png";
// import dueDot from "assets/_red-Dot.png";
// import SearchBarWithOutBtn from "Helpers/SearchBarWithOutBtn";
// import UseGetHook from "Hooks/UseGetHook";
// import { useSelector } from "react-redux";
// import DeleteModal from "Modals/DeleteModal/DeleteModal";
// import BulkDelete from "Hooks/BulkDelete";
// import ConditionalFilter from "Hooks/ConditionalFilter";
// import {
//   RecordMenuPopup,
//   ModalLayout1,
//   ButtonVariant1,
// } from "Components/GeneralComponents";
// import UserPermission from "libs/UserPermission";
// import { useDispatch } from "react-redux";
// import { FilterValue } from "Store/Slices/FilterSlice";
// import axios from "axios";
// import { MdOutlineErrorOutline } from "react-icons/md";
// import Logo from "assets/Copy of DigitalTenant_Logo.png";
// import * as Icon from "assets/icons";

// const Payment = () => {
//   // States start
//   const [selectionType, setSelectionType] = useState("checkbox");
//   const [key, setKey] = useState("");
//   const [deleteId, setDeleteId] = useState("");
//   const [selectedTableItem, setSelectedTableItem] = useState("");
//   const [isDropdownOpen, setDropdownOpen] = useState(false);
//   const navigate = useNavigate();
//   const [component, setcomponent] = useState("");
//   const [Delete, setDelete] = useState("");
//   const [update, setUpdate] = useState(false);
//   const [isPayNowModelOpen, setIsPayNowModelOpen] = useState(false);
//   const [options, setOptions] = useState({});
//   const [invoiceError, setInvoiceError] = useState("");
//   // console.log("🚀 ~ Payment ~ invoiceError:", invoiceError)
//   const [apiCallError, setApiCallError] = useState();
//   // console.log("🚀 ~ Payment ~ apiCallError:", apiCallError)
//   // States end

//   const dispatch = useDispatch();
//   const { ROLE } = UserPermission();

//   const resetFilter = () => {
//     dispatch(FilterValue({ reset: "reset" }));
//   };

//   const range = useSelector((state) => {
//     return state.FilterValue.value;
//   });
//   const property = useSelector((state) => {
//     return state.FilterValue.property;
//   });
//   const toDate = useSelector((state) => {
//     return state.FilterValue.toDate;
//   });
//   const fromDate = useSelector((state) => {
//     return state.FilterValue.fromDate;
//   });
//   const { filters, FilterObjects } = ConditionalFilter({
//     range,
//     property,
//     fromDate,
//     toDate,
//   });

//   // Stripe
//   const stripeKey = loadStripe(config.stripe_publish_key);

//   const role = localStorage.getItem("role");

//   // GET API endpoint based on role and filters
//   const getFilterUrl = () => {
//     if (role === "tenant") {
//       return filters(FilterObjects)
//         ? `getInvoicesTenantFilter?${filters(FilterObjects)}`
//         : "getInvoicesTenant";
//     } else {
//       return filters(FilterObjects)
//         ? `getInvoice/filter?${filters(FilterObjects)}`
//         : "getAllInvoices";
//     }
//   };

//   const { Invoice, FetchInvoice, FetchTenantInvoice } = UseGetHook(
//     getFilterUrl()
//   );

//   const search = useSelector((state) => {
//     return state.Search.value;
//   });

//   // Fetch invoie data based on filters and role
//   useEffect(() => {
//     if (role === "tenant") {
//       FetchTenantInvoice();
//     } else {
//       FetchInvoice();
//     }
//   }, [range, property, toDate, fromDate]);

//   useEffect(() => {
//     if (update) {
//       FetchInvoice();
//       setUpdate(false);
//     }
//   }, [update]);
//   const handleIconClickCustom = () => {
//     // Toggle the dropdown state when the icon is clicked
//     setDropdownOpen(!isDropdownOpen);
//   };

//   // Data Table functions
//   const data = Invoice.filter((e) =>
//     e.invoice_no.toLowerCase().includes(search.toLowerCase())
//   ).map((e) => ({
//     key: e?._id,
//     invoice: e?.invoice_no,
//     name: `${e?.owner?.firstName} ${e?.owner?.lastName}`,
//     start_date: new Date(e?.start_date).toLocaleDateString(),
//     end_date: new Date(e?.end_date).toLocaleDateString(),
//     property: e?.property_id?.title,
//     due_date: new Date(e?.due_date).toLocaleDateString(),
//     status: e?.status,
//   }));

//   const OpenDeleteModal = () => {
//     setDelete(true);
//   };
//   const closDeleteModal = () => {
//     setDelete(false);
//   };

//   const handleIconClick = (result) => {
//     if (key === result) {
//       setKey(null);
//     } else {
//       setKey(result);
//     }
//   };

//   const handlePayNow = async () => {
//     try {
//       const propertyId = await Invoice.filter((tenant) => tenant._id === key)[0]
//         .property_id.id;

//       const fetchClientSecret = async () => {
//         try {
//           const response = await axios.post(
//             `${config.baseUrl}/api/stripe/paynow`,
//             {
//               email: localStorage.getItem("email"),
//               invoiceId: key,
//               propertyId: propertyId,
//               returnUrl: `${
//                 process.env.REACT_APP_ENVIRONMENT === "development"
//                   ? process.env.REACT_APP_API_URL_DEV
//                   : process.env.REACT_APP_API_URL
//               }/Payment`,
//             },
//             {
//               headers: {
//                 "content-type": "application/json",
//                 Authorization: `Bearer ${localStorage.getItem("token")}`,
//               },
//             }
//           );

//           const data = response.data;
//           return data?.message?.data?.client_secret;
//         } catch (error) {
//           // console.error("Error fetching client secret:", error?.response?.data);
//           setApiCallError(error?.response?.data?.apiCallStatus);
//           setInvoiceError(error?.response?.data?.message);
//           throw error;
//         }
//       };

//       setOptions({ fetchClientSecret });
//       setIsPayNowModelOpen(true);
//     } catch (error) {
//       console.error("Error handling pay now:", error);
//     }
//   };

//   // const handlePayNow = () => {
//   //   const propertyId = Invoice.filter((tenant) => tenant._id === key)[0]
//   //     .property_id.id;
//   //   const fetchClientSecret = () => {
//   //     return fetch(`${config.baseUrl}/api/stripe/paynow`, {
//   //       method: "POST",
//   //       headers: {
//   //         "content-type": "application/json",
//   //         Authorization: `Bearer ${localStorage.getItem("token")}`,
//   //       },
//   //       body: JSON.stringify({
//   //         email: localStorage.getItem("email"),
//   //         invoiceId: key,
//   //         propertyId: propertyId,
//   //         returnUrl: `${process.env.REACT_APP_ENVIRONMENT === "development"
//   //             ? process.env.REACT_APP_API_URL_DEV
//   //             : process.env.REACT_APP_API_URL
//   //           }/Payment`,
//   //       }),
//   //     })
//   //       .then((res) => res.json())
//   //       .then((data) => data?.message?.data?.client_secret);
//   //   };
//   //   setOptions({ fetchClientSecret });

//   //   setIsPayNowModelOpen(true);
//   // };

//   const columns = [
//     {
//       title: "Invoice No",
//       dataIndex: "invoice",
//       render: (text, name) => (
//         <>
//           <Link
//             to={`/payable-overview?id=${name.key}`}
//             className="payment-table-invoice-text"
//           >
//             {text}
//           </Link>
//         </>
//       ),
//     },
//     {
//       title: "Name",
//       dataIndex: "name",
//       render: (text) => <span>{text}</span>,
//     },
//     {
//       title: "Start date",
//       dataIndex: "start_date",
//       render: (text) => (
//         <>
//           <span className="phone">{text}</span>
//         </>
//       ),
//     },
//     {
//       title: "End date",
//       dataIndex: "end_date",
//       render: (text) => (
//         <>
//           <span>{text}</span>
//         </>
//       ),
//     },
//     {
//       title: "Related to",
//       dataIndex: "property",
//       render: (text) => (
//         <>
//           <span>{text}</span>
//         </>
//       ),
//     },
//     {
//       title: "Invoice Date",
//       dataIndex: "due_date",
//       render: (text) => (
//         <>
//           <span>{text}</span>
//         </>
//       ),
//     },
//     {
//       title: "Status",
//       dataIndex: "status",
//       render: (text, status) => (
//         <>
//           <span
//             className={
//               text === "unpaid"
//                 ? "tenant-report-due-bar"
//                 : "tenant-report-active-bar"
//             }
//           >
//             <img
//               src={text === "unpaid" ? dueDot : dot}
//               alt=""
//               className="me-1"
//             />
//             {text}
//           </span>
//         </>
//       ),
//     },
//     {
//       title: "",
//       dataIndex: "setting",
//       render: (text, setting) => (
//         <>
//           <div
//             className="position-relative cursor text-center"
//             onClick={() => handleIconClick(setting.key)}
//           >
//             <Icon.VerticalElipsisIcon width={23} height={23} />
//             <RecordMenuPopup
//               isOpen={key === setting.key}
//               onClose={() => setKey(null)}
//               fields={{
//                 view: true,
//                 payNow: setting.status === "Paid" ? false : true,
//                 delete: true,
//               }}
//               handler={{
//                 handleDeleteRecord: () => {
//                   OpenDeleteModal();
//                   setcomponent("invoice");
//                   setDeleteId(setting.key);
//                 },
//                 handlePayNow: handlePayNow,
//                 hanldleView: () =>
//                   navigate(`/payable-overview?id=${setting.key}`),
//               }}
//             />
//           </div>
//         </>
//       ),
//     },
//   ];
//   // rowSelection object indicates the need for row selection
//   const rowSelection = {
//     onChange: (selectedRowKeys, selectedRows) => {
//       setSelectedTableItem([...selectedRowKeys]);
//     },
//     getCheckboxProps: (record) => ({
//       disabled: record.name === "Disabled User",
//       // Column configuration not to be checked
//       name: record.name,
//     }),
//   };
//   const { bulkDelete } = BulkDelete("invoice", selectedTableItem, FetchInvoice);
//   const DeleteSelectedProperty = () => {
//     bulkDelete();
//   };
//   return (
//     <>
//       {Delete && (
//         <DeleteModal
//           setUpdate={setUpdate}
//           onClose={closDeleteModal}
//           component={component}
//           deleteBtnText={`Delete ${component}`}
//           delId={deleteId}
//         />
//       )}

//       <div className="container-fluid bg-white p-3">
//       <div className="row mt-3">
//             <Leasedstatuscards
//               icon={leaseBalanceIcon}
//               title="Balance"
//             />
//             <Leasedstatuscards
//               icon={leaseStatusIcon}
//               value={"Active"}
//               title="Lease Status"
//             />
//             <Leasedstatuscards
//               icon={leaseRentIcon}
//               title="Rent"
//             />
//           </div>
//         <div className="row mt-3">
//           <div className="col-md-12">
//             {localStorage.getItem("role") === "tenant" ? (
//               <SearchBarWithOutBtn
//                 route="create-payable"
//                 invoiceFilter={true}
//                 btnTitle="Create New Invoice"
//                 unitsFilter={true}
//               />
//             ) : (
//               <SearchBar
//                 route="create-payable"
//                 invoiceFilter={true}
//                 btnTitle="Create New Invoice"
//               />
//             )}
//           </div>
//         </div>
//         <div className="row mt-3">
//           <div className="col-md-12">
//             {selectedTableItem.length >= 1 && (
//               <div className="table-delete-icon mt-3">
//                 <button
//                   onClick={DeleteSelectedProperty}
//                   className="table-delete-btn next-btn-main"
//                 >
//                   <img src={trashIconWhite} alt="Delete Icon" />
//                   Delete
//                 </button>
//               </div>
//             )}
//             <div className="table-container">
//               {range || property || fromDate || toDate ? (
//                 <div className="mt-2" style={{ width: "fit-content" }}>
//                   <ButtonVariant1 handler={resetFilter} children={"Reset"} />
//                 </div>
//               ) : (
//                 ""
//               )}
//               <ConfigProvider
//                 theme={{
//                   components: {
//                     Table: {
//                       colorTextHeading: "#667085",
//                       //colorTextDescription: '#667085',
//                       colorText: "#667085",
//                       fontSize: 14,
//                       fontWeightStrong: 500,
//                       fontFamily: "Montserrat",
//                       //cellFontSize: 14,
//                     },
//                     Pagination: {
//                       itemActiveBg: "#EF6B3E",
//                     },
//                     Checkbox: {
//                       colorPrimary: "#EF6B3E",
//                       colorPrimaryHover: "#EF6B3E",
//                     },
//                   },
//                 }}
//               >
//                 <Table
//                   pagination={true}
//                   className="scroll-remove scroll-responsive-tablet"
//                   rowSelection={{
//                     type: selectionType,
//                     ...rowSelection,
//                   }}
//                   columns={columns}
//                   dataSource={data}
//                 />
//               </ConfigProvider>
//             </div>
//           </div>
//         </div>
//         {/* Pay now Model */}
//         {apiCallError === "error" ? (
//           <ModalLayout1
//             isOpen={isPayNowModelOpen}
//             onClose={() => setIsPayNowModelOpen(false)}
//           >
//             <div className="invoiceError">
//               <MdOutlineErrorOutline color="red" size={50} />
//               <p className="mt-3 invoiceErrorText"> {invoiceError} </p>
//             </div>
//           </ModalLayout1>
//         ) : (
//           <ModalLayout1
//             isOpen={isPayNowModelOpen}
//             onClose={() => setIsPayNowModelOpen(false)}
//           >
//             <img src={Logo} alt="digitalTenantLogo" className="m-3" />
//             {/* <MdOutlineErrorOutline color="red" size={50} /> */}
//             <EmbeddedCheckoutProvider stripe={stripeKey} options={options}>
//               <EmbeddedCheckout />
//             </EmbeddedCheckoutProvider>
//           </ModalLayout1>
//         )}
//         {/* <ModalLayout1
//           isOpen={isPayNowModelOpen}
//           onClose={() => setIsPayNowModelOpen(false)}
//         >
//           <EmbeddedCheckoutProvider stripe={stripeKey} options={options}>
//             <EmbeddedCheckout />
//           </EmbeddedCheckoutProvider>
//         </ModalLayout1> */}
//       </div>
//     </>
//   );
// };

// export default Payment;
