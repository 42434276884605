import React, {useEffect, useState} from "react";
import SideDrawer from "Components/SideDrawer/SideDrawer";
import TitleTopBar from "Helpers/TitleTopBar";
import Notification from "Components/Notification/Notification";
import ResponsiveSideBar from "Components/ResponsiveSideBar/ResponsiveSideBar";
import { useSelector } from "react-redux";
import config from "Helpers/config.js";
import axios from "axios";
// import {updateNotifications} from "../../Store/Slices/Notifications";
import { Puff } from 'react-loader-spinner'


const NotificationPage = () => {
  const drawer = useSelector((state) => {
    return state.sideDrawer.drawerState;
  });

  // const [isNotification, setIsNotification] = useState(false);
  const [notifications, setNotifications] = useState();

  // const dispatch = useDispatch()

// const userId = localStorage.getItem('user_id');

// sconst allNotifications = useSelector(state => state.notifications);
// console.log("🚀 ~ allNotifications:", allNotifications)

// useEffect(() => {
//   dispatch(updateNotifications());
// }, []);

  useEffect(() => {
    const notificationData = async () => {
      try {
        const response = await axios.get(`${config.baseUrl}/api/notifications`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        setNotifications(response?.data?.message?.data);
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
      }
    };
  
    notificationData();
  }, []);
  

  return (
    <>
      <div className="container-fluid">
        <div className="row hv-100">
          <div className="col-xl-2 p-sm-0 d-md-none d-lg-none d-xl-block d-none position-relative drawer-bg-primary">
            <SideDrawer />
          </div>
          <div
            className={
              drawer === true ? "res-drawer-bg-open" : "res-drawer-bg-close"
            }
          >
            <div
              className={
                drawer === true
                  ? "drawer-bg-primary responsive-drawer-container-open"
                  : "drawer-bg-primary responsive-drawer-container "
              }
            >
              <ResponsiveSideBar />
            </div>
          </div>
          <div className="col-xl-10 col-lg-12 col-md-12 mt-3">
            <div className="right-component bg-white h-100">
              <div className="container-fluid bg-default">
                <TitleTopBar topBarTitle="Notification" />
              </div>
             {notifications?.length > 0 ?  notifications?.map((notificationsData) => {
                // console.log("🚀 ~ {notifications?.length>0?notifications?.map ~ notificationsData:", notificationsData)
                return(
                  <div key={notificationsData?._id}>
                  <Notification 
                  profileImage={notificationsData?.profileImage}
                  firstName={notificationsData?.sender?.firstName}
                  lastName={notificationsData?.sender?.lastName}
                  message={notificationsData?.message}
                  createdAt={notificationsData?.createdAt}
                  redirectUrl={notificationsData?.redirectUrl}
                  _id= {notificationsData?._id}
                  />
                  </div>
                )
             }) : 
             <div style={{display: 'flex', justifyContent: 'center', alignSelf: 'center', marginTop: '50px'}}>
             <Puff
             visible={true}
             height="80"
             width="80"
             color="#ef6b3e"
             ariaLabel="puff-loading"
             wrapperStyle={{}}
             wrapperClass=""
             />
             </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationPage;
