import { useEffect, useRef, useState } from "react";
import { message } from "antd";
import PropertyAddSuccessModal from "Modals/PropertyAddSuccessModal/PropertyAddSuccessModal";
import PurchaseUnit from "Modals/PurchaseUnit/PurchaseUnit";
import UseGetHook from "Hooks/UseGetHook";
import config from "Helpers/config.js";
import * as InnerComponent from "./index";
import { validatePropertyDetails, validateUnitInfo } from "utils/validations";
import { createProperty } from "network/landlord/property";
import { SuccessModal } from "Components/GeneralComponents";
import { useNavigate } from "react-router-dom";

const AddPropertyDetails = () => {
  // Choose Property Type states
  const navigate = useNavigate();
  const [unitValue, setUnitValue] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPropertyType, setSelectedPropertyType] = useState("");

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedPropertyType("");
  };

  const handlePropertyTypeClick = (propertyType) => {
    setSelectedPropertyType(propertyType);
  };
  // Choose Property Type states end

  // States start
  const [currentStep, setCurrentStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [Images, setImages] = useState([]);
  const [selectedOptions, setselectedOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [inputHeight, setInputHeight] = useState("45px");
  const [address2, setAddress2] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const { FetchUser, user } = UseGetHook("userinfo");

  useEffect(() => {
    FetchUser();
  }, []);

  const [form, setForm] = useState({
    title: "",
    status: "",
    amenities: [],
    bedroom: "",
    bathroom: "",
    area: "",
    rent: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    description: "",
  });
  const [unitInfo, setUnitInfo] = useState({
    propertyUnit: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (fieldName, value) => {
    if (fieldName === "amenities") {
      setForm({
        ...form,
        amenities: value,
      });
    } else {
      setForm({
        ...form,
        [fieldName]: value,
      });
    }
  };

  const handleUnitInfoChange = (fieldName, value) => {
    setUnitInfo({
      ...unitInfo,
      [fieldName]: value,
    });
  };
  // States end
  const dropdownRef = useRef(null);
  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  // Modal Function
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
    navigate("/properties-dashboard");
  };
  const onOpenAccountModal = () => {
    setOpenAccountModal(true);
  };
  const onCloseModalWorkOrder = () => {
    setOpenAccountModal(false);
  };
  // Create Property

  const CreateProperty = () => {
    const formData = new FormData();
    console.log("form", form);
    formData.append("title", form.title);
    formData.append("status", form.status);
    formData.append("address[address_line_1]", form.address);
    formData.append("address[address_line_2]", form.address2);
    formData.append("address[zipcode]", parseInt(form.zipCode));
    formData.append("address[state]", form.state);
    formData.append("address[city]", form.city);
    formData.append("address[country]", "USA");
    formData.append("description", form.description);
    formData.append("bedroom", form.bedroom);
    formData.append("bathroom", form.bathroom);
    formData.append("area", form.area);
    formData.append("rent_amount", form.rent.amount);
    formData.append("unit", unitInfo.propertyUnit);
    formData.append("property_type", selectedCategory);
    formData.append("property_sub_type", selectedPropertyType);
    Images.forEach((img) => {
      formData.append("images", img);
    });
    console.log("form Amenities", form);
    form.amenities.forEach((amenity, i) => {
      formData.append(`amenities[${[i]}]`, amenity);
    });

    setLoader(true);
    createProperty(formData).then((res) => {
      if (res.apiCallStatus === "success") {
        onOpenModal();
        setLoader(false);
      } else {
        console.log(res);
      }
    });
  };
  // Stepper Function
  const handleNext = () => {
    if (currentStep === 1) {
      if (selectedCategory && selectedPropertyType) {
        setCurrentStep(currentStep + 1);
      } else {
        message.error("Please select property type to continue");
      }
    }

    if (currentStep === 2) {
      const newErrors = validatePropertyDetails(
        form,
        selectedCategory === "commercial"
      );
      if (Object.keys(newErrors).length === 0) {
        setErrors({});
        setCurrentStep(currentStep + 1);
      } else {
        setErrors(newErrors);
      }
    }
    if (currentStep === 3) {
      const newErrors = validateUnitInfo(unitInfo);
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        setErrors({});
        CreateProperty();
      }
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const purchaseUnit = () => {
    fetch(`${config.baseUrl}/api/stripe/purchaseUnit`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        quantity: unitValue,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.apiCallStatus === "success") {
          onCloseModalWorkOrder();
          CreateProperty();
        } else {
          console.log(res);
        }
      })
      .catch((error) => console.log(error, "error"));
  };
  useEffect(() => {
    // Calculate input height based on the number of selected options
    if (inputRef.current) {
      const numSelectedOptions = selectedOptions.length;
      let inputHeightValue =
        numSelectedOptions >= 8 ? `${numSelectedOptions * 7}px` : "45px";
      if (window.innerWidth <= 375) {
        if (numSelectedOptions >= 3) {
          inputHeightValue = `${numSelectedOptions * 30}px`;
        }
      } else if (window.innerWidth <= 425) {
        if (numSelectedOptions >= 3) {
          inputHeightValue = `${numSelectedOptions * 25}px`;
        }
      } else if (window.innerWidth <= 768) {
        if (numSelectedOptions >= 4) {
          inputHeightValue = `${numSelectedOptions * 11}px`;
        }
      }

      setInputHeight(inputHeightValue);
    }
  }, [selectedOptions, windowWidth]); // Update when selectedOptions change

  return (
    <>
      {openAccountModal === true ? (
        <PurchaseUnit
          onOpen={onOpenModal}
          onClose={onCloseModalWorkOrder}
          setUnitValue={setUnitValue}
          callBack={purchaseUnit}
          value={unitValue}
        />
      ) : (
        ""
      )}
      {openModal === true && (
        <SuccessModal
          heading={"Property Added Successfully"}
          title={
            "Your property has been successfully added. Check your properties"
          }
          route={"all-accounts"}
          success={"Add Bank Account"}
          onClose={onCloseModal}
        />
      )}
      <div className="container-fluid bg-white p-3">
        <div className="container">
          <InnerComponent.Stepper currentStep={currentStep} />
          <div className="stepper-content-container mt-4 add-property-details-stepper-content">
            {currentStep === 1 && (
              <InnerComponent.PropertyTypeForm
                selectedCategory={selectedCategory}
                selectedPropertyType={selectedPropertyType}
                handleCategoryClick={handleCategoryClick}
                handlePropertyTypeClick={handlePropertyTypeClick}
                handleNext={handleNext}
              />
            )}
            {currentStep === 2 && (
              <InnerComponent.PropertyDetailsForm
                form={form}
                handleChange={handleChange}
                errors={errors}
                Images={Images}
                setImages={setImages}
                handlePrev={handlePrev}
                handleNext={handleNext}
                choosePropertyTypeCommercial={selectedCategory === "commercial"}
                // windowWidth={windowWidth}
              />
            )}
            {currentStep === 3 && (
              <InnerComponent.UnitInformationForm
                form={unitInfo}
                handleChange={handleUnitInfoChange}
                errors={errors}
                isSubmitForm={true}
                handlePrev={handlePrev}
                handleNext={handleNext}
                isLoader={loader}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPropertyDetails;
