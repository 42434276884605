/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import CloudImg from "assets/Illustration.png";
import plusIconOrange from "assets/plus-orange.png";
import searchIcon from "assets/search.png";
import activeDot from "assets/_Dot.png";
import inactiveDot from "assets/inactivedot.png";
import trashIconWhite from "assets/trash-icon-white.png";
import { Table, ConfigProvider, Tooltip } from "antd";
import { useNavigate, Link } from "react-router-dom";
import UserPermission from "libs/UserPermission";
import { formatPhoneNumber } from "utils";
import helpIcon from "assets/help-circle.png";
import * as Icon from "assets/icons";
import * as Component from "Components/GeneralComponents";
import { getTenants } from "network/landlord/tenant";

const TenantsTab = ({ id }) => {
  const { ROLE } = UserPermission();
  const filter = {
    property: id,
  }
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [key, setKey] = useState([]);
  const [isDeleteTenantModal, setIsDeleteTenantModal] = useState(false);
  const [isRerender, setIsRerender] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tenantData, setTenantData] = useState([]);
  const [leases, setLeases] = useState([]);
  const [filteredTenants, setFilteredTenants] = useState([]);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  // handle get tenants with filters
  useEffect(() => {
    setIsLoading(true);
    getTenants(filter).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        setTenantData(
          res.message.data
            .filter((e) => e.name.toLowerCase().includes(search.toLowerCase()))
            .map((e) => ({
              key: e.id,
              name: e.name,
              img: e?.profileImage,
              properties: e.properties,
              email: e.email,
              phone: e.phone,
              status: e.status,
            }))
        );
      } else {
        console.log("Error in fetching tenant data");
      }
    });
  }, [isRerender]);

  // handle search
  useEffect(() => {
    if (search) {
      setFilteredTenants(
        tenantData.filter((item) => {
          if (item.name.toLowerCase().includes(search.toLowerCase()))
            return item;
          if (item.email.toLowerCase().includes(search.toLowerCase()))
            return item;
          if (item.phone.includes(search)) return item;
          if (item.status.toLowerCase().includes(search.toLowerCase()))
            return item;
          if (item.properties.includes(search)) return item;
        })
      );
    } else {
      setFilteredTenants(tenantData);
    }
  }, [search, tenantData]);

  // Data Table Functions
  const handleIconClick = (result) => {
    const filterData = leases.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, name) => (
        <>
          <Link to={`/tenant-details-view?id=${name.key}`}>
            <span className="tenants_table_name_text">
              <img
                className="me-2 property-table-image mw_40 mh_40 me-2 rounded-5"
                src={`${name.img}`}
                alt=""
              />
              {text}
            </span>
          </Link>
        </>
      ),
    },
    {
      title: (
        <>
          Properties
          <Tooltip title={"Non-Expired Leases Properties"}>
            <img src={helpIcon} alt="" className="cursor ps-2" />
          </Tooltip>
        </>
      ),
      dataIndex: "properties",
      render: (text, add) => (
        <>
          <span className="tenants_table_properties_text">
            <Component.IconList properties={text} />
          </span>
        </>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      render: (text) => (
        <>
          <span className="tenants_table_email_text">{text}</span>
        </>
      ),
    },
    {
      title: (
        <>
          Phone No
          <Tooltip title={"Tenant Phone Number"}>
            <img src={helpIcon} alt="" className="cursor ps-2" />
          </Tooltip>
        </>
      ),
      dataIndex: "phone",
      render: (text) => (
        <>
          {" "}
          <span className="tenants_table_phone_text phone">
            {formatPhoneNumber(text)}
          </span>
        </>
      ),
    },
    {
      title: (
        <>
          Status
          <Tooltip title={"Shows if tenant account is created"}>
            <img src={helpIcon} alt="" className="cursor ps-2" />
          </Tooltip>
        </>
      ),
      dataIndex: "status",
      render: (text, status) => (
        <>
          <div
            className={
              text === "Active"
                ? "prospect-active-bar"
                : "prospect-inactive-bar"
            }
          >
            <img src={text === "Active" ? activeDot : inactiveDot} alt="" />{" "}
            <span>{text}</span>
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleView: () => {
                  console.log("View Clicked: ", setting.key);
                  navigate(`/tenant-details-view?id=${setting.key}`);
                },
              }}
              fields={{
                view: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="container-fluid bg-white">
        <div className="row mb-3">
          <div className="col-md-12">
            <div className="task-search-input position-relative">
              <input
                type="text"
                placeholder="Search"
                className="form-control search-form-control-task"
                onChange={(e) => setSearch(e.target.value)}
              />
              <div className="search-icon-task">
                <img src={searchIcon} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="text-center main-screen-properties-content d-none">
          <img src={CloudImg} alt="" />
          <p className="property-main-text">No tenants found</p>
          <p className="property-sub-text">
            No tenants were found; the folder is empty.
            <br />
            Please try again.
          </p>
          <button className="add-property-btn-white">
            <img
              src={plusIconOrange}
              className="add-property-icon-white"
              alt=""
            />{" "}
            Add Tenants
          </button>
        </div>
        <div className="row">
          <div className="col-md-12">
            {selectedTableItem.length !== 0 && (
              <div className="table-delete-icon mb-3">
                <button
                  onClick={() => setIsDeleteTenantModal(true)}
                  className="table-delete-btn next-btn-main"
                >
                  <img src={trashIconWhite} alt="" />
                  Delete
                </button>
              </div>
            )}
            <Component.CustomTable 
              columns={columns} 
              dataSource={filteredTenants} 
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TenantsTab;
