import React, { useEffect, useState } from "react";
import { message } from "antd";
import {
  validateLeaseInfoForm,
  validateLeaseTenantsForm,
} from "utils/validations";
import { leaseFormData } from "utils/FormDataStructure";
import { createLease } from "network/landlord/lease";
import * as InnerComponents from "./index";
import TempModal from "Modals/TempModal/TempModal";
import { getProspectById } from "network/landlord/prospect";
import { useParams } from "react-router-dom";

const ConvertToTenant = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [selectedPropertyType, setSelectedPropertyType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [leaseFiles, setLeaseFiles] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [properties, setProperties] = useState([]);
  const [popUpMsg, setPopUpMsg] = useState("");
  const { id } = useParams();
  const [form, setForm] = useState({
    property: {
      id: "",
      heading1: "",
      heading2: "",
      heading3: "",
    },
    unit: "",
    lease_term: "",
    lease_term_start_date: "",
    lease_term_end_date: "",
    first_rent_date: "",
    frequency: "",
    rent_amount: {
      amount: "",
    },
    security_deposit: "",
    security_amount: {
      amount: "",
    },
    late_fee: "",
    late_fee_charge: "",
    late_fee_frequency: "",
    late_fees_amount: {
      amount: "",
      type: "$",
    },
    renewLease: "",
    tenant: {
      _id: "",
      name: "",
      email: "",
      type: "",
    },
    coTenants: [],
  });
  const [dependentInfo, setDependentInfo] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      email: "",
      phone: "",
      relationship: "",
      description: "",
    },
  ]);
  const [pets, setPets] = useState([
    {
      petType: "",
      petName: "",
      petWeight: "",
      petBreed: "",
    },
  ]);
  const [vehicles, setVehicles] = useState([
    {
      vehicleType: "",
      vehicleMake: "",
      vehicleModel: "",
      vehicleYear: "",
      vehicleColor: "",
      vehicleLicense: "",
    },
  ]);
  const [documentInfo, setDocumentInfo] = useState({
    isSigned: "",
    newLeaseSign: "",
  });

  const handleChange = (fieldName, value, index) => {
    if (fieldName === "coTenant") {
      const newCoTenants = [...form.coTenants];
      newCoTenants[index] = value;
      setForm({
        ...form,
        coTenants: newCoTenants,
      });
      return;
    }
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  useEffect(() => {
    console.log("ID => ", id);

    getProspectById(id).then((res) => {
      if (res.apiCallStatus === "success") {
        setForm({
          property: {
            id: res.message.interestedIn.property,
            heading1: "",
            heading2: "",
            heading3: "",
          },
          unit: res.message.interestedIn.unit,
          lease_term: "",
          lease_term_start_date: "",
          lease_term_end_date: "",
          first_rent_date: "",
          frequency: "",
          rent_amount: {
            amount: "",
          },
          security_deposit: "",
          security_amount: {
            amount: "",
          },
          late_fee: "",
          late_fee_charge: "",
          late_fee_frequency: "",
          late_fees_amount: {
            amount: "",
            type: "$",
          },
          renewLease: "",
          tenant: {
            _id: res.message.id,
            name: `${res.message.firstName} ${res.message.middleName} ${res.message.lastName}`,
            email: res.message.email,
            type: "Prospect",
          },
          coTenants: [],
        });
        res.message.dependent.length > 0 &&
          setDependentInfo(
            res.message.dependent.map((dependent) => ({
              firstName: dependent.first_name,
              middleName: dependent.middle_name,
              lastName: dependent.last_name,
              dob: dependent.dob,
              email: dependent.email,
              phone: dependent.phone,
              relationship: dependent.relationship,
            }))
          );
        res.message.pets.length > 0 &&
          setPets(
            res.message.pets.map((pet) => ({
              petType: pet.pet_type,
              petName: pet.name,
              petWeight: pet.weight,
              petBreed: pet.breed,
            }))
          );
        res.message.vehicles.length > 0 &&
          setVehicles(
            res.message.vehicles.map((vehicle) => ({
              vehicleType: vehicle.vehicle_type,
              vehicleMake: vehicle.make,
              vehicleModel: vehicle.model,
              vehicleYear: vehicle.year,
              vehicleColor: vehicle.color,
              vehicleLicense: vehicle.license,
            }))
          );
      } else {
        message.error(res.message);
      }
    });
  }, []);

  useEffect(() => {
    if (form.security_deposit === "No") {
      setForm((prevForm) => ({
        ...prevForm,
        security_amount: {
          ...prevForm.security_amount,
          amount: "",
        },
      }));
    }
  }, [form.security_deposit]);

  useEffect(() => {
    if (form.late_fee === "No") {
      setForm((prevForm) => ({
        ...prevForm,
        late_fee_charge: "",
        late_fee_frequency: "",
        late_fees_amount: {
          ...prevForm.late_fees_amount,
          amount: "",
        },
      }));
    }
  }, [form.late_fee]);

  const handleDocumentInfoChange = (fieldName, value) => {
    setDocumentInfo({
      ...documentInfo,
      [fieldName]: value,
    });
  };

  const handlePetChange = (field, index, value) => {
    const newPets = [...pets];
    newPets[index][field] = value;
    setPets(newPets);
  };

  const handleDependentChange = (field, index, value) => {
    const newDependents = [...dependentInfo];
    newDependents[index][field] = value;
    setDependentInfo(newDependents);
  };

  const addAnotherPet = () => {
    setPets([
      ...pets,
      { petType: "", petName: "", petWeight: "", petBreed: "" },
    ]);
  };

  const removePet = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedPets = pets.filter((pet, index) => index !== indexToRemove);
      setPets(updatedPets);
    }
  };

  const addAnotherDependent = () => {
    setDependentInfo([
      ...dependentInfo,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        email: "",
        phone: "",
        relationship: "",
        description: "",
      },
    ]);
  };

  const removeDependent = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedDependents = dependentInfo.filter(
        (dependent, index) => index !== indexToRemove
      );
      setDependentInfo(updatedDependents);
    }
  };

  const handleVehicleChange = (field, index, value) => {
    const newVehicles = [...vehicles];
    newVehicles[index][field] = value;
    setVehicles(newVehicles);
  };

  const addAnotherVehicle = () => {
    setVehicles([
      ...vehicles,
      {
        vehicleType: "",
        vehicleMake: "",
        vehicleModel: "",
        vehicleYear: "",
        vehicleColor: "",
        vehicleLicense: "",
      },
    ]);
  };

  const removeVehicle = (indexToRemove) => {
    if (indexToRemove !== 0) {
      const updatedVehicles = vehicles.filter(
        (vehicle, index) => index !== indexToRemove
      );
      setVehicles(updatedVehicles);
    }
  };

  const handleAddCoTenant = () => {
    setForm({
      ...form,
      coTenants: [
        ...form.coTenants,
        { _id: "", name: "", email: "", type: "" },
      ],
    });
  };

  const handleRemoveCoTenant = (index) => {
    const updatedCoTenants = form.coTenants.filter((tenant, i) => i !== index);
    setForm({
      ...form,
      coTenants: updatedCoTenants,
    });
  };

  const handleBackButton = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleLeaseInfo = () => {
    const newErrors = validateLeaseInfoForm(form);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleTenantInfo = () => {
    const newErrors = validateLeaseTenantsForm(form);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleAddLease = () => {
    const newErrors =
      documentInfo.isSigned === "" ? { isSigned: "Signed is required" } : {};
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const formData = leaseFormData(
        form,
        leaseFiles,
        dependentInfo,
        pets,
        vehicles,
        documentInfo
      );
      setIsLoading(true);
      createLease(formData).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          setPopUpMsg(res.message.popupMessage);
          setOpenModal(true);
        } else {
          message.error(res.error.message);
        }
      });
    }
  };

  const handleNext = () => {
    switch (currentStep) {
      case 1:
        handleLeaseInfo();
        break;
      case 2:
        handleTenantInfo();
        break;
      case 3:
        setCurrentStep(currentStep + 1);
        break;
      case 4:
        selectedPropertyType === "commercial"
          ? handleAddLease()
          : setCurrentStep(currentStep + 1);
        break;
      case 5:
        setCurrentStep(currentStep + 1);
        break;
      case 6:
        handleAddLease();
        break;
    }
  };

  return (
    <>
      {openModal && (
        <TempModal
          onClose={() => setOpenModal(false)}
          title={popUpMsg ? popUpMsg : "Successfully converted to tenant."}
          success="Go To Prospects"
          route="all-prospect"
        />
      )}
      <div className="container-fluid p-3">
        <div className="container">
          <InnerComponents.Stepper
            currentStep={currentStep}
            isCommercial={selectedPropertyType === "commercial"}
          />
          <div className="stepper-content-container mt-4">
            {currentStep === 1 && (
              <InnerComponents.LeaseInfoForm
                form={form}
                handleChange={handleChange}
                errors={errors}
                properties={properties}
                leaseFiles={leaseFiles}
                setLeaseFiles={setLeaseFiles}
                handlePropertyType={setSelectedPropertyType}
                handleNext={handleNext}
              />
            )}
            {currentStep === 2 && (
              <InnerComponents.TenantInfoForm
                form={form}
                handleChange={handleChange}
                errors={errors}
                handleAddCoTenant={handleAddCoTenant}
                handleRemoveCoTenant={handleRemoveCoTenant}
                handleBackButton={handleBackButton}
                handleNext={handleNext}
              />
            )}
            {selectedPropertyType !== "commercial" && currentStep === 3 && (
              <InnerComponents.DependentInfoForm
                dependentInfo={dependentInfo}
                handleChange={handleDependentChange}
                addAnotherDependent={addAnotherDependent}
                removeDependent={removeDependent}
                handleBackButton={handleBackButton}
                handleNext={handleNext}
              />
            )}
            {selectedPropertyType !== "commercial" && currentStep === 4 && (
              <InnerComponents.PetsForm
                pets={pets}
                handleChange={handlePetChange}
                handleBackButton={handleBackButton}
                handleNext={handleNext}
                addAnotherPet={addAnotherPet}
                removePet={removePet}
              />
            )}
            {((selectedPropertyType === "commercial" && currentStep === 3) ||
              currentStep === 5) && (
              <InnerComponents.VehicleForm
                vehicles={vehicles}
                handleChange={handleVehicleChange}
                handleBackButton={handleBackButton}
                handleNext={handleNext}
                addAnotherVehicle={addAnotherVehicle}
                removeVehicle={removeVehicle}
                isLoading={isLoading}
              />
            )}
            {((selectedPropertyType === "commercial" && currentStep === 4) ||
              currentStep === 6) && (
              <InnerComponents.DocumentInfoForm
                form={documentInfo}
                handleChange={handleDocumentInfoChange}
                errors={errors}
                leaseFiles={leaseFiles}
                setLeaseFiles={setLeaseFiles}
                handlePropertyType={setSelectedPropertyType}
                handleBackButton={handleBackButton}
                handleNext={handleNext}
                isLoading={isLoading}
                isSubmitForm={true}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConvertToTenant;
