import React from "react";
import homeIconTwo from "assets/home-03 (1).png";
import bedroomIcon from "assets/Vector (5).png";
import bathroomIcon from "assets/Vector (6).png";
import sizeIcon from "assets/layout-alt-04 (1).png";

const UnitDescription = ({unitData}) => {
  return (
    <div className="mb-5">
      <div>
        <div className="col-md-12">
          <p className="properties_details_view_description_content">
            {unitData?.description || "Not Set"}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 flex-bottom">
          <div className="row text-center">
            <div className="col">
              <div className="units-view-counter d-flex justify-content-center align-items-center gap-3">
                <img alt="" src={homeIconTwo} className="units-view-fa-2x" />
                <div className="units-view-counter-details">
                  <p className="units-view-count-text ">Market Value</p>
                  <p className="timer units-view-count-title count-number">
                    ${unitData?.rent_amount || 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="units-view-counter d-flex justify-content-center align-items-center gap-3">
                <img alt="" src={bedroomIcon} className="units-view-fa-2x" />
                <div className="units-view-counter-details">
                  <p className="units-view-count-text ">Bedrooms</p>
                  <h2 className="timer units-view-count-title count-number">
                    {unitData?.bedroom || 0}
                  </h2>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="units-view-counter d-flex justify-content-center align-items-center gap-3">
                <img alt="" src={bathroomIcon} className="units-view-fa-2x" />
                <div className="units-view-counter-details">
                  <p className="units-view-count-text ">Bathrooms</p>
                  <h2 className="timer units-view-count-title count-number">
                    {unitData?.bathroom || 0}
                  </h2>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="units-view-counter d-flex justify-content-center align-items-center gap-3">
                <img alt="" src={sizeIcon} className="units-view-fa-2x" />
                <div className="units-view-counter-details">
                  <p className="units-view-count-text ">Size</p>
                  <h2 className="timer units-view-count-title count-number">
                    {unitData?.area || 0} sqft
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitDescription;
