import React, { useState } from "react";
import { ConfigProvider, Tabs,  } from "antd";
import settingIconOrange from "assets/dots-vertical.png";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import { OverviewTab, FilesTab, NotesTab } from "./index";
import { DeleteModal, RecordMenuPopup } from "Components/GeneralComponents";
import { deleteProspect } from "network/landlord/prospect";

const { TabPane } = Tabs;

const ProspectDetails = () => {
  const { id } = UseUrlParamsHook();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  // Delete Prospect
  const handleDeleteProspect = () => {
    setIsLoading(true);
    deleteProspect(id).then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        message.success("Prospect Deleted Successfully");
        setOpenDeleteModal(false);
        navigate("/all-prospect");
      } else {
        message.error(res.message);
      }
    });
  };

  const handleIconClickCustom = () => {
    // Toggle the dropdown state when the icon is clicked
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      {/* Delete Prospect Model */}
      <DeleteModal
        isOpen={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        message={"Are you sure you want to delete this prospect?"}
        deleteCall={handleDeleteProspect}
        isLoading={isLoading}
      />

      <div className="container-fluid bg-white p-3">
        <div className="row">
          <div className="col-md-12 text-end">
            <span className="position-relative">
              <img
                onClick={handleIconClickCustom}
                src={settingIconOrange}
                alt="Action Buttons"
                className="cursor"
              />
              <RecordMenuPopup
                isOpen={isDropdownOpen}
                onClose={() => setDropdownOpen(false)}
                fields={{ edit: true, delete: true }}
                handler={{ 
                  handleDeleteRecord: () => setOpenDeleteModal(true),
                  handleEdit: () => navigate(`/edit-prospect-details/${id}`),
                }}
              />
            </span>
          </div>
        </div>
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                inkBarColor: "#EF6B3E",
                itemColor: "#667085",
                itemSelectedColor: "#EF6B3E",
                itemHoverColor: "#EF6B3E",
                titleFontSize: 15,
                horizontalItemGutter: window.innerWidth <= 768 ? 10 : 60,
                fontFamily: "Montserrat",
              },
            },
          }}
        >
          <Tabs centered defaultActiveKey="1" style={{ fontWeight: 500 }}>
            <TabPane tab="Overview" key="1">
              <OverviewTab id={id} />
            </TabPane>
            <TabPane tab="Notes" key="4">
              <NotesTab id={id} category={"prospect"} />
            </TabPane>
            <TabPane tab="Files" key="6">
              <FilesTab id={id} category={"prospect"} />
            </TabPane>
          </Tabs>
        </ConfigProvider>
      </div>
    </>
  );
};

export default ProspectDetails;
