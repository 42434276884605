import {
  condo,
  multifamily,
  office,
  other,
  Parking,
  shopingmall,
  singlefamily,
  TownHouse,
} from "assets/svg/index";

function convertTimestamp(timestamp) {
  var date = new Date(timestamp);
  var now = new Date();
  var timeDifference = now.getTime() - date.getTime();
  var seconds = Math.floor(timeDifference / 1000);
  var minutes = Math.floor(seconds / 60);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);
  var months = Math.floor(days / 30);
  if (months > 0) {
    return months + (months === 1 ? " month ago" : " months ago");
  } else if (days > 0) {
    return days + (days === 1 ? " day ago" : " days ago");
  } else if (hours > 0) {
    return hours + (hours === 1 ? " hour ago" : " hours ago");
  } else if (minutes > 0) {
    return minutes + (minutes === 1 ? " minute ago" : " minutes ago");
  } else {
    return seconds + (seconds === 1 ? " second ago" : " seconds ago");
  }
}

function formatDate(isoString) {
  const date = new Date(isoString);
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const year = date.getUTCFullYear();
  return `${month}/${day}/${year}`;
}

const formatPhoneNumber = (input) => {
  if (!input) return "";

  let formattedNumber = "";

  if (input.length > 0) {
    formattedNumber = `(${input.slice(0, 3)}`;

    if (input.length > 3) {
      formattedNumber += `) ${input.slice(3, 6)}`;
    }

    if (input.length > 6) {
      formattedNumber += `-${input.slice(6, 10)}`;
    }
  }

  return formattedNumber;
};

const calculateAge = (dateOfBirth) => {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const removeEmptyValues = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) => value !== "" && value !== undefined && value !== null
    )
  );
};

const checkPasswordCriteria = (password) => {
  const hasMinimumLength = /.{8,}/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasSpecialCharacter = /[!@#$%^&*()_+={}\[\]:;,<.>?/\\~-]/.test(
    password
  );

  return {
    hasMinimumLength,
    hasNumber,
    hasUpperCase,
    hasSpecialCharacter,
  };
};

const propertyIcon = (type) => {
  switch (type) {
    case "shopping_center":
      return shopingmall;
    case "single_family":
      return singlefamily;
    case "multi_family":
      return multifamily;
    case "townhouse":
      return TownHouse;
    case "residential_others":
      return other;
    case "office":
      return office;
    case "commercial_Storage":
      return other;
    case "parking":
      return Parking;
    case "commercial_others":
      return other;
    case "condo":
      return condo;
    default:
      return ;
  }
};

export {
  convertTimestamp,
  formatDate,
  formatPhoneNumber,
  calculateAge,
  removeEmptyValues,
  checkPasswordCriteria,
  propertyIcon,
};
export { usaStates } from "./States";
