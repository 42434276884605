import React from "react";
import {
  InputField,
  PhoneNumberField,
  DateField,
  SelectField,
  SubmitButton,
  PasswordField,
  AmountField,
  TextAreaField,
  MediaField,
} from "Components/GeneralComponents";
import { selectIncomeFrequency, employmentStatus } from "utils/dropdownData";
import { formatDate } from "utils";

const CreditAndBackground = ({
  form,
  errors,
  Images,
  setImages,
  handleChange,
  handleNext,
  handlePrev,
}) => {
  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="col-md-12">
          <AmountField
            name={"yearlyIncome"}
            required={true}
            label={"Yearly Income ($)"}
            placeholder={"Yearly Income"}
            value={form.yearlyIncome}
            handler={(value) => handleChange("yearlyIncome", value)}
            errorMessage={errors.yearlyIncome}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <TextAreaField
            name={"Add Details"}
            label={"Add Details"}
            placeholder={"Add your details here"}
            value={form.details}
            handler={(e) => handleChange("details", e.target.value)}
            rows={"10"}
            cols={"30"}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
          <MediaField
            Images={Images}
            setImages={setImages}
          />
        </div>
      </div>
      {/* <div className="row mt-3">
        <div className="col-md-6">
          <InputField
            name={"Other Income"}
            required={true}
            label={"Other Income"}
            type="number"
            placeholder={"Other Income"}
            value={form.otherIncome}
            handler={(e) => handleChange("otherIncome", e.target.value)}
            errorMessage={errors.otherIncome}
          />
        </div>
        <div className="col-md-6">
          <SelectField
            name={"Other Income Frequency"}
            required={true}
            label={"Other Income Frequency"}
            placeholder={"Select Other Income Frequency"}
            value={form.otherIncomeFrequency}
            options={selectIncomeFrequency}
            handler={(e) => handleChange("otherIncomeFrequency", e)}
            errorMessage={errors.otherIncomeFrequency}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <InputField
            name={"Assets"}
            required={true}
            label={"Assets"}
            type="number"
            placeholder={"Assets"}
            value={form.assets}
            handler={(e) => handleChange("assets", e.target.value)}
            errorMessage={errors.assets}
          />
        </div>
        <div className="col-md-6">
          <SelectField
            name={"Employment Status"}
            required={true}
            label={"Employment Status"}
            placeholder={"Select Employment Status"}
            value={form.employmentStatus}
            options={employmentStatus}
            handler={(e) => handleChange("employmentStatus", e)}
            errorMessage={errors.employmentStatus}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6">
          <PasswordField
            name={"SSN"}
            required={true}
            label={"SSN"}
            placeholder={"SSN"}
            value={form.ssn}
            handler={(e) => handleChange("ssn", e.target.value)}
            errorMessage={errors.password}
          />
        </div>
        <div className="col-md-6">
          <DateField
            required={true}
            label={"Date of Birth"}
            placeholder={"Select Date"}
            defaultValue={form.dob && formatDate(form.dob)}
            handler={(e) => {
              const formattedDate = new Date(e).toLocaleDateString();
              handleChange("dob", formattedDate);
            }}
            errorMessage={errors.dob}
          />
        </div>
      </div> */}
      <div className="stepper-btn d-flex justify-content-between mt-5 gap-1">
        <div style={{ width: "50%" }}>
          <SubmitButton type="contained" handler={handlePrev}>
            Back
          </SubmitButton>
        </div>
        <div style={{ width: "50%" }}>
          <SubmitButton handler={handleNext}>Next</SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default CreditAndBackground;
