import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import SideDrawer from "Components/SideDrawer/SideDrawer";
import TenantReports from "Components/TenantReports/TenantReports";
import TaskReports from "Components/TaskReports/TaskReports";
import ResponsiveSideBar from "Components/ResponsiveSideBar/ResponsiveSideBar";
import { useSelector } from "react-redux";

const TaskReportsPage = () => {
  const drawer = useSelector((state) => {
    return state.sideDrawer.drawerState;
  });
  return (
    <>
      <div className="container-fluid">
        <TaskReports />
      </div>
    </>
  );
};

export default TaskReportsPage;
