import Loader from "Helpers/Loader";
import React from "react";
import { formatPhoneNumber } from "utils";

const LandlordInfoTab = ({ data, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="row mt-5">
            <div className="col-md-10"></div>

            <div className="d-flex flex-column flex-md-row">
              <div className="flex-grow-1 order-md-0 order-1">
                <div className="task-info-heading">
                  <h4>Personal Info</h4>
                </div>
                <div className=" d-flex flex-column">
                  <div className="mt-5 task-info-list ">
                    <div className="">
                      <p>
                        <span className="task-info-list-span me-3">
                          Landlord Name:
                        </span>{" "}
                        {data?.name || "N/A"}
                      </p>
                    </div>
                    <div className="">
                      <p>
                        <span className="task-info-list-span me-3">
                          Company Name:
                        </span>{" "}
                        {data?.companyName || "N/A"}
                      </p>
                    </div>
                    <div className="">
                      <p>
                        <span className="task-info-list-span me-3">
                          Phone No:
                        </span>{" "}
                        {formatPhoneNumber(data?.phone) || "N/A"}
                      </p>
                    </div>
                    <div className="">
                      <p>
                        <span className="task-info-list-span me-3">
                          Company Phone:
                        </span>{" "}
                        {formatPhoneNumber(data?.companyPhone) || "N/A"}
                      </p>
                    </div>
                    <div className="">
                      <p>
                        <span className="task-info-list-span me-3">Email:</span>{" "}
                        {data?.email || "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="order-md-1 order-0 position-relative"
                style={{
                  marginBottom: "15px",
                  width: "180px",
                  height: "180px",
                }}
              >
                <img
                  className="img-fluid rounded-circle w-100 h-100 object-cover position-absolute top-0 start-0"
                  src={data?.companyLogo || "https://via.placeholder.com/150"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LandlordInfoTab;
