import React from "react";
import { Link } from "react-router-dom";
import { LoginSocialFacebook } from "reactjs-social-login";
import * as component from "Components/GeneralComponents";
import Or from "assets/login-or-image.png";
import facebookIcon from "assets/fb login.png";
import googleIcon from "assets/google login.png";

const ServiceProfessionalLogin = ({
  form,
  errors,
  googleAuth,
  resolveFacebook,
  rejectFacebook,
  handleSubmit,
  handleChange,
}) => {
  return (
    <>
      <div className="land-lord-sign-in pt-2 ps-4">
        <div className="onboarding-right-heading text-start mb-5">
          <h1 className="mt-5">Login to Service Pro Portal</h1>
          <div className="col-md-12 mt-3 not-a-member">
            Not a member yet?{" "}
            <Link
              className="primary-orange-text"
              to="/service-professional-sign-up"
            >
              Register Now!
            </Link>
          </div>
          <Link className="primary-orange-text" to="/">
            <svg
              width={15}
              height={15}
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11.438 18.75 4.688 12l6.75-6.75" />
              <path d="M5.625 12h13.688" />
            </svg>
            Back
          </Link>
        </div>
        <div className="sign-in-fields">
          <div className="row">
            <div className="col-md-12">
              <component.InputField
                name={"email"}
                type={"email"}
                placeholder={"Email"}
                value={form.email}
                handler={handleChange}
                errorMessage={errors.email}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <component.PasswordField
                name={"password"}
                placeholder={"Password"}
                value={form.password}
                handler={handleChange}
                errorMessage={errors.password}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="sign-up-buttons d-flex justify-content-center">
                <component.ButtonVariant1 handler={handleSubmit}>
                  Sign In
                </component.ButtonVariant1>
              </div>
            </div>
          </div>
          <div className="remember-me-checkBox d-flex justify-content-center align-items-center mt-4">
            <p className="text-end">
              <Link
                to="/landlord-forgot-password"
                className="sign-in-style-text"
              >
                Forgot Password?
              </Link>
            </p>
          </div>
          <div className="remember-me-checkBox d-flex justify-content-center">
            <p className="text-end">
              <img src={Or} alt="" style={{ width: "100%" }} />
            </p>
          </div>
          <div className="row mb-5 ">
            <div className="col-md-12">
              <div className="sign-up-buttons d-flex gap-4 justify-content-center">
                <button onClick={googleAuth} className="google-signup-btn">
                  <img src={googleIcon} alt="" style={{ width: "140px" }} />
                </button>
                <LoginSocialFacebook
                  appId="1129610314899200"
                  autoLoad={false}
                  onResolve={(response) => resolveFacebook(response)}
                  onReject={(error) => rejectFacebook(error)}
                >
                  <button className="facebook-signup-btn">
                    <img src={facebookIcon} alt="" style={{ width: "140px" }} />
                  </button>
                </LoginSocialFacebook>
              </div>
            </div>
          </div>
          <div className="row " style={{ marginTop: "50px" }}>
            <div className="col-md-12 text-center not-a-member">
              Login as{" "}
              <Link className="primary-orange-text" to="/land-lord-sign-in">
                LandLord
              </Link>
              <nbsp /> or{" "}
              <Link className="primary-orange-text" to="/tenant-sign-in">
                Tenant
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceProfessionalLogin;
