import React, { useEffect, useState } from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import SideDrawer from "Components/SideDrawer/SideDrawer";
import LandLordDashboard from "Components/LandLordDashboard/LandLordDashboard";
import ResponsiveSideBar from "Components/ResponsiveSideBar/ResponsiveSideBar";
import CustomizeSideBar from "Components/CustomizeLandlordSideDrawer/CustomizeLandlordSideDrawer";
import customizeOptionIcon from "assets/Group 1000004204.png";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
const LandLordDashboardPage = () => {
  // States
  const location = useLocation();
  const drawer = useSelector((state) => {
    return state.sideDrawer.drawerState;
  });
  const [revenueToggle, setRevenueToggle] = useState(true);
  const [propertiesToggle, setPropertiesToggle] = useState(true);
  const [recentactivitiesToggle, setRecentActivitiesToggle] = useState(true);
  const [tenantToggle, setTenantToggle] = useState(true);
  const [prospectsToggle, setProspectsToggle] = useState(true);
  const [vacanciesToggle, setVacanciesToggle] = useState(true);
  const [taskToggle, setTaskToggle] = useState(true);
  const [payablesToggle, setPayablesToggle] = useState(true);
  const [mileageToggle, setMileageToggle] = useState(true);

  // state end
  // State to manage the display of the right sidebar
  const [showRightSidebar, setShowRightSidebar] = useState(false);

  // Function to toggle the right sidebar
  const toggleRightSidebar = () => {
    setShowRightSidebar(true);
  };
  const toggleRightSidebarClose = () => {
    setShowRightSidebar(false);
  };
  const handlRrevenueClick = () => {
    setRevenueToggle(!revenueToggle);
  };
  const handlPropertiesClick = () => {
    setPropertiesToggle(!propertiesToggle);
  };
  const handlRecentActivitiesClick = () => {
    setRecentActivitiesToggle(!recentactivitiesToggle);
  };
  const handlTenantsClick = () => {
    setTenantToggle(!tenantToggle);
  };
  const handlProspectsClick = () => {
    setProspectsToggle(!prospectsToggle);
  };
  const handlVacanciesClick = () => {
    setVacanciesToggle(!vacanciesToggle);
  };
  const handlTaskClick = () => {
    setTaskToggle(!taskToggle);
  };
  const handlPayablesClick = () => {
    setPayablesToggle(!payablesToggle);
  };
  const handlMileageClick = () => {
    setMileageToggle(!mileageToggle);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-2 p-sm-0 d-md-none d-lg-none d-xl-block d-none position-relative drawer-bg-primary">
            <SideDrawer dashboard={true} />
          </div>
          {location.pathname === "/dashboard" || location.pathname === "/" ? (
            <div
              className={
                drawer === true
                  ? "res-drawer-bg-open h-100"
                  : "res-drawer-bg-close"
              }
            >
              <div
                className={
                  drawer === true
                    ? "drawer-bg-primary responsive-drawer-container-open"
                    : "drawer-bg-primary responsive-drawer-container "
                }
              >
                <ResponsiveSideBar />
              </div>
            </div>
          ) : (
            <div
              className={
                drawer === true ? "res-drawer-bg-open" : "res-drawer-bg-close"
              }
            >
              <div
                className={
                  drawer === true
                    ? "drawer-bg-primary responsive-drawer-container-open"
                    : "drawer-bg-primary responsive-drawer-container "
                }
              >
                <ResponsiveSideBar />
              </div>
            </div>
          )}
          <div className="col-xl-10 col-lg-12 col-md-12 mt-3">
            <div className="container-fluid">
              <TitleTopBar topBarTitle={"Dashboard"} />
            </div>
            <LandLordDashboard
              revenue={revenueToggle}
              properties={propertiesToggle}
              recentActivities={recentactivitiesToggle}
              tenants={tenantToggle}
              prospects={prospectsToggle}
              vacancies={vacanciesToggle}
              task={taskToggle}
              payables={payablesToggle}
              mileage={mileageToggle}
            />
          </div>
        </div>
        <div
          className="row customize-landlord-dashboard-btn cursor"
          title="Customize Dashboard"
          onClick={toggleRightSidebar}
        >
          <img
            src={customizeOptionIcon}
            alt=""
            className="customize-landlord-dashboard-icon"
          />
        </div>
        <div
          className={
            showRightSidebar
              ? "right-sidebar customize-landord-dashboard-sidebar-open h-100"
              : "right-sidebar customize-landord-dashboard-sidebar-close h-100"
          }
        >
          {/* Add content and styles for the right sidebar */}
          <CustomizeSideBar
            //   ========================================================================
            revenue={handlRrevenueClick}
            properties={handlPropertiesClick}
            recentActivities={handlRecentActivitiesClick}
            tenants={handlTenantsClick}
            prospects={handlProspectsClick}
            vacancies={handlVacanciesClick}
            task={handlTaskClick}
            payables={handlPayablesClick}
            mileage={handlMileageClick}
            // ==========================================================================

            close={toggleRightSidebarClose}
          />
        </div>
        {/*  */}
      </div>
    </>
  );
};

export default LandLordDashboardPage;
