import React, { useEffect, useRef, useState } from "react";
import notificationIcon from "assets/bell-01.png";
import helpIcon from "assets/Help icon.png";
import { useNavigate, Link, NavLink } from "react-router-dom";
import unread from "assets/unread-icon.jpg";
import user from "assets/notification-img.png";
import "../hamburgers/dist/hamburgers.css";
import { useDispatch, useSelector } from "react-redux";
import { openDrawer } from "Store/Slices/SideDrawerSlice";
import axios from "axios";
import config from "Helpers/config.js";
import moment from "moment";

const notifications = [
  {
    id: 1,
    name: "Carmen Parksouth",
    notification:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt.",
    unread: true,
    time: "7:30 AM",
  },
  {
    id: 2,
    name: "Heidi Turner",
    notification: "Lorem ipsum dolor sit amet, consectetuer adipiscing eli.",
    unread: false,
    time: "3:00 PM",
  },
  {
    id: 3,
    name: "Emilly Walker",
    notification:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing eli. Explore the origins, history amous passage",
    unread: false,
    time: "3:00 PM",
  },
  {
    id: 4,
    name: "Heidi Turner",
    notification: "Lorem ipsum dolor sit amet, consectetuer adipiscing eli.",
    unread: false,
    time: "3:00 PM",
  },
];
const TitleTopBar = ({ topBarTitle, topBarTitleDetail, back, vendorText }) => {
  // States
  const [notifyData, setNotifyData] = useState();
  const [notifyDropdown, setNotifyDropdown] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  // console.log("🚀 ~ unreadNotifications:", unreadNotifications)
  const popupRef = useRef(null);
  const userId = localStorage.getItem('user_id');
  // console.log("🚀 ~ userId:", userId)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setNotifyDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Get All Notifications

  useEffect(() => {
    const notificationData = async () => {
      try {
        const response = await axios.get(`${config?.baseUrl}/api/notifications`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });

        setNotifyData(response?.data?.message?.data);
        const filterUnreadNotifications = response?.data?.message?.data.filter((notification) => !notification?.isRead);
        setUnreadNotifications(filterUnreadNotifications?.length);

      } catch (error) {
        console.log("🚀 ~ notificationData ~ error:", error);
      }
    };

    notificationData();
  }, []);

  // Integrate mark all read notifications api
  const markAllAsRead = async () => {
    try {
      const requestParams = {
        isRead: true,
      };
      const allReadResponse = await axios.put(`${config?.baseUrl}/api/notifications`, requestParams, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      // console.log("🚀 ~ markAllAsRead ~ allReadResponse:", allReadResponse)
    } catch (error) {
      console.log("🚀 ~ markAllAsRead ~ error:", error);
    }
  };

  // Mark as read by Id
  const markAsReadById = async (notificationId) => {
    try {
      const markAsReadByIdResponse = await axios.put(`${config?.baseUrl}/api/notifications/${notificationId}`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      console.log("🚀 ~ markAsReadById ~ markAsReadByIdResponse:", markAsReadByIdResponse?.data?.message?.data?.redirectUrl);
      navigate(`${markAsReadByIdResponse?.data?.message?.data?.redirectUrl}`)
    } catch (error) {
      console.log("🚀 ~ markAsReadById ~ error:", error);
    }
  };





  const drawer = useSelector((state) => {
    return state.sideDrawer.drawerState;
  });
  const openDrawerHandler = () => {
    dispatch(openDrawer());
  };
  // Functions
  const redirecting = () => {
    if (back) {
      navigate(`/${back}`);
    }
  };
  return (
    <>
      <div className="row">
        {/* Mobile View Notification part start*/}
        <div className="responsive-top-bar d-flex d-lg-flex d-md-flex d-xl-none justify-content-between align-items-center">
          <div className="burger-menu-container">
            <div className="burger-menu d-none">
              <button
                onClick={() => {
                  openDrawerHandler();
                }}
                className={
                  drawer === true
                    ? "hamburger hamburger--elastic is-active"
                    : "hamburger hamburger--elastic "
                }
                type="button"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </div>
          </div>
          <div className="page-title-box">
            <div className="top-heading-h1 d-flex align-items-center gap-4">
              <h1 className="fw-bold top-bar-heading">
                {topBarTitleDetail && (
                  <span onClick={redirecting} className="me-2 cursor">
                    <svg
                      width={30}
                      height={30}
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m15 18-6-6 6-6" />
                    </svg>
                  </span>
                )}
                {topBarTitle}
                {topBarTitleDetail}
              </h1>
            </div>
          </div>
          <div className="notification-box-container">
            <div className="dashboard-right-top-header d-flex align-items-center gap-2">
              <span className="help-icon">
                <NavLink to="/help">
                  <img src={helpIcon} alt="" />
                </NavLink>
              </span>
              <div className="notify-icon me-3 position-relative cursor">
                <img
                  onClick={() => {
                    setNotifyDropdown(!notifyDropdown);
                  }}
                  src={notificationIcon}
                  alt=""
                />
                <div ref={popupRef}
                  className={
                    notifyDropdown === true
                      ? "notification-dropdown-show"
                      : "notification-dropdown"
                  }
                >
                  <div className="notification-dropdown-header d-flex justify-content-between align-items-center">
                    <p className="notification-left-heading">Notifications</p>
                    <p className="notification-right-heading" onClick={markAllAsRead}>
                      Mark all as read
                    </p>
                  </div>
                  <div className="notification-dropdown-second-header d-flex gap-3">
                    <Link to="/notification" className="notification-left-second-heading">All</Link>
                    <p className="notification-right-second-heading">
                      {" "}
                      Unread <span className="unread-count">{unreadNotifications}</span>{" "}
                    </p>
                  </div>
                  {notifyData?.slice(0, 4).map((item) => {
                    return (
                      <div key={item?.id}>
                        <div
                          className={
                            item.isRead === false
                              ? "notification-box-unread d-flex gap-1 position-relative"
                              : "notification-box d-flex gap-1 position-relative"
                          }
                        >
                          <div className="notification-box-img d-flex gap-1">

                            <img
                              className="object-fit-contain "
                              src={item?.profileImage ? `https://digital-tenant-bucket.s3.us-east-1.amazonaws.com/${item?.profileImage}` : 'https://static.vecteezy.com/system/resources/previews/019/879/186/original/user-icon-on-transparent-background-free-png.png'}
                              alt="userImage"
                            />
                          </div>
                          <div className="notification-box-text">
                            <p className="mb-0 notification-text-one">
                              {item?.sender?.firstName} {item?.sender?.lastName}
                            </p>
                            <p className="mb-0 notification-text-second">
                              {item?.message}
                            </p>
                          </div>
                          <div
                            className="notification-box-time position-absolute top-0 "
                            style={{ right: "10px" }}
                          >
                            <span className="normal-grey-text">
                              {moment(item?.updatedAt).format('LT')}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {notifyData?.length > 3 ? (
                    <div className="see-all-notification-box text-center border-top p-2">
                      <Link to="/notification" className="text-dark fw-bold td">
                        See All Notifications
                      </Link>
                    </div>
                  ) : null}

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile View Notification part end*/}

        {/* Desktop View Notification part start*/}
        <div className="top-heading-container d-md-none d-lg-none d-xl-flex d-none align-items-center justify-content-between">
          <div className="top-heading-h1  d-flex align-items-start flex-column">
            <h1 className="fw-bold top-bar-heading mb-0 mt-2">
              {topBarTitleDetail && (
                <span onClick={redirecting} className="me-2 cursor">
                  <svg
                    width={30}
                    height={30}
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m15 18-6-6 6-6" />
                  </svg>
                </span>
              )}
              {topBarTitle}
              {topBarTitleDetail}
            </h1>
            <p className="fw-medium ">{vendorText}</p>
          </div>
          <div className="dashboard-right-top-header d-md-none d-lg-flex  d-none align-items-center gap-3">
            <span className="help-icon">
              <NavLink to="/help">
                <img src={helpIcon} alt="" />
              </NavLink>
            </span>
            <div className="notify-icon me-3 position-relative cursor">
              <img
                onClick={() => {
                  setNotifyDropdown(!notifyDropdown);
                }}
                src={notificationIcon}
                alt=""
              />
              <div ref={popupRef}
                className={
                  notifyDropdown === true
                    ? "notification-dropdown-show"
                    : "notification-dropdown"
                }
              >
                <div className="notification-dropdown-header d-flex justify-content-between align-items-center">
                  <p className="notification-left-heading">Notifications</p>
                  <p className="notification-right-heading" onClick={markAllAsRead}>
                    Mark all as read
                  </p>
                </div>
                <div className="notification-dropdown-second-header d-flex gap-3">
                  <Link to="/notification" className="notification-left-second-heading">All</Link>
                  <p className="notification-right-second-heading">
                    {" "}
                    Unread <span className="unread-count"> {unreadNotifications} </span>{" "}
                  </p>
                </div>
                {notifyData?.slice(0, 4).map((item) => {
                  // console.log("🚀 ~ {notifyData?.slice ~ item:", notifyData?.length)
                  return (
                    <div key={item?._id} onClick={() => markAsReadById(item?._id)}>
                      <div
                        className={
                          item.isRead === false
                            ? "notification-box-unread d-flex gap-1 position-relative"
                            : "notification-box d-flex gap-1 position-relative"
                        }
                      >
                        <div className="notification-box-img d-flex gap-1">
                          <img
                            className="object-fit-contain "
                            src={item?.profileImage ? `https://digital-tenant-bucket.s3.us-east-1.amazonaws.com/${item?.profileImage}` : 'https://static.vecteezy.com/system/resources/previews/019/879/186/original/user-icon-on-transparent-background-free-png.png'}
                            alt="userImage"
                          />
                        </div>
                        <div className="notification-box-text">
                          <p className="mb-0 notification-text-one">
                            {item?.sender?.firstName} {item?.sender?.lastName}
                          </p>
                          <p className="mb-0 notification-text-second">
                            {item?.message}
                          </p>
                        </div>
                        <div
                          className="notification-box-time position-absolute top-0 "
                          style={{ right: "10px" }}
                        >
                          <span className="normal-grey-text"> {moment(item?.createdAt).format('LT')} </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {notifyData?.length > 3 ? (
                  <div className="see-all-notification-box text-center border-top p-2">
                    <Link to="/notification" className="text-dark fw-bold td">
                      See All Notifications
                    </Link>
                  </div>
                ) : null}

              </div>
            </div>
          </div>
        </div>
        {/* Desktop View Notification part end*/}
      </div>
    </>
  );
};

export default TitleTopBar;
