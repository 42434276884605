import { useState, useEffect } from "react";
import * as Component from "Components/GeneralComponents";
import { Link, useNavigate } from "react-router-dom";
import * as Icon from "assets/icons";
import * as Form from "./index";
import { PostUpdateModalForm } from "./index";
import { getallTasks } from "network/tenant/task";

const TenantTask = () => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [key, setKey] = useState(null);
  const [openModalPostUpdate, setOpenModalPostUpdate] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([ ]);
  const [isrender , setIsRender] = useState(false);

  useEffect(() => {
    setIsTableLoading(true);
    getallTasks().then((response) => {
      if (response.apiCallStatus === "success") {
        setIsTableLoading(false);
        const taskdata = response.message.data.map((item) => {
          return {
            key: item._id,
            title: item.title,
            dueDate: new Date(item.dueDate).toLocaleDateString(),
            status: item.status,
            image: item.image,
            priority: item.priority,
            related : item.propertyAddress,
            assignedTo: item.assignees,
            setting: {
              key: item._id,

            }
          };
        });
        setData(taskdata);
      }
      else{
        setData([]);
        setIsTableLoading(false);
      }
    });
  }, [isrender]);

  // Data Table Functions
  const handleIconClick = (result) => {
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const columns = [
    {
      title: "TITLE",
      dataIndex: "title",
      render: (text, data) => (
        <Link
          to={`/task-details?id=${data.key}`}
          className="d-flex align-items-center gap-2"
        >
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              overflow: "hidden",
              border: `0.5px solid ${data.image ? "#c9c9c9" : "#ef6b3e"}`,
              position: "relative",
            }}
          >
            {data.image ? (
              <img
                src={data.image}
                alt="task"
                style={{ width: "100%", height: "100%",objectFit:"cover" }}
              />
            ) : (
              <Icon.TaskIcon width={20} height={20} />
            )}
          </div>
          <div className="">{text}</div>
        </Link>
      ),
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      render: (text, data) => (
        <>
          {" "}
          <Component.IconList
            properties={text}
            defaultimage={"https://via.placeholder.com/150"}
            name={true}
          />
        </>
      ),
    },
    {
      title: "DUE DATE",
      dataIndex: "dueDate",
      render: (text,data) => (
        <>
          <div className="assign-date-container">
          
            {
              (!data.priority) ? "Not Set" :(<>
               <div
              className={
                data.priority === "High"
                  ? "priority-text bg-error"
                  : data.priority === "Low"
                  ? "priority-text bg-grey"
                  : data.priority === "Medium"
                  ? "priority-text bg-yellow"
                  : data.priority === "Very High"
                  ? "priority-text bg-error"
                  : ""
              }
            >
              
              <span>{`${data.priority} Priority`}</span>
            </div>
            <br />
            <span className="assign-to-date">{text}</span></>)
            }

          </div>
        </>
      ),
    },
    {
      title: "RELATED TO",
      dataIndex: "related",
      render: (text) => <div className="all-lease-property-text">{text}</div>,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (text) => (
        <>
          <span
            className={
              text === "Completed"
                ? "completed-status text-white"
                : text === "In Progress"
                ? "in-progress-status text-white"
                : text === "Not Started"
                ? "not-started-status text-dark"
                : ""
            }
          >
            {text}
          </span>{" "}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleView: () => {
                  navigate(`/task-details?id=${setting.key}`);
                },
                handlePostAnUpdate: () => {
                  onOpenModalPostUpdate();
                  setTaskId(setting.key);
                },
              }}
              fields={{
                view: true,
                delete: false,
                postAnUpdate: true,
                renewLease: setting.renew,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  const onOpenModalPostUpdate = () => {
    setOpenModalPostUpdate(true);
  };

  const openModalTask = () => {
    setOpenModal(true);
  };
  const onCloseModalTask = () => {
    setOpenModal(false);
  };
  const onCloseModalPostUpdate = () => {
    setOpenModalPostUpdate(false);
  };

  return (
    <>
      {openModal && (
        <Component.FormModalLayout
          title={"Add New Maintenance Request"}
          isOpen={openModal}
          onClose={onCloseModalTask}
        >
          <Form.TenantTaskForm onClose={() => {
            onCloseModalTask();
            setIsRender(!isrender);
          }} />
        </Component.FormModalLayout>
      )}

      {openModalPostUpdate && (
        <Component.FormModalLayout
          title={"Post Update"}
          isOpen={openModalPostUpdate}
          onClose={onCloseModalPostUpdate}
        >
          <PostUpdateModalForm onClose={() => {
            onCloseModalPostUpdate();
            setIsRender(!isrender);
          }} id={taskId} />
        </Component.FormModalLayout>
      )}
      
      <div className="p-3">
        <Component.FilterRow
          filter={""}
          setFilter={"setFilter"}
          btnTitle={"Create New"}
          btnHandler={() => openModalTask()}
        />
        
        
        <div className="table-container mt-5 ">
          <Component.CustomTable
            loading={isTableLoading}
            pagination={true}
            columns={columns}
            dataSource={data}
            rowSelection={false}
          />
        </div>
      </div>
    </>
  );
};

export default TenantTask;
